<div class="basic-company-information">
    <div class="container-fluid" *ngIf="traderJson">
        <button class="btn" style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()"> 
        
        <span 
        *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
        *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span></button>

        <form #basiccompinfo="ngForm">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.entityName.label}}<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" name="entity"
                        [(ngModel)]="traderJson.traderProfile.entityName" [disabled]="!editableFlag && readOnlyMode"
                        required placeholder="Please Enter Entity Type">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.entityType.label}}<span
                            class="text-danger">*</span></label>
                    <select class="form-select  form-select-sm" [(ngModel)]="traderJson.traderProfile.typeOfEntity.code" (change)="onchange(basiccompinfo)"  name="entitytype"
                        [disabled]="!editableFlag && readOnlyMode" required>
                        <option value="" selected disabled>Select Entity Type</option>
                        <option *ngFor="let dropdownElement of businessTypes" [value]="dropdownElement.code">
                            {{dropdownElement.labels[0].text}}</option>

                    </select>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.panNumber.label}}<span
                            class="text-danger">*</span></label>
                    <input type="email" class="form-control form-control-sm" aria-describedby="helpId"
                        placeholder="Please Enter {{appLabel.panNumber.label}}" name="panNumber"
                        [(ngModel)]="traderJson.traderProfile.panNumber" [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid(input1.name)}" #input1
                        required>

                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4"  *ngIf="appLabel.msmeType.required">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.msmeType.label}}
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                    <select class="form-select  form-select-sm" name="msmeType" id=""   *ngIf="traderJson?.traderProfile?.msmeType"
                        [(ngModel)]="traderJson.traderProfile.msmeType.code" [disabled]="!editableFlag && readOnlyMode"
                    >
                        <option value="" selected disabled>Select {{appLabel.msmeType.label}}</option>
                        <option *ngFor="let dropdownElement of msmeTypes" [value]="dropdownElement.code">
                            {{dropdownElement.labels[0].text}}</option>
                    </select>


                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.msmeUAMNo.label}}
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                    <input type="email" class="form-control form-control-sm" placeholder="Please Enter {{appLabel.msmeUAMNo.label}}"
                        name="msmeUANNo" [(ngModel)]="traderJson.traderProfile.msmeUAMNumber" 
                        [disabled]="!editableFlag && readOnlyMode">

                </div>
            </div>

        </div>
        <div class="row mt-4" *ngIf="appLabel.msmeAttachment.required">
            <div class="col-12 col-md-4" *ngIf="!imageLoader">
                <label for="exampleInputEmail1" class="text-label">{{appLabel.msmeAttachment.label}}
                    <span class="text-danger">*</span>
                </label>
   
                
                <div class="form-group" style="display: flex;align-items: center;" [ngClass]="{'has-error': isFieldInvalid(uploadTraderProfile.id)}">
                    <button class="outline-upload-button" style=" margin-right: 10px;"
                        (click)="uploadTraderProfile.click()" [disabled]="!editableFlag && readOnlyMode"><span
                            *ngIf="!attachedDoc" >Upload</span>
                        <span *ngIf="attachedDoc">Re-Upload</span>
                    </button>
                    <input #uploadTraderProfile id="Msmeattachmet" type="file" accept="image/*"
                        (change)="addAttachment($event)" style="display: none" (click)="$event.target.value=null" required />
                    <span class="muted-notification-text">Image (.jpg,.jpeg,.png)of Size lessthan 6mb can be
                        uploaded.</span>
                </div>

            </div>

            <div class="col-12 col-md-4" *ngIf="!imageLoader && imgFlag">
                <div class="image-wrap">
                    <img *ngIf="imgFlag; else pdfDoc" [src]="attachedDoc" alt="Loading Image" class="image-size" />
                    <span *ngIf="imageLoader" class="text-center"><i class="fa fa-spinner fa-pulse fa-x fa-fw"
                            style="margin-left: 50%;"></i></span>
                    <ng-template #pdfDoc>
                        <p> {{attachedDoc}} </p>
                    </ng-template>
                </div>
            </div>

        </div>
        <div class="full-width-div">
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">

                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                        style="height:28px;width:100px;left:15px" [disabled]="editableFlag "
                        (click)="navigatePrevious()">
                        <i class="fa fa-angle-double-left" style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                        Previous
                    </button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                    <button [disabled]="editableFlag " type="button"
                        class="btn  btn-sm profile-side-nav-next-prev-button"
                        style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                        Next&nbsp; &nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>


                    <button type="button" [disabled]="basiccompinfo.form.invalid || !attachedDoc ||( !editableFlag && readOnlyMode) "class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                        style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>