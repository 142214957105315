<div class="profile-side-nav" *ngIf="appLabel">
    <div class="container-fluid  " style="height:90vh;position:absolute ;margin-top: 4.8rem;">
        <div class="row" style="height: 100%;">
            <div class="col-md-12" style="height:50px;display: flex;align-items: center ">
                <h4 style="margin-top: 10px;">Profile Details </h4>
                <app-trader-details-pdf></app-trader-details-pdf>
            </div>
            <div class="col-md-3 ">
                <div class="card" style="padding: 20px;background-color: #1A237F;    height: 97%;">
                    <div class="nav flex-column nav-pills mb-2" id="v-pills-tab" role="tablist" aria-orientation="vertical" *ngFor="let item of appLabel.menu; let i = index">
                        <a class="nav-link d-flex justify-content-between align-items-center" [ngClass]="selectedIndex == i ? 'active' : ''" id="{{i}}" data-target="#item2" aria-selected="item.isActive" (click)="loadComponent(item, i)">
                            <span>{{item.label}}</span>
                            <span *ngIf="isFieldInvalid(item.label)" class="my-icon">
                                <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                        </a>
                    </div>
                    
                </div>
            </div>

            <div class="col-md-9 ">
                <div class="card" style="  min-height: 97%;">
                    <div class="card-header custom-card-header " style="display: flex;
                    width: 97%;
                    padding: 15px 0px 15px 0px;
                    margin: 0px 17px 0px 17px;
                ">
                        {{currentActiveComponent.label}}
                    </div>
                    <div class="card-body">
                        <ng-template *ngIf="lazyComp$  | async as compType"
                            [ngComponentOutlet]="compType"></ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>