<div class="basic-information" *ngIf="traderJson">
    <div class="container-fluid">
        <button class="btn" style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()">  <span 
          *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
          *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span>   </button>
          <form #basicinfo="ngForm">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label class="text-label">{{appLabel.vendorId}}<span class="text-danger">*</span></label>
                    <input type="text" [(ngModel)]="vendorId" class="form-control form-control-sm" name="VendorName"
                        placeholder="Enter {{appLabel.vendorId}}" disabled>

                </div>
            </div>

            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label class="text-label">{{appLabel.firstName}}<span class="text-danger">*</span></label>
                    <input type="text" [(ngModel)]="traderJson.traderProfile.firstName"
                        class="form-control form-control-sm" name="firstName" placeholder="Enter {{appLabel.firstName}}" required
                        [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid(input1.name)}"  #input1>
                </div>
            </div>
            <div class="col-12 col-md-4"  *ngIf="appLabel.middleName.required">
                <div class="form-group">
                    <label class="text-label">{{appLabel.middleName.label}}
                         <!-- <span class="text-danger">*</span> -->
                        </label> 
                    <input type="text" [(ngModel)]="middleName"
                        class="form-control form-control-sm" name="middleName" placeholder="Enter {{appLabel.middleName.label}}" 
                        [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid(input4.name)}"  #input4>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label class="text-label">{{appLabel.lastName}}<span class="text-danger">*</span></label>
                    <input type="text" [(ngModel)]="traderJson.traderProfile.lastName"
                        class="form-control form-control-sm" name="lastName" placeholder="Enter {{appLabel.lastName}}"required
                        [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid(input2.name)}" #input2>
                </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label class="text-label">{{appLabel.email}} <span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" #email="ngModel"
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" aria-describedby="helpId"
                        placeholder="Please Enter Email Id" name="contacts"
                        [(ngModel)]="traderJson.traderProfile.contacts[contactsMailIndex].contactValue" required
                        [disabled]="!editableFlag && readOnlyMode"  [ngClass]="{'has-error': isFieldInvalid(input3.name)}" #input3>
                    <span *ngIf="email.touched">
                        <small *ngIf="email.errors?.pattern" class="text-danger">Please enter a valid email</small>
                        <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
                    </span>


                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label class="text-label">{{appLabel.contact}} <span class="text-danger">*</span></label>
                    <input type="text" [(ngModel)]="traderJson.traderProfile.contacts[contactsMobileIndex].contactValue" #contactNumber="ngModel" name="contactNumber" pattern="^([+]\d{2}[ ])?\d{10}$" class="form-control form-control-sm"
                        id="exampleInputEmail1" placeholder="Please Enter Contact Number"
                        [disabled]="!editableFlag && readOnlyMode" required [ngClass]="{'has-error': isFieldInvalid(input1.name)}">
                        <span *ngIf="contactNumber.touched">
                            <small *ngIf="contactNumber.errors?.pattern" class="text-danger">Please enter a valid contact Number</small>
                            <small *ngIf="contactNumber.errors?.required" class="text-danger">contact Number is required</small>
                        </span>
    
                </div>
            </div>
        </div>
        <div class="full-width-div">
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">
                    <button type="button" [disabled]="editableFlag "
                        class="btn  btn-sm profile-side-nav-next-prev-button" style="height:28px;width:100px;left:15px"
                        (click)="navigateNext()">
                        Next&nbsp;&nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>
                    <!-- <span class="vertical-separator"></span>
                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button" style="left:180px">
                        Download PDF</button> -->
                    <button type="button"[disabled]="basicinfo.form.invalid ||( !editableFlag && readOnlyMode)"
                        class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                        style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>

    </div>
</form>
</div>
</div>