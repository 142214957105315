<div style="height:50px;display: flex;align-items: center">    <button type="button" class="btn btn-warning btn-sm page-header-button" (click)=" both()"> 
        <span *ngIf="loader">&nbsp; <i class="fa fa-spinner fa-pulse fa-x fa-fw"></i></span> <span> <i class="fa fa-file-pdf-o"></i></span>                Download PDF</button> </div>

    <ng-template #yourModalContent let-c="close" let-d="dismiss" style="width: 500px;border-radius: 10px;">
      
      <div class="modal-body" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <p>To Download the PDF, Please Update the remaining mandatory fields marked with red</p>
        <button type="button" class="close" aria-label="Close" (click)="c('Close')" style="color: white; background-color: #1A237F; height: 30px; width: 100px; border-radius: 10px;">
            <span aria-hidden="true">Ok</span>
        </button>
    </div>
    
    
    
   
    </ng-template>

  
    <ng-template #pdfview let-c="close" let-d="dismiss" style="width: 100%; height: 1000px;">
      <div class="modal-header">
        <h4 class="modal-title">Trader Profile PDF Preview</h4>
        <button type="button" class="close" aria-label="Close" (click)="c('Close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="pdfContainer" style="width: 100%; height: 100%;"></div>
    </ng-template>