<div class="ware-house-shipping" *ngIf="warehouses.length > 0">
    <div class="container-fluid">
        <div style="position: absolute; top: 13px; right: 15px;">
            <button type="button" class="btn btn-sm" (click)="addWarehouse()" *ngIf="editableFlag" style="border: 1px solid #1A237F; color: #1A237F;">
                <span><i class="fa fa-plus-circle"></i></span>&nbsp; Add Warehouse
            </button>
            
            <button class="btn" style="margin-left: 10px;" (click)="toggleEdit()">
                <span *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span>
                <span *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span>
            </button>
        </div>


        <form #warehouseInfo="ngForm">
            <div *ngFor="let warehouse of warehouses; let i = index">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <label  class="text-label">Warehouse {{i+1}}</label>
                    </div>
                   
                </div>
                
                <hr>
               
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label"> Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" aria-describedby="helpId"
                                [(ngModel)]="warehouse.centerName" placeholder="" id="name - {{i}}" name="name - {{i}}"
                                [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid()&& !input1.model}" #input1="ngModel" required>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label">Address <span class="text-danger">*</span></label>
                            <input type="email" class="form-control form-control-sm" aria-describedby="emailHelp"
                                [(ngModel)]="warehouse.workNode.workNodeAddress.addressLine1" placeholder=""
                                id="address - {{i}}" name="address - {{i}}" [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !input2.model}" #input2="ngModel" required>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label">State <span class="text-danger">*</span></label>
                            <select class="form-select  form-select-sm" name="state - {{i}}" id="state - {{i}}"
                                [(ngModel)]="warehouse.workNode.workNodeAddress.state.code"
                                [disabled]="!editableFlag && readOnlyMode"
                                (change)="getDistrict(warehouse.workNode.workNodeAddress.state.code,1,i); warehouse.workNode.workNodeAddress.district.code = ''"
                                [ngClass]="{'has-error': isFieldInvalid() && !input3.model}" #input3="ngModel" required>
                                <option value="" selected disabled>Select State</option>
                                <option *ngFor="let dropdownElement of states" [value]="dropdownElement.code">
                                    {{dropdownElement.labels[0].text}}</option>
                            </select>

                        </div>
                    </div>

                </div>

                <div class="row mt-4">
                    <div class="col-12 col-md-4"  *ngIf="appLabel.district.required">
                        <div class="form-group">
                            <label for="" class="text-label">District <span class="text-danger">*</span></label>
                            <select class="form-select  form-select-sm" name="district - {{i}}" id="district - {{i}}"
                                [(ngModel)]="warehouse.workNode.workNodeAddress.district.code"
                                (change)="getBlock(warehouse.workNode.workNodeAddress.district.code,1,i); warehouse.workNode.workNodeAddress.block.code = ''"
                                [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !input4.model}" #input4="ngModel" required>
                                <option value="" selected disabled>Select District</option>
                                <ng-container *ngFor="let dropdownElement of district">
                                    <option
                                        *ngIf="warehouse.workNode.workNodeAddress.district.code != '' && dropdownElement.code == warehouse.workNode.workNodeAddress.district.code"
                                        [value]="dropdownElement.code">{{dropdownElement.labels[0].text}}</option>
                                    <option *ngIf="warehouse.workNode.workNodeAddress.district.code == ''"
                                        [value]="dropdownElement.code">{{dropdownElement.labels[0].text}}</option>
                                </ng-container>
                            </select>

                        </div>
                    </div>
                    <div class="col-12 col-md-4"  *ngIf="appLabel.block.required">
                        <div class="form-group">
                            <label for="" class="text-label">Block <span class="text-danger">*</span></label>
                            <select class="form-select  form-select-sm" name="block - {{i}}" id="block - {{i}}"
                                [(ngModel)]="warehouse.workNode.workNodeAddress.block.code"
                                [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !input5.model}" #input5="ngModel" required>
                                <option value="" selected disabled>Select Block</option>
                                <ng-container *ngFor="let dropdownElement of block">
                                    <option
                                        *ngIf="warehouse.workNode.workNodeAddress.block.code != '' && dropdownElement.code == warehouse.workNode.workNodeAddress.block.code"
                                        [value]="dropdownElement.code">
                                        {{dropdownElement.labels[0].text}}
                                    </option>
                                    <option *ngIf="warehouse.workNode.workNodeAddress.block.code == ''"
                                        [value]="dropdownElement.code">
                                        {{dropdownElement.labels[0].text}}
                                    </option>
                                </ng-container>
                            </select>

                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label">{{appLabel.pin}} <span class="text-danger">*</span></label>
                            <input type="email" class="form-control form-control-sm" aria-describedby="helpId"
                                id="pin - {{i}}" [(ngModel)]="warehouse.workNode.workNodeAddress.pinCode" placeholder=""
                                name="pin - {{i}}" [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !input6.model}" #input6="ngModel" required>
                        </div>
                    </div>

                </div>
                <div class="row mt-4">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label">Warehouse Manager <span
                                    class="text-danger">*</span></label>

                            <input type="text" class="form-control" aria-describedby="helpId" id="manager - {{i}}"
                                [(ngModel)]="warehouse.workNode.workNodeContact[2].contactValue" placeholder=""
                                name="manager - {{i}}" [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !input7.model}" #input7="ngModel" required>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label">Contact Number <span class="text-danger">*</span></label>
                            <input type="email" class="form-control form-control-sm" aria-describedby="helpId"
                                placeholder="" pattern="^[\+\d]?(?:[\d-.\s()]*)$" id="contactNumber - {{i}}"
                                [(ngModel)]="warehouse.workNode.workNodeContact[1].contactValue"
                                name="contactNumber - {{i}}" [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !input8.model}" #input8="ngModel" required>

                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label">Contact Email <span class="text-danger">*</span></label>
                            <input type="email" class="form-control form-control-sm" ria-describedby="helpId"
                                placeholder="" #email="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                id="contactEmail - {{i}}"
                                [(ngModel)]="warehouse.workNode.workNodeContact[0].contactValue"
                                name="contactEmail - {{i}}" [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !email.model}" required>
                            <span *ngIf="email.touched">
                                <small *ngIf="email.errors?.pattern" class="text-danger">Please enter a valid
                                    email</small>
                                <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
                            </span>

                        </div>
                    </div>

                </div>
                <div class="row mt-4">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="" class="text-label">Service Area <span class="text-danger">*</span></label>
                            <select class="form-select  form-select-sm" name="servingAreaCodes - {{i}}"
                                id="servingAreaCodes - {{i}}" [(ngModel)]="warehouse.servingAreaCodes[0].code"
                                [disabled]="!editableFlag && readOnlyMode" [ngClass]="{'has-error': isFieldInvalid() && !input9.model}" #input9="ngModel" required>
                                <option value="" disabled>Select Serving Area</option>
                                <option value="REGION_COUNTRY.INDIA" selected>India</option>
                            </select>

                        </div>

                    </div>
                </div>
                <div class="row" style="margin-top: 40px;">
                    <div class="full-width-div">
                        <div class="row">
                            <div class="col-12"
                                style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">

                                <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                                    style="height:28px;width:100px;left:15px" [disabled]="editableFlag "
                                    (click)="navigatePrevious()">
                                    <i class="fa fa-angle-double-left"
                                        style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                                    Previous
                                </button>
                                <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                                <button [disabled]="editableFlag " type="button"
                                    class="btn  btn-sm profile-side-nav-next-prev-button"
                                    style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                                    Next&nbsp; &nbsp;
                                    <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                                </button>


                                <button type="button" [disabled]="warehouseInfo.form.invalid || loader ||( !editableFlag && readOnlyMode)"
                                    class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                                    style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            
        </form>
    </div>
</div>