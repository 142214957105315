import { Component, OnInit } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-finance-division-contact-details',
  templateUrl: './finance-division-contact-details.component.html',
  styleUrls: ['./finance-division-contact-details.component.scss']
})
export class FinanceDivisionContactDetailsComponent implements OnInit {
  traderJson: any;
  readOnlyMode: boolean;
  editableFlag: boolean;
  currentIndex: number;
  appLanguage: string;
  appLabel: any;
  refDataCodes: any;
  traderRefDataCodes: any;
  loader: boolean;
  mandatoryFields: any[];

  constructor(private sharedService:SharedService,private componentLoaderService:ComponentLoaderService,
    private toastr : ToastrCustomService) { }

  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getAppLabels();
    this.getDataCodes();
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;

  }

  getAppLabels() {
    this.sharedService  
      .getJson("app-labels/" + this.appLanguage + "/traderProfile")
      .subscribe((res) => {
        this.appLabel = res["financeDivisionContactDetails"]
        });
  }

  getDataCodes() {
    this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getTraderJson();
      });
    }
    );
  }
  getTraderJson() {
    this.sharedService.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(traderResponse => {
      let arr = [];
      arr = traderResponse[0]['traderProfile']['traderContactPersons'].filter((x, index) => {
        // //Sorting
        x['personContacts'] = x['personContacts'].sort(function (a, b) {
          var x = a.contactType.code.toLowerCase();
          var y = b.contactType.code.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        });
        if (x.division.code == this.traderRefDataCodes.division.financeDivisionContactDetails) {
          this.currentIndex = index;
          return x;
        }
      });
      console.log('Finance ---', arr);
      if (traderResponse[0]['traderProfile']['traderContactPersons'].length == 0 || arr.length == 0) {
        console.log('Trader-Details Undefined');
        this.sharedService.getJSONData('app-models/'+this.appLanguage+'/post-jsons/trader').subscribe(resJson => {
          traderResponse[0]['traderProfile']['traderContactPersons'].push(resJson['traderProfile']['traderContactPersons'][1]);
          this.traderJson = traderResponse[0];
          this.currentIndex = this.traderJson.traderProfile.traderContactPersons.length - 1;
          console.log('Trader-Json-Updated', this.traderJson);
        })
      }
      else this.traderJson = traderResponse[0];
    })
  }
  navigateNext() {
    if (this.readOnlyMode) {
      let lazyComp: any = import('../../vendor-profile-forms/bank-details/bank-details.component').then(({ BankDetailsComponent }) => BankDetailsComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'BankDetailsComponent' });
    }
    else {
      this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
        let lazyComp: any = import('../../vendor-profile-forms/bank-details/bank-details.component').then(({ BankDetailsComponent }) => BankDetailsComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'BankDetailsComponent' });
        console.log('Details Updated Successfully')
      })
    }
  }
  navigatePrevious() {
    let lazyComp: any = import(
      "../../vendor-profile-forms/communication-address/communication-address.component"
    ).then(({ CommunicationAddressComponent }) => CommunicationAddressComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "CommunicationAddressComponent",
    });
  }

  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;
    this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
      this.editableFlag = false;
      this.toastr.showSuccess('Details Updated Successfully');
      console.log('Details Updated Successfully');
      this.sharedService.traderDetails.next(res);
      this.loader = false;
    })
  }
  isFieldInvalid(): boolean {
    this.mandatoryFields = this.sharedService.dataArray;
    const divisionCode ="Finance Division Contact Details"
    return this.mandatoryFields.includes(divisionCode);
}

}
