import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UnAuthorizedLoginComponent } from 'src/app/shared-module/un-authorized-login/un-authorized-login.component';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {
  constructor(private modalService: NgbModal, private toastr: ToastrService) {
    // this.openLoginPopup('')
  }

  //private modalService: NgbModal, private toastr: ToastrService
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    console.log('Request in interceptor*********************', req);
    return next.handle(req).pipe(
      tap({
        next: event => event,
        error: error => {
          // console.log(error);
          //Check if auth_token is expired
          console.log("reqLLLLLLLLLLLLLLLLLLLLL:::::::::", req);

          if (error.status == 401 && error.error.errorCode == 'BadJWTException') {
            // console.log(error);
            console.log("req in interceptor", req);

            this.openLoginPopup(req);
          }
          else if (error.status != 404 && error.status != 401 && error.status != 202 && error.status != 200) {
            //      this.toastr.error('Error Occurred!');
          }
        }
      }),
    )
  }
  openLoginPopup(requestBody: any) {
    
    console.log("requestBodyLLLLLLLLLLLLLLL While opening popup", requestBody);

    const modalRef = this.modalService.open(UnAuthorizedLoginComponent, { centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.request = requestBody;
  }

}
