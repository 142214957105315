import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit{
 
  postLoginJson: any = {
    "password": "",
    "userLogin": ""
  };
  isPassword=true;
  showInfo = false;
  pageChange: boolean = false;
  emailOtpFlag: boolean;
  smsOtpFlag: boolean;
  createUserJson: any;
  tenantJson: any;
  otpJson: any;
  password: any;
  validateOTPFlag: boolean;
  correctOTP: boolean;
  messageId: any;
  otpEntered: any;
  userExists: boolean;
  loader: boolean;
  roleJson: any;
  tenantExists: any;
  tenantCheckFlag: boolean;
  assignUserRolesJson: any;
  languageFlag: boolean;
  appLanguage: any;
  apiKeyJson: Object;
  emailOtpJson: any;
  mobileFlag: boolean;
  states: any;
  refDataCodes: any;
  labelValueLanguage: string;
  state = {
    "code": ""
  }
  traderJson: any;
  postTraderJson: any;
  traderMarketAccountJSON: any;
  defaultMarketCode: any;
  isMobileNumber: boolean;
  prefix: string;
  // otpSubjectLine: any;
  showHidePassword = false;
  constructor(private router: Router, private service: SharedService) { }

  ngOnInit(): void {
   
    sessionStorage.clear();
    //Set the ClientAPiKey for accessing api's
    this.getClientApiKey();
    
  }
  
  getClientApiKey() {
   
    sessionStorage.setItem('auth_token', environment.token);
    this.apiKeyJson = { token: environment.token };
  };

  // Adding App Language
  updateAppLanguage(event) {
    console.log(event);   
    sessionStorage.setItem('appLanguage', event);
    localStorage.setItem('appLanguage', event);
    this.appLanguage=event;
    this.appLanguage = event;
    this.labelValueLanguage = this.appLanguage.split('-')[0];
    this.getPostJsons();
    this.getDataCodes();
    this.getTraderJson();
    this.languageFlag = false;
    setTimeout(() => {
      this.languageFlag = true;
    }, 100);
  }

  getPostJsons() {
    this.service.getJSONData('app-models/' + this.appLanguage + '/post-jsons/signUp').subscribe(res => {
      this.tenantJson = res['tenantDTO'];
      this.createUserJson = res['userDTO'];
      this.otpJson = res['otpDTO'];
      this.emailOtpJson = res['otpEmailDTO'];
      this.roleJson = res['roleDTO'];
      this.assignUserRolesJson = res['assignUserRolesDTO'];
      //console.log('POST JSON', this.tenantJson, this.createUserJson);
      //console.log('SignUp-Form', this.SignUpform);
      // //console.log('Role', this.roleJson.code + name.toLocaleUpperCase());
    });
  }

  getDataCodes() {
    this.service.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;    
      this.getStatesGREF();
      this.getDefaultMarket();
    });
  };

  getDefaultMarket() {
    this.service.getGREFDataByDataCode(this.refDataCodes.market.defaultMarket).subscribe(defaultMarket => {
      this.defaultMarketCode = defaultMarket.labels[0].text;
    })
  };

  getTraderJson() {
    this.service.getJSONData('app-models/' + this.appLanguage + '/post-jsons/trader').subscribe(res => {
      this.traderJson = res;
      this.service.getJSONData('app-models/' + this.appLanguage + '/post-jsons/traderMarketAccount').subscribe(resTMA =>
        this.traderMarketAccountJSON = resTMA['traderMarketAccountDTO']
      );
      // Store form json in subject
      this.service.storeFormData(this.traderJson);
    });
  }

  getStatesGREF() {
    this.service.getGREFDataHierarchyByParentCode(this.refDataCodes.country, this.refDataCodes.state, sessionStorage['auth_token'], 1).subscribe(res => {
      let _tempStates = res.map(x => x.child);
      if (_tempStates) {
        _tempStates.forEach((o, i) => {
          let _index = o.labels.findIndex((obj, ind) => {
            return obj.language.code == this.labelValueLanguage;
          })

          if (_index != 0) {
            let _obj = o.labels.splice(_index, 1);
            let tempArray: any[] = [];
            tempArray.push(_obj[0]);
            tempArray.push(o.labels);
            o.labels = tempArray;
          }
        })
        this.states = _tempStates;
      }
    })
  };

  sendOtp() {

    this.userExists = false;
    let postOtpJson: any;
    let appLanguage = sessionStorage.getItem('appLanguage');
    let prefix = appLanguage.includes('IN') ? '+91' : '+1';
    if (this.createUserJson.login.match('[a-zA-z]')) {
      // this.isMobileNumber = false;
      this.otpJson.toUsers[0] = this.emailOtpJson.toUsers[0] = this.createUserJson.login;
    } else {
      this.otpJson.toUsers[0] = this.emailOtpJson.toUsers[0] = prefix + this.createUserJson.login;
    }

    // this.otpJson.toUsers[0] = '+91' + this.createUserJson.login;
    // this.otpJson.toUsers[0] =  this.createUserJson.login;
    //Populating Subject Line
    // this.otpJson.params.MSG_SUBJECT = this.otpSubjectLine;

    // this.otpJson.toUsers[0] = this.emailOtpJson.toUsers[0] = this.createUserJson.login;
    //console.log('SignUp-Form', this.SignUpform);
    const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const emailReg = /^([w-.]+@([w-]+.)+[w-]{2,4})?$/;

    if (emailReg.test(this.createUserJson.login)) {
      postOtpJson = this.emailOtpJson;
      //populate contact value
      this.createUserJson.contacts[0].contactType.code = "CONTACT_TYPE.MAIL";
      this.createUserJson.contacts[0].contactValue = this.createUserJson.login;
      //console.log('Validated Email-', this.createUserJson);
    }
    else {
      this.mobileFlag = true;
      postOtpJson = this.otpJson;
      let appLanguage = sessionStorage.getItem('appLanguage');
      let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '+91' : '+1';
      //populate contact value
      this.createUserJson.contacts[0].contactType.code = "CONTACT_TYPE.MOBILE";
      this.createUserJson.contacts[0].contactValue = prefix + this.createUserJson.login;
      //console.log('Not valid email-', this.createUserJson);
    }
    //console.log('Json', postOtpJson);
    // postOtpJson = this.emailOtpJson;
    // else postOtpJson = this.otpJson;

    // this.service.getOtp(this.otpJson, sessionStorage['auth_token']).subscribe(res => {

    this.service.getOtp(postOtpJson, sessionStorage['auth_token']).subscribe(res => {
      // //console.log(JSON.parse(res));
      //console.log('SUccess')
    }, err => {
      if (err.status == 202) {
        this.userExists = false;
        this.smsOtpFlag = true;
        //console.log('error', err);
        this.messageId = err.error.text;
        //console.log('Message ID', this.messageId);
      }
    })
  }
  validateOtp() {
    this.service.validateOtp(this.messageId, this.otpEntered, sessionStorage['auth_token']).subscribe(res => {
      if (res.isValid == true) //Change to true
        this.correctOTP = true;
      this.validateOTPFlag = true;
    })
  }
  checkLoginName() {
    this.tenantCheckFlag = false;
    //console.log('Checking', this.tenantJson.login)
    //Checking whether user exists or not
    this.service.getTenantDetailsByLoginId(this.tenantJson.login, sessionStorage['auth_token']).subscribe(res => {
      this.tenantCheckFlag = true;
      //console.log('User Details', res);
      this.tenantExists = true;
    }, err => {
      if (err.error.errorCode == "ERR_PLATFORM_GEN_NOT_FOUND" && err.status == 404) {
        this.tenantCheckFlag = true;
        //console.log('User Details Error', err);
        this.tenantExists = false;
        //Valid Login Name
      }
    })
  }
  login() {
    this.router.navigate(['user-login']);
  }
  signUp() {
    this.loader = true;
    // this.tenantJson.name = this.createUserJson.name;
    // this.createUserJson.login = this.tenantJson.login;

    let appLanguage = sessionStorage.getItem('appLanguage');
    let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '+91' : '+1';
    this.tenantJson.name = this.tenantJson.login;
    sessionStorage.setItem('user_contact', this.createUserJson.login);
    this.pageChange = true;
    //Create Tenant
    this.service.createTenant(this.tenantJson, sessionStorage['auth_token']).subscribe(tenantResponse => {
      sessionStorage.clear();
      this.createUserJson.tenant.id = tenantResponse;
      //Login To get Auth Token
      this.postLoginJson.userLogin = 'admin' + '@@' + this.tenantJson.login;
      this.postLoginJson.password = 'tenantAdminPass@123';
      // this.postLoginJson.password = this.apiKeyJson['adminPassword'];
      this.service.login(this.postLoginJson).subscribe(loginRes => {
        sessionStorage.setItem('auth_token', loginRes['tokenType'] + ' ' + loginRes['accessToken']);
        sessionStorage.setItem('appLanguage', this.appLanguage);

        //Create Roles
        this.roleJson.code = this.roleJson.code + this.tenantJson.login;
        this.service.createRolesForTenant(this.roleJson, sessionStorage['auth_token']).subscribe(tenantRoleResponse => {
          //Create User
          if (this.mobileFlag) {
            this.createUserJson.login = prefix + this.createUserJson.login;
          }
          this.service.createUser(this.createUserJson, this.password).subscribe(userResponse => {
            this.loader = false;
            //console.log('User Created Successfully');
            sessionStorage.setItem('tenant_id', this.createUserJson.tenant.id);
            sessionStorage.setItem('user_id', userResponse);
            //Assign roles to user
            
            this.assignUserRolesJson.code = this.assignUserRolesJson.code + this.tenantJson.login;
            this.assignUserRolesJson.users[0].id = userResponse;
            this.service.assignUserRoles(this.assignUserRolesJson, sessionStorage['auth_token']).subscribe(userRolesResponse => {
              //Setting User's auth token
              this.postLoginJson.userLogin = this.createUserJson.login + '@@' + this.tenantJson.login;
              this.postLoginJson.password = this.password;
              this.service.login(this.postLoginJson).subscribe(userLoginRes => {
                // sessionStorage.setItem('auth_token', loginRes['tokenType'] + ' ' + loginRes['accessToken']);
                sessionStorage.setItem('auth_token', userLoginRes['tokenType'] + ' ' + userLoginRes['accessToken']);
                //Store Contact Number
                if (this.mobileFlag) {
                  sessionStorage.setItem('mobile', this.createUserJson.login);
                }
                // this.router.navigate(['vendorDashboard']);
                this.createTrader();
              })
            })
          })
        }, err => {
          this.loader = false;
        })
      })
    }, err => {
      this.loader = false;
    })
  };

  createTrader() {
    this.traderJson.traderProfile.entityName = this.tenantJson.name
    this.traderJson.traderProfile.traderName = this.traderJson.traderProfile.entityName + '_trader';
    this.traderJson.traderProfile.address.district = null;
    this.traderJson.traderProfile.address.pinCode = "000000";
    this.traderJson.traderProfile.address.block = null;
    this.traderJson.actor.actorId = sessionStorage['tenant_id'];
    this.traderJson.traderProfile.address.state = this.state;

    delete this.traderJson.traderProfile.traderBankAccounts;
    delete this.traderJson.traderProfile.msmeType;
    delete this.traderJson.traderProfile.orgType;
    delete this.traderJson.traderProfile.traderContactPersons;
    delete this.traderJson.traderProfile.contacts;
    delete this.traderJson.traderProfile.traderBrands;
    delete this.traderJson.traderProfile.typeOfEntity;
    //Remove the below one once the ref data is created
    delete this.traderJson.traderProfile.gstAvailability;


    this.service.createTrader(this.traderJson, sessionStorage['auth_token']).subscribe(res => {
      console.log('Trader Created Successfully', res);
      sessionStorage.setItem('trader_id', res.id);
      sessionStorage.setItem('actor_id', res.actor.id);
      sessionStorage.setItem('tenant_id', res.actor.actorId);
      sessionStorage.setItem('actor_type', res.actor.actorType);
      sessionStorage.setItem('trader_name', this.traderJson.traderProfile.traderName);
      sessionStorage.setItem('isLoggedIn', 'true');
      
      this.service.getMarketByMarketCode(this.defaultMarketCode, sessionStorage['auth_token']).subscribe(marketResponse => {
        //Create TMA
        this.traderMarketAccountJSON.trader = sessionStorage['trader_id'];
        this.traderMarketAccountJSON.market = marketResponse.id;
        this.service.createTraderMarketAccount(sessionStorage['trader_id'], this.traderMarketAccountJSON, sessionStorage['auth_token']).subscribe(TMAres => {
          sessionStorage.setItem('traderMarketAccount', TMAres.id);
          console.log('Trader Account Created Successfully');
          this.loader = false;
          this.router.navigate(['vendorDashboard/vendor-profile/', sessionStorage.getItem('user_id')]);
        })
      }, err => {
        this.loader = false;
        // this.toastr.error('Some Error Occurred');
      })
    })
  }
  checkInputType(event) {
    // console.log((event.keyCode || event.charCode));
    // console.log([8, 46].filter(el => el != (event.keyCode || event.charCode)));
    this.prefix = (this.appLanguage != null && this.appLanguage.includes('IN')) ? '+91' : '+1';
    if ([8, 46].filter(el => el != (event.keyCode || event.charCode))) {
      let appLanguage = sessionStorage.getItem('appLanguage');
      this.prefix = (appLanguage != null && appLanguage.includes('IN')) ? '+91' : '+1';
      // console.log('Event', this.modelChange);
      // if (this.model[0] == '+')
      //   this.model = appLanguage.includes('IN') ? this.model.substr(3) : this.model.substr(2);
      if (this.createUserJson.login.match('[a-zA-z]+')) {
        this.isMobileNumber = false;
        // if (this.model[0] == '+')
        //   this.model = appLanguage.includes('IN') ? this.model.substr(3) : this.model.substr(2);
      } else {
        // if (this.model[0] !== '+')

        this.isMobileNumber = true;
      }
    }
  }
  checkIdLoginIdValid() {

    let mobileLength=this.createUserJson.login
    mobileLength= mobileLength.replace(this.prefix, '').trim();
    console.log( mobileLength)

    let isNumberValid = (this.isMobileNumber && mobileLength.length == 10) ? true : false;
    console.log("hiii",isNumberValid)
    if (isNumberValid || this.createUserJson.login.match('^[a-zA-Z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$')) {
      return true;
    } else {
      return false;
    }
  }
}
