import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ToastrCustomService {

  constructor(private toastr: ToastrService) { }
  showSuccess(message: string, title: string = ''): void {
    this.toastr.success( `<div class="custom-toast-content">
    <div class="icon-container success">
      <i class="fa fa-check-circle"></i>
    </div>
    <div class="text-container">
      <div class="title">Success</div>
      <div class="message">${message}</div>
    </div>

  </div>`,
 null, { toastClass: 'custom-success-toast' ,
 
  });
  }
  showError(message: string, title: string = ''): void {
    this.toastr.success( `<div class="custom-toast-content">
    <div class="icon-container error">
      <i class="fa fa-times-circle"></i>
    </div>
    <div class="text-container">
      <div class="title">Error</div>
      <div class="message">${message}</div>
    </div>
  </div>`,
 null, { toastClass: 'custom-error-toast' ,
 
  });
  }
  showWarning(message: string, title: string = ''): void {
    this.toastr.success( `<div class="custom-toast-content">
    <div class="icon-container warning">
      <i class="fa fa-times-circle"></i>
    </div>
    <div class="text-container">
      <div class="title">Warning</div>
      <div class="message">${message}</div>
    </div>
  </div>`,
 null, { toastClass: 'custom-warning-toast' ,
 
  });
  }

  closeToast(){}
}
