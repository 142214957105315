import { Component, OnInit } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.scss']
})
export class ProductInformationComponent implements OnInit {
  selectAll: any = false
  parentCategoryList: any;
  appLanguage: any;
  categoryList: any;
  refDataCodes: any;
  traderRefDataCodes: any;
  activeCategory: any = {
    "classificationType": {
      "classificationCode": 'VSR_ACCESSORIES'
    },
    isAllSelected: false
  };
  loader = false;
  editableFlag: any = false;
  readOnlyMode: any;
  traderJson: any;

  allSelectedSubCategories: any = []
  constructor(private sharedService: SharedService, private componentLoaderService: ComponentLoaderService,
    private toastr: ToastrCustomService) { }

  ngOnInit(): void {
    this.allSelectedSubCategories = []
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getDataCodes();
    if (sessionStorage.getItem("isLoggedIn") == "true")
      this.readOnlyMode = true;

  }

  bindCategories(category) {
    this.traderJson.traderProfile.productClassifications = category;
  }

  checkActivation(parentCategoryValue: any) {
    this.activeCategory = parentCategoryValue

  }

  getDataCodes() {
    this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        //Get Product Categories
        this.sharedService.getCategories(this.traderRefDataCodes.productClassification.code, sessionStorage['auth_token']).subscribe(res => {
          let _temp: Array<any> = [];
          _temp.push(res);


          _temp = _temp[0].sort(function (a, b) {
            if (a.classificationType.name < b.classificationType.name) { return -1; }
            if (a.classificationType.name > b.classificationType.name) { return 1; }
            return 0;
          });
          
          _temp.forEach(element => {
            element['isActive'] = false;
          });
          this.parentCategoryList = _temp;
          this.parentCategoryList[0].isActive = true
          let parentCategories = this.parentCategoryList.map(x => x.classificationType.classificationCode).toString();
          this.sharedService.getCategories(parentCategories, sessionStorage['auth_token']).subscribe(resCategoryList => {
            let _tempChild: Array<any> = [];
            _tempChild.push(resCategoryList);
            _tempChild = _tempChild[0].sort(function (a, b) {
              if (a.classificationType.name < b.classificationType.name) { return -1; }
              if (a.classificationType.name > b.classificationType.name) { return 1; }
              return 0;
            });
            
            this.categoryList = _tempChild;

            this.activeCategory = this.parentCategoryList[0]
            this.getTraderJson();
          });
        });

      });
    });

  }

  getTraderJson() {
    this.sharedService.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(res => {
      let traderJson: any = res[0];
      let category: any = traderJson.traderProfile.productClassifications.filter(x => x);
      for (let i = 0; i < this.categoryList.length; i++) {
        for (let j = 0; j < category.length; j++) {
          if (this.categoryList[i].classificationType.classificationCode == category[j]) {
            this.categoryList[i].classificationType['isSelected'] = true;
            this.allSelectedSubCategories.push( this.categoryList[i])
               
            this.parentCategoryList.filter(x => {
              this.activeCategory = x
              if (x.classificationType.classificationCode == this.categoryList[i].parentClassificationType.classificationCode) {
                x.classificationType['isSelected'] = true;
                // let isAvailable = this.allSelectedSubCategories.find((xElem: any) => xElem.classificationType.classificationCode === x.classificationType.classificationCode)
                // if (!isAvailable) {
                 
                // }
              }
            })
          }
        }
      }
      this.parentCategoryList.forEach(element => {
        let temCountArr = [];
        element['subCategoriesList'] = this.categoryList.filter((subCatElem: any) => subCatElem.parentClassificationType.classificationCode == element.classificationType.classificationCode)
        temCountArr = element['subCategoriesList'].filter((elem: any) => elem.isSelected)
        if (temCountArr.length == element['subCategoriesList'].length) {
          element['isAllSelected'] = true;
        }
      
      
      });

      this.traderJson = traderJson;
    })
  }
  navigateNext() {
    if (this.readOnlyMode) {
      let lazyComp: any = import('../../vendor-profile-forms/emergency-contact/emergency-contact.component').then(({ EmergencyContactComponent }) => EmergencyContactComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'EmergencyContactComponent' });
    }
    else {
      this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
        let lazyComp: any = import('../../vendor-profile-forms/emergency-contact/emergency-contact.component').then(({ EmergencyContactComponent }) => EmergencyContactComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'EmergencyContactComponent' });

      })
    }
  }
  navigatePrevious() {
    let lazyComp: any = import(
      "../../vendor-profile-forms/warehouse-shipping-address/warehouse-shipping-address.component"
    ).then(({ WarehouseShippingAddressComponent }) => WarehouseShippingAddressComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "WarehouseShippingAddressComponent",
    });
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;



    let selctedClassification = [];
    // this.activeCategory['subCategoriesList'].filter((elem: any) => elem.isSelected).id
    // 
    // this.activeCategory['subCategoriesList'].forEach(element => {
    //   if (element.isSelected) {
    //     selctedClassification.push(element.classificationType.classificationCode)
    //   }
    // });

    this.allSelectedSubCategories.forEach(element => {
      selctedClassification.push(element.classificationType.classificationCode)
    });


    this.traderJson.traderProfile.productClassifications = selctedClassification;
    this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
      this.editableFlag = false;
      this.toastr.showSuccess('Updated Successfully');
      this.ngOnInit();
      this.sharedService.traderDetails.next(res);
      this.loader = false;
    })
  }

  // handleAllCheckboxClick(event) {

  //   this.activeCategory.subCategoriesList.forEach(element => {
  //     element.classificationType.isSelected = event.target.checked

  //     if (event.target.checked) {
  //       let isAvailable = this.allSelectedSubCategories.find((xElem: any) => xElem.classificationType.classificationCode === element.classificationType.classificationCode)
  //       if (!isAvailable) {
  //         this.allSelectedSubCategories.push(element)
  //       }
  //     } else {
  //       this.allSelectedSubCategories = this.allSelectedSubCategories.filter((xElem: any) => xElem.classificationType.classificationCode != element.classificationType.classificationCode)
  //     }
  //   });



  // }
  handleAllCheckboxClick(event: any) {
    if (this.activeCategory) {
      // Update the sub-categories of the active category based on the state of the "Select All" checkbox
      this.activeCategory.subCategoriesList.forEach(subcatVal => {
        subcatVal.classificationType.isSelected = event.target.checked;
      });
  
      // Update the "Select All" checkbox state
      this.activeCategory.isAllSelected = event.target.checked;
  
      // Update the allSelectedSubCategories array based on the state of the "Select All" checkbox
      if (event.target.checked) {
        // Add sub-categories to the allSelectedSubCategories array
        this.allSelectedSubCategories.push(...this.activeCategory.subCategoriesList);
      } else {
        // Remove sub-categories from the allSelectedSubCategories array
        this.allSelectedSubCategories = this.allSelectedSubCategories.filter(subcat => {
          return !this.activeCategory.subCategoriesList.includes(subcat);
        });
      }
    }
  }
  
  

  
  
  
  

  handleSelectionOfSubCat(event, subcatValue) {
    let temCountArr = [];
    this.activeCategory['subCategoriesList'].find((xelem: any) => xelem.classificationType.classificationCode === subcatValue.classificationType.classificationCode).isSelected = event.target.checked
    temCountArr = this.activeCategory['subCategoriesList'].filter((elem: any) => elem.isSelected)

    if (temCountArr.length == this.activeCategory['subCategoriesList'].length) {

      this.activeCategory['isAllSelected'] = true;
    } else {
      this.activeCategory['isAllSelected'] = false;
    }

    if (event.target.checked) {
      let isAvailable = this.allSelectedSubCategories.find((xElem: any) => xElem.classificationType.classificationCode === subcatValue.classificationType.classificationCode)
      if (!isAvailable) {
        this.allSelectedSubCategories.push(subcatValue)
      }
    } else {
      this.allSelectedSubCategories = this.allSelectedSubCategories.filter((xElem: any) => xElem.classificationType.classificationCode != subcatValue.classificationType.classificationCode)
    }

  }
}
