<div class="bank-information">
    <div class="container-fluid" *ngIf="traderJson">
        <button  class="btn"
        style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()"><span 
          *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
          *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span> </button>

          <form #bankdetails="ngForm">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.bankHolderName}}<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" name="bankHoldersName"
                    [(ngModel)]="traderJson.traderProfile.traderBankAccounts[0].accountHolderName"
                    [disabled]="!editableFlag && readOnlyMode" required [ngClass]="{'has-error': isFieldInvalid()&& !input1.model}" #input1="ngModel">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.bankAccountType}}<span
                            class="text-danger">*</span></label>
                    <select class="form-select  form-select-sm"  name="bankAccountType"
                    [(ngModel)]="traderJson.traderProfile.traderBankAccounts[0].accountType.code"
                    [disabled]="!editableFlag && readOnlyMode" required [ngClass]="{'has-error': isFieldInvalid()&& !input2.model}" #input2="ngModel">
                    <option value="" selected disabled>Select Bank Account Type</option>
                    <option *ngFor="let dropdownElement of accountType" [value]="dropdownElement.code">
                      {{dropdownElement.labels[0].text}}</option>

                    </select>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.bankAccountNumber}}<span
                            class="text-danger">*</span></label>
                    <input type="email" class="form-control form-control-sm" aria-describedby="helpId"
                    name="bankAccountNumber"
                    [(ngModel)]="traderJson.traderProfile.traderBankAccounts[0].accountNumber"
                    [disabled]="!editableFlag && readOnlyMode" required [ngClass]="{'has-error': isFieldInvalid()&& !input3.model}" #input3="ngModel">

                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.bankName}}<span
                            class="text-danger">*</span></label>
                            <input type="email" class="form-control form-control-sm" aria-describedby="helpId"
                            name="bankName"
            [(ngModel)]="traderJson.traderProfile.traderBankAccounts[0].bankName"
            [disabled]="!editableFlag && readOnlyMode" required [ngClass]="{'has-error': isFieldInvalid()&& !input4.model}" #input4="ngModel">


                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.ifscCode}}<span
                            class="text-danger">*</span></label>
                    <input type="email" class="form-control form-control-sm" name="ifsc"
                    [(ngModel)]="traderJson.traderProfile.traderBankAccounts[0].ifsc" [disabled]="!editableFlag && readOnlyMode"
                    required [ngClass]="{'has-error': isFieldInvalid()&& !input5.model}" #input5="ngModel">

                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.branchName}}
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                    <input type="email" class="form-control form-control-sm"name="branchName"
                    [(ngModel)]="traderJson.traderProfile.traderBankAccounts[0].branchName"
                    [disabled]="!editableFlag && readOnlyMode">

                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-4" *ngIf="!imageLoader">
                <label for="" class="text-label" >{{appLabel.bankAttachment}}<span
                    class="text-danger" >*</span> </label>
                <div class="form-group" style="display: flex;align-items: center;" [ngClass]="{'has-error': isFieldInvalid()}" >
                    <button class="outline-upload-button" style=" margin-right: 10px;" 
                        (click)="uploadTraderProfile.click()" [disabled]="!editableFlag && readOnlyMode"><span *ngIf="!attachedDoc" >Upload</span>
                        <span  *ngIf="attachedDoc">Re-Upload</span>
                    </button>
                    <input #uploadTraderProfile id="uploadPDFProfile" type="file" accept="image/*" (change)="addAttachment($event)" style="display: none"
                        (click)="$event.target.value=null" required />
                    <span class="muted-notification-text">Image (.jpg,.jpeg,.png)of Size less than 6mb can be
                        uploaded.</span>
                </div>

            </div>

            <div class="col-12 col-md-4" *ngIf="!imageLoader && imgFlag">
                <div class="image-wrap">
                    <img *ngIf="imgFlag; else pdfDoc" [src]="attachedDoc" alt="Loading Image" class="image-size" />
                    <span *ngIf="imageLoader" class="text-center"><i class="fa fa-spinner fa-pulse fa-x fa-fw" style="margin-left: 50%;"></i></span>
                    <ng-template #pdfDoc>
                      <p> {{attachedDoc}} </p>
                    </ng-template>
                  </div>
            </div>
        
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-4"></div>

        </div>
        <div class="full-width-div">
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">
                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                        style="height:28px;width:100px;left:15px" [disabled]="editableFlag" (click)="navigatePrevious()">
                        <i class="fa fa-angle-double-left" style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                        Previous
                    </button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                    <button [disabled]="editableFlag " type="button"
                        class="btn  btn-sm profile-side-nav-next-prev-button"
                        style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                        Next&nbsp; &nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>

                    <button type="button"  [disabled]="bankdetails.form.invalid || !attachedDoc  ||( !editableFlag && readOnlyMode)"
                                class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                                style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>
          </form>
    </div>
</div>