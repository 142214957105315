<div class="product-classification">
    <div class="container-fluid">
        <button class="btn" style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()">
        
        <span 
        *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
        *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span>  </button>

        <div class="row">
            <div class="col-12" style="padding-left:0px;border-bottom: 2px solid  #ededed;">
                <div class="btn-group">

                    <ng-container *ngFor="let parentCategory of parentCategoryList">
                        <a (click)="checkActivation(parentCategory)"  class="btn btn-primary-nav "
                            [class.active]="parentCategory.classificationType.classificationCode==activeCategory.classificationType.classificationCode"
                            aria-current="page">{{parentCategory.classificationType.name}}</a>

                    </ng-container>

                </div>
            </div>

        </div>
        <div class="row mt-2">
            <div class="col-12" style="border-bottom: 2px solid  #ededed;">
                <div class="form-check " style="    margin: 0px 1rem;
            ">
                   <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [disabled]="!editableFlag"
                   [checked]="activeCategory.isAllSelected" (change)="handleAllCheckboxClick($event)">
                 <label class="form-check-label" for="defaultCheck1">
                   Select All
                 </label>
                 
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4" style=" column-count: 2;
            column-gap: 20px; 
            max-height: 300px; 
            overflow: hidden;">

                <ul class="list-group" style="width:350px;    border: 0px;">
                    <ng-container *ngFor="let subcatVal of activeCategory.subCategoriesList;let i=index">
                        <li class="list-group-item" style="border: 0px;">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" [disabled]="!editableFlag" name="checkbox-{{ i }}"
                                        [checked]="subcatVal.classificationType.isSelected" (change)="handleSelectionOfSubCat($event,subcatVal)" type="checkbox" value="">
                                    {{subcatVal.classificationType.name}}
                                </label>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>


        <div class="full-width-div">
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">

                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                        style="height:28px;width:100px;left:15px" [disabled]="editableFlag " (click)="navigatePrevious()">
                        <i class="fa fa-angle-double-left" style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                        Previous
                    </button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                    <button [disabled]="editableFlag " type="button"
                        class="btn  btn-sm profile-side-nav-next-prev-button"
                        style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                        Next&nbsp; &nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>


                    <button type="button" [disabled]="!editableFlag && readOnlyMode"
                        class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                        style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>