import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class ComponentLoaderService {
  lazyComponent$ = new BehaviorSubject(0);
  lazyComponentName$ = new BehaviorSubject(0);
  activeComponentName$ = new BehaviorSubject(0);
  
  constructor(private http: HttpClient) { }
  
  getCurrentLazyComponent() {
    return this.lazyComponent$.asObservable();
  }
  getCurrentLazyComponentName() {
    return this.lazyComponentName$.asObservable();
  }


  storeLazyComponent(schoolsData:any) {
    
    // console.log("data stored", schoolsData);
    this.lazyComponent$.next(schoolsData.component);
    this.lazyComponentName$.next(schoolsData.componentName);
  }
  getJSONForSideNav(jsonPathVal:any){
    return this.http.get("./assets/JSON-FILES/" + jsonPathVal + ".json");
    
  }

  storeActiveComponentName(componentName){
    this.activeComponentName$.next(componentName);
  }
  getActiveComponentName(componentName){
    return this.activeComponentName$.asObservable();
    //this.activeComponentName$.next(componentName);
  }
}
