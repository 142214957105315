<div class="registered-address" *ngIf="traderJson">
    <div class="container-fluid">
        <button class="btn" style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()">
        <span *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span>
        <span *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span> </button>
        

        <form #registerAddress="ngForm"  *ngIf="isDataLoaded">

        <div class="row">
            <div class="col-12 col-md-4" *ngIf="appLabel.addressLine1.required">
                <div class="form-group">
                    <label for=" " class="text-label">{{appLabel.addressLine1.label}}<span class="text-danger">*</span></label>
                    <input type="email" class="form-control form-control-sm" id="" aria-describedby="emailHelp"
                        placeholder="" [(ngModel)]="traderJson.traderProfile.address.addressLine1" name="addressLine1" 
                        [ngClass]="{'has-error': isFieldInvalid() && !input1.model}" #input1="ngModel"
                        [disabled]="!editableFlag && readOnlyMode" required>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="appLabel.addressLine2.required">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.addressLine2.label}}</label>
                    <input type="email" class="form-control form-control-sm" id="" aria-describedby="emailHelp"
                        placeholder="" [(ngModel)]="traderJson.traderProfile.address.addressLine2" name="addressLine2"

                        [disabled]="!editableFlag && readOnlyMode">
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="appLabel.country.required">
                <div class="form-group">
                    <label for="" class="text-label">Country<span class="text-danger">*</span></label>
                    <select class="form-select  form-select-sm" aria-label="Default select example"
                        [(ngModel)]="traderJson.traderProfile.address.country.code" name="country" disabled required
                        [ngClass]="{'has-error': isFieldInvalid() && !input2.model}" #input2="ngModel"
                        >
                        <option value="" selected disabled>Select Country</option>
                        <option *ngFor="let dropdownElement of country" [value]="dropdownElement.code">
                            {{dropdownElement.labels[0].text}}</option>
                    </select>

                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4"  *ngIf="appLabel.state.required">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.state.label}} <span class="text-danger">*</span></label>
                    <select class="form-select  form-select-sm" aria-label="Default select example"
                        [(ngModel)]="traderJson.traderProfile.address.state.code" name="state"
                        (change)=" getDistrict(traderJson.traderProfile.address.state.code,1);"
                        [disabled]="!editableFlag && readOnlyMode" required
                        [ngClass]="{'has-error': isFieldInvalid() && !input3.model}" #input3="ngModel">
                        <option value="" selected disabled>Select State</option>
                        <option *ngFor="let dropdownElement of states" [value]="dropdownElement.code">
                            {{dropdownElement.labels[0].text}}</option>
                    </select>

                </div>
            </div>
            <div class="col-12 col-md-4"  *ngIf="appLabel.district.required">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">District<span
                            class="text-danger">*</span></label>
                    <select class="form-select  form-select-sm" aria-label="Default select example"
                        [(ngModel)]="traderJson.traderProfile.address.district.code" name="district"
                        (change)="getBlock(traderJson.traderProfile.address.district.code,1)"
                        [disabled]="!editableFlag && readOnlyMode" required
                        [ngClass]="{'has-error': isFieldInvalid() && !input4.model}" #input4="ngModel">
                        <option value="" selected disabled>Select District</option>
                        <option *ngFor="let dropdownElement of district" [value]="dropdownElement.code">
                            {{dropdownElement.labels[0].text}}</option>
                    </select>

                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="appLabel.block.required">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">Block<span class="text-danger">*</span></label>
                    <select class="form-select  form-select-sm" aria-label="Default select example"
                        [(ngModel)]="traderJson.traderProfile.address.block.code" name="block"
                        [disabled]="!editableFlag && readOnlyMode" required
                        [ngClass]="{'has-error': isFieldInvalid() && !input5.model}" #input5="ngModel">
                        <option value="" selected disabled>Select Block</option>
                        <option *ngFor="let dropdownElement of block" [value]="dropdownElement.code">
                            {{dropdownElement.labels[0].text}}</option>
                    </select>

                </div>
            </div>

        </div>


        <div class="row mt-4">
            <div class="col-12 col-md-4" *ngIf="appLabel.pinCode.required">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.pinCode.label}}<span class="text-danger">*</span></label>
                    <input type="number" class="form-control form-control-sm"
                        [(ngModel)]="traderJson.traderProfile.address.pinCode" name="pinCode"
                        [disabled]="!editableFlag && readOnlyMode" required
                        [ngClass]="{'has-error': isFieldInvalid() && !input6.model}" #input6="ngModel">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Landmark</label>
                    <input type="text" class="form-control form-control-sm" id="" aria-describedby="emailHelp"
                        placeholder="" [(ngModel)]="traderJson.traderProfile.address.landmark" name="landmark"
                        [disabled]="!editableFlag && readOnlyMode">
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="appLabel.gst.required">
                <div class="form-group">
                    <label for="" class="text-label">{{appLabel.gst.label}}<span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" id="" aria-describedby="emailHelp"
                      
                    placeholder="" [(ngModel)]="traderJson.traderProfile.gstNumber" name="gstNumber"
                    [disabled]="!editableFlag && readOnlyMode" required [ngClass]="{'has-error': isFieldInvalid(input7.name)  && !input7.model}" #input7="ngModel">
                        <span class="muted-notification-text">In case GST is not applicable, please write -NA- in the GSTIN column</span>
                       
                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4" *ngIf="!imageLoaderTAN"  [ngClass]="{'has-error': isFieldInvalid(uploadTraderProfile.id)}">
                <label for="" class="text-label">{{appLabel.gstAttachment.label}}<span class="text-danger">*</span> </label>
                <div class="form-group" style="display: flex;align-items: center;">
                    <button class="outline-upload-button" style=" margin-right: 10px;"
                        (click)="uploadTraderProfile.click()" [disabled]="!editableFlag && readOnlyMode"><span
                            *ngIf="!tanNumberAttachment">Upload</span>
                        <span *ngIf="tanNumberAttachment">Re-Upload</span>
                    </button>
                    <input #uploadTraderProfile id="GstAttachment" type="file" accept="image/*"
                        (change)="addAttachment($event, 'tanNumber')" style="display: none"
                        (click)="$event.target.value=null" />
                    <span class="muted-notification-text">Image (.jpg,.jpeg,.png)of Size less than 6mb can be
                        uploaded.</span>
                </div>

            </div>

            <div class="col-12 col-md-4" *ngIf="!imageLoaderTAN  && imgFlagTAN">
                <div class="image-wrap">
                    <img *ngIf="imgFlagTAN; else pdfDoc" [src]="tanNumberAttachment" alt="Loading Image"
                        class="image-size" />
                    <span *ngIf="imageLoaderTAN" class="text-center"><i class="fa fa-spinner fa-pulse fa-x fa-fw"
                            style="margin-left: 50%;"></i></span>
                    <ng-template #pdfDoc>
                        <p> {{tanNumberAttachment}} </p>
                    </ng-template>
                </div>
            </div>

        </div>

        <div class="row" style="margin-top: 40px;">
            <div class="full-width-div">
                <div class="row">
                    <div class="col-12"
                        style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">
                        <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                        style="height:28px;width:100px;left:15px" [disabled]="editableFlag " (click)="navigatePrevious()">
                        <i class="fa fa-angle-double-left" style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                        Previous
                    </button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                    <button [disabled]="editableFlag " type="button"
                        class="btn  btn-sm profile-side-nav-next-prev-button"
                        style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                        Next&nbsp; &nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>

                        <button type="button"  [disabled]="registerAddress.form.invalid || !tanNumberAttachment || ( !editableFlag && readOnlyMode) "
                                    class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                                    style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                                <!-- <button type="button" class="btn btn-warning btn-sm page-header-button">Save</button> -->
                    </div>
                </div>
            </div>
        </div>
                    </form>
    </div>
</div>