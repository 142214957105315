import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-additional-kyc',
  templateUrl: './additional-kyc.component.html',
  styleUrls: ['./additional-kyc.component.scss']
})
export class AdditionalKycComponent implements OnInit  {
  attachedDoc: any;
  fd: FormData;
  // imgFlag: boolean;
  tanNumberAttachment: any;
  nodalLetterAttachment: any;
  authorizedName: any;
  traderJson: any;
  readOnlyMode: boolean;
  editableFlag: boolean;
  successfullyRegisteredFlag: boolean;
  appLanguage: string;
  appLabel: any;
  refDataCodes: any;
  traderRefDataCodes: any;
  imageLoaderTAN: boolean;
  imageLoaderAuthorizedName: boolean;
  loader: boolean;
  imgFlagTAN: boolean;
  imgFlagAuthorizedName: boolean;
  imageLoaderGST: boolean;
  imgFlagGST:boolean;
  gstAttachment:any;
  gst: SafeResourceUrl;
  safeIframeUrl:SafeResourceUrl;
  @ViewChild('download') private download;
  resGst: any;
  mandatoryFields: any[];


  constructor(private service: SharedService, private router: Router ,private sanitizer: DomSanitizer, private modalService: NgbModal, 
    private componentLoaderService:ComponentLoaderService , private toastr: ToastrCustomService,
    ) { }

  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getAppLabels();
    this.getDataCodes();

     this.getTraderJson();
    this.attachedDoc = '';
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;
  }
  // addTanNumberAttachment(){
  //   this.addAttachment()
  // }

  getAppLabels() {
    this.service
      .getJSONData("app-labels/" + this.appLanguage + "/traderProfile")
      .subscribe((res) => (this.appLabel = res["aditionalKYC"]));
  }
  getDataCodes() {
    this.service.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.service.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getTraderJson();
      });
    }
    );
  }
  getTraderJson() {
    this.service.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(res => {
      this.traderJson = res[0];
      this.getAttachments();
    })
  }
  getAttachments() {
    this.imageLoaderTAN = true;
    this.imageLoaderAuthorizedName = true;
    this.imageLoaderGST = true;
    this.service.getAttachment(this.traderRefDataCodes.attachment.kycTan, sessionStorage['trader_id']).subscribe(resTAN => {
      if (resTAN) {
        this.imgFlagTAN = true;
        console.log('Attachment Response', resTAN);
        this.tanNumberAttachment = "data:image/png;base64," + (resTAN.attachment);
        this.imageLoaderTAN = false;
        console.log(this.tanNumberAttachment);
      }
      // this.tanNumberAttachment = reader.readAsDataURL(resTAN.attachment);
      // this.tanNumberAttachment = resTAN.attachment;
    }, err => {
      this.imageLoaderTAN = false;
    })
    // this.service.getAttachment('TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER', sessionStorage['trader_id'], sessionStorage['auth_token']).subscribe(resNodal => {
    //   if (resNodal) {
    //     this.imgFlag = true;
    //     this.nodalLetterAttachment = "data:image/png;base64," + resNodal.attachment;
    //   }
    // })
    this.service.getAttachment(this.traderRefDataCodes.attachment.authorizedSignatory, sessionStorage['trader_id'],).subscribe(resSignatory => {
      if (resSignatory) {
        this.imgFlagAuthorizedName = true;
        this.authorizedName = "data:image/png;base64," + resSignatory.attachment;
        console.log("sign")
        this.imageLoaderAuthorizedName = false;
       
      }
    }, err => {
      this.imageLoaderAuthorizedName = false;
    })
    // this.service.getAttachment(this.traderRefDataCodes.attachment.gstNumber, sessionStorage['trader_id'], sessionStorage['auth_token']).subscribe(resSignatory => {
    //   if (resSignatory !== null) {
        
    //     this.imgFlagGST = true;
    //     // console.log(resSignatory.attachment)
    //     this.gstAttachment = "data:application/pdf;base64," + resSignatory.attachment;
    //     console.log("pdfff",this.gstAttachment);
    //     this.imageLoaderGST = false;
    //     const pdfViewer = document.getElementById("pdfViewer") as HTMLIFrameElement;
        
    //      pdfViewer.src=this.gstAttachment;
    //   }
    // }, err => {
    //   this.imageLoaderGST = false;
    // })
    this.service.getAttachment(this.traderRefDataCodes.attachment.gstNumber, sessionStorage['trader_id']).subscribe(resSignatory => {

      if (resSignatory !== null) {
       this.resGst=resSignatory
        
        if (resSignatory.fileName.includes('.pdf')) {
          this.imgFlagGST = false;
        
          const base64Pdf = "data:application/pdf;base64," + resSignatory.attachment;
          this.gst = this.sanitizer.bypassSecurityTrustResourceUrl(base64Pdf);
          this.safeIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(base64Pdf);
          this.imageLoaderGST = false;
          console.log("h2", this.imageLoaderGST, this.imgFlagGST);
          console.log("content", this.gstAttachment);
        }
        

         else{
 
          this.imgFlagGST = true;
          this.gstAttachment= "data:image/png;base64," + resSignatory.attachment;
          this.imageLoaderGST = false;
          console.log("h1",this.imageLoaderGST,this.imgFlagGST);
          console.log("content",this.gst)
        
         }
         this.imageLoaderGST = false;
        
      }
     
    
    else{
      console.log("error");
    }
  }, err => {
      this.imageLoaderGST = false;
    });
      
  }

  


  addAttachment(event, attachmentName) {
    console.log(event.target.files[0]);
    const attachedFile = event.target.files[0];
  
    if (attachedFile.type != 'application/pdf') {
      this.fd = new FormData();
        this.fd.append('file', attachedFile);
        const reader = new FileReader();
        reader.onload = ((e) => {
          if (attachmentName == 'tanNumber') {
            this.imgFlagTAN = true;
            this.tanNumberAttachment = e.target['result'];
            //Checking file size
            if (attachedFile.size > (6 * 1048576)) {
              this.tanNumberAttachment = '';
               this.toastr.showError('Image size should be less than 6Mb');
            }
            else {
              console.log('File size is less than 2Mb', this.tanNumberAttachment)
              this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.kycTan).subscribe(attachmentResponse => {
                console.log('Attachment Added Successfully', attachmentResponse);
                this.service.traderAttachment.next([this.traderRefDataCodes.attachment.kycTan, this.tanNumberAttachment]);
              })
            }
          }
          // else if (attachmentName == 'nodalLetter') {
          //   this.nodalLetterAttachment = e.target['result'];
          //   this.service.addAttachment(sessionStorage['trader_id'], this.fd, 'TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER', sessionStorage['auth_token']).subscribe(attachmentResponse => {
          //     console.log('Attachment Added Successfully', attachmentResponse);
          //   this.service.traderAttachment.next(['TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER',this.nodalLetterAttachment]);
          //   })
          // }
          else if (attachmentName == 'authorizedSignature') {
            this.imgFlagAuthorizedName = true;
            this.authorizedName = e.target['result'];
            //Checking file size
            if (attachedFile.size > (6 * 1048576)) {
              this.authorizedName = '';
              this.toastr.showError('Image size should be less than 6Mb');
            }
            else {
              console.log("-----------------",this.traderRefDataCodes);
              
              this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.authorizedSignatory,).subscribe(attachmentResponse => {
                console.log('Attachment Added Successfully', attachmentResponse);
                this.service.traderAttachment.next([this.traderRefDataCodes.attachment.authorizedSignatory, this.authorizedName]);
              })
            }
          }
  
          else if (attachmentName == 'gstNumber') {
            this.imgFlagGST = true;
            this.gstAttachment = e.target['result'];
            //Checking file size
            if (attachedFile.size > (5 * 1048576)) {
              this.gstAttachment = '';
              this.toastr.showError('Image size should be less than 5Mb');
            }
            else {
              console.log("-----------------",this.traderRefDataCodes);
              this.imgFlagGST = true;
               this.toastr.showSuccess("image is uploaded");
              this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.gstNumber).subscribe(attachmentResponse => {
                console.log('Attachment Added Successfully img', attachmentResponse);
                this.service.traderAttachment.next([this.traderRefDataCodes.attachment.gstNumber, this.gstAttachment]);
              })
            }
            console.log("h3",this.imageLoaderGST,this.imgFlagGST);
          }
        });
        reader.readAsDataURL(event.target.files[0]);
  
    } else {
     
       // Handle PDF attachments
        this.fd = new FormData();
        this.fd.append('file', attachedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        

  
        if (attachmentName == 'gstNumber') {
          // Handle GST number attachment
          this.imgFlagGST = false;
          this.gstAttachment = e.target['result'];
  
          if (attachedFile.size > 5 * 1048576) {
            this.gstAttachment = '';
           this.toastr.showError('File size should be less than 5Mb');
          } else {
            this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.gstNumber)
              .subscribe(attachmentResponse => {
                 this.toastr.showSuccess('PDF is uploaded');
                console.log('Attachment Added Successfully: PDF uploaded', attachmentResponse);
                this.gst = this.sanitizer.bypassSecurityTrustResourceUrl(this.gstAttachment);
                this.safeIframeUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.gstAttachment)
                this.imgFlagGST = false;
                this.service.traderAttachment.next([this.traderRefDataCodes.attachment.gstNumber, this.gstAttachment]);
              });
          }
        }
      };
      reader.readAsDataURL(attachedFile);
     
      console.log("h4",this.imageLoaderGST,this.imgFlagGST);
    }
  }
  

  navigateNext() {
    let lazyComp: any = import('../../vendor-profile-forms/display-information/display-information.component').then(({ DisplayInformationComponent }) => DisplayInformationComponent);
    this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'DisplayInformationComponent' });
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;
    this.service.patchTraderProfile(this.traderJson).subscribe(res => {
      this.editableFlag = false;
       this.toastr.showSuccess('Details Updated Successfully');
      console.log('Details Updated Successfully');
      this.service.traderDetails.next(res);
      this.loader = false;
      this.modalService.open(this.download, { centered: true, backdrop: 'static' })
    })
  }

  submitDetails() {
    this.service.patchTraderProfile(this.traderJson).subscribe(res => {
      this.editableFlag = false;
      this.successfullyRegisteredFlag = true;
      this.modalService.open(this.download, { centered: true, backdrop: 'static' })
      setTimeout(() => {
        this.successfullyRegisteredFlag = false;
        this.router.navigate(['vendorDashboard/market-channel']);
      }, 2000)
      console.log('Details Submitted Successfully');
    })
  }


  navigatePrevious(){
    let lazyComp: any = import(
      "../../vendor-profile-forms/emergency-contact/emergency-contact.component"
    ).then(({ EmergencyContactComponent }) => EmergencyContactComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "EmergencyContactComponent",
    });
  }
  isFieldInvalid(fieldName: string): boolean {
    this.mandatoryFields = this.service.dataArray;
  return this.mandatoryFields.includes(fieldName);



    
}
closeModal(fromPdf: boolean) {
  // Close the modal only if the event is not triggered from app-trader-details-pdf component
  if (!fromPdf) {
    this.modalService.dismissAll();
  }
}

onPdfClicked(fromPdf: boolean) {
  this.closeModal(fromPdf); // Call closeModal when pdf is clicked
}

}

