// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://dev.platformcommons.org/gateway/',
  token: "Apikey 8a034304-864e-4a39-b78f-b4cae0dbdf25",
  channelCode: "FLOURISH",
  s3templateURL: "https://pcf-static-public-data.s3.ap-south-1.amazonaws.com/app-vendor-self-registration/flourish/line-item-sheet-template/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
