import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrls: ['./pagination-control.component.scss']
})
export class PaginationControlComponent implements OnInit {

 
  @Input() collectionSize: number;
  @Input() pageSize: number;
  @Input() currentPage: number;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }
  onPageChange(pageNumber: number): void {
    this.pageChange.emit(pageNumber);
  }

}
