import { Component, Input, OnInit } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-communication-address',
  templateUrl: './communication-address.component.html',
  styleUrls: ['./communication-address.component.scss']
})
export class CommunicationAddressComponent implements OnInit {
  @Input() receivedData: any[]

  traderJson: any;
  readOnlyMode:boolean;
  country: any;
  city: any;
  district: any;
  block: any
  states: any;
  editableFlag: boolean;
  currentIndex: number;
  appLanguage: string;
  appLabel: any;
  refDataCodes: any;
  traderRefDataCodes: any;
  loader: boolean;
  isDataLoaded;
  labelValueLanguage: string;

  _contactPersonMobileIndex = -1;
  _contactPersonLandlineIndex = -1;
  _contactPersonMandatoryEmailIndex = -1;
  _contactPersonOptionalEmailIndex = -1;

  traderProfile: {};
  copyaddress = false


 
  mandatoryFields: any[];

  constructor(private service: SharedService,private componentLoaderService:ComponentLoaderService,
    private toastr:ToastrCustomService) { }

  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    if (this.appLanguage) {
      this.labelValueLanguage = this.appLanguage.split('-')[0];
    }
    this.getAppLabels();
    this.getDataCodes();

    // this.getCountryGREF();
    // this.getStatesGREF();
     this.getTraderJson();
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;
  }

  // getTraderJson() {
  //   this.service.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(res => {
  //     this.traderJson = res[0];
  //     if (this.traderJson.traderProfile.address.pinCode == "" || this.traderJson.traderProfile.address.pinCode == "000000") {
  //       this.traderJson.traderProfile.address.pinCode = null;
  //     }
  //     if (this.traderJson.traderProfile.address.hasOwnProperty('district') && this.traderJson.traderProfile.address.district.code) {
  //       this.getDistrictGREF(this.traderJson.traderProfile.address.district.code);
  //     }
  //     else {
  //       this.traderJson.traderProfile.address['district'] = { code: '' };
  //       this.getDistrict(this.traderJson.traderProfile.address.state.code, 1);
  //     }
  //     if (this.traderJson.traderProfile.address.hasOwnProperty('block') && this.traderJson.traderProfile.address.block.code)
  //       this.getBlockGREF(this.traderJson.traderProfile.address.block.code);
  //     else {
  //       this.traderJson.traderProfile.address['block'] = { code: '' };
  //       this.getBlock(this.traderJson.traderProfile.address.district.code, 1);
  //     }
  //     this.isDataLoaded = true;
  //   })
  // }

  getLanguageWiseLabelValues(arr: Array<any>) {
    arr.forEach((o, i) => {
      let _index = o.labels.findIndex((obj, ind) => {
        return obj.language.code == this.labelValueLanguage;
      })

      if (_index != 0) {
        let _obj = o.labels.splice(_index, 1);
        let tempArray: any[] = [];
        tempArray.push(_obj[0]);
        tempArray.push(o.labels);
        o.labels = tempArray;
      }
    })

    return arr;
  };

  getAppLabels() {
   
    this.service
    .getJSONData("app-labels/" + this.appLanguage + "/traderProfile")
      .subscribe((res) => {
        this.appLabel = res['communicationAddress'];
        console.log("albel,",this.appLabel)
      });
  }
  getDataCodes() {
    this.service.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.service.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getCountryGREF();
        this.getStatesGREF();
      });
    }
    );
  }
  getCountryGREF() {
    this.service.getGREFDataByDataCode(this.refDataCodes.country).subscribe(res => {
      let arr: any = [];
      arr.push(res);

      if (arr) {
        this.country = this.getLanguageWiseLabelValues(arr);
      }
    });
  }
  getStatesGREF() {
    this.service.getGREFDataHierarchyByParentCode(this.refDataCodes.country, this.refDataCodes.state, sessionStorage['auth_token'], 1).subscribe(res => {
      let _tempStates = res.map(x => x.child);
      if (_tempStates) {
        this.states = this.getLanguageWiseLabelValues(_tempStates);
      }
      this.states.forEach(data => {
        data.labels = data.labels.filter(cData => cData != null || cData != undefined);
      });
      this.getTraderJson();
    });
  }
  getTraderJson() {
    this.service.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(traderResponse => {

      this.traderProfile = traderResponse[0]
      console.log('Trader Details', traderResponse[0]);
      console.log('Trader Details', traderResponse[0]['traderProfile']['traderContactPersons']);
      let arr = [];
      arr = traderResponse[0]['traderProfile']['traderContactPersons'].filter((x, index) => {
        // //Sorting
        x['personContacts'] = x['personContacts'].sort(function (a, b) {
          var x = a.contactType.code.toLowerCase();
          var y = b.contactType.code.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        });

        if (x.division.code == this.traderRefDataCodes.division.communicationAddress) {
          this.currentIndex = index;
          console.log('Index---Current', this.currentIndex)
          return x;
        }
      });
      console.log('Updated ---', arr);
      if (traderResponse[0]['traderProfile']['traderContactPersons'].length == 0 || arr.length == 0) {
        console.log('Trader-Details Undefined');
        this.service.getJSONData('app-models/' + this.appLanguage + '/post-jsons/trader').subscribe(resJson => {
          traderResponse[0]['traderProfile']['traderContactPersons'].push(resJson['traderProfile']['traderContactPersons'][0]);
          this.traderJson = traderResponse[0];
          this.currentIndex = this.traderJson.traderProfile.traderContactPersons.length - 1;

          console.log('Trader-Json-Updated', this.traderJson);

          let _indexArray = [0, 1, 2, 3];

          this._contactPersonLandlineIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
            return o.contactType.code == "CONTACT_TYPE.LANDLINE"
          });

          this._contactPersonMobileIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
            return o.contactType.code == "CONTACT_TYPE.MOBILE"
          });

          this._contactPersonMandatoryEmailIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
            return (o.contactType.code == "CONTACT_TYPE.MAIL" && o.contactValue != '')
          });

          if (this._contactPersonMandatoryEmailIndex == -1) {
            this._contactPersonMandatoryEmailIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
              return (o.contactType.code == "CONTACT_TYPE.MAIL")
            });
          }

          this._contactPersonOptionalEmailIndex = _indexArray.findIndex((o, i) => {
            return (o != this._contactPersonLandlineIndex && o != this._contactPersonMobileIndex && o != this._contactPersonMandatoryEmailIndex)
          })
        })
      }
      else {
        this.traderJson = traderResponse[0];
        //this.getCityGREF(this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.city.code);
        if (this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.hasOwnProperty('district') && this.traderJson.traderProfile.address.district.code)
          this.getDistrictGREF(this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.district.code);
        else {
          if(this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.country.code != 'REGION_COUNTRY.USA')
          {
          this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.district.code = '';
          this.getDistrict(this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.state.code, 1);
          }
        }
        if (this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.hasOwnProperty('block') && this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.block.code)
          this.getBlockGREF(this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.block.code);
        else {
          this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress['block'] = { code: '' };
          this.getBlock(this.traderJson.traderProfile.address?.district?.code, 1);
        }

        let _indexArray = [0, 1, 2, 3];

        this._contactPersonLandlineIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
          return o.contactType.code == "CONTACT_TYPE.LANDLINE"
        });

        this._contactPersonMobileIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
          return o.contactType.code == "CONTACT_TYPE.MOBILE"
        });

        this._contactPersonMandatoryEmailIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
          return (o.contactType.code == "CONTACT_TYPE.MAIL" && o.contactValue != '')
        });

        if (this._contactPersonMandatoryEmailIndex == -1) {
          this._contactPersonMandatoryEmailIndex = this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personContacts.findIndex((o, i) => {
            return (o.contactType.code == "CONTACT_TYPE.MAIL")
          });
        }

        this._contactPersonOptionalEmailIndex = _indexArray.findIndex((o, i) => {
          return (o != this._contactPersonLandlineIndex && o != this._contactPersonMobileIndex && o != this._contactPersonMandatoryEmailIndex)
        })

      }

      this.isDataLoaded = true;

      console.log("traderProfile", this.traderProfile)
    })
  }
  getCityGREF(dataCode) {
    if (dataCode != "") {
      let arr = [];
      this.service.getGREFDataByDataCode(dataCode).subscribe(res => {
        arr.push(res);
        this.city = arr;
      });
    }
  }

  getCityDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.city, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      res.forEach(x => this.city.push(x.child));
      console.log('City', this.city);
      if (res.length > 99) {
        pageIndex += 1;
        this.getCityDataByPagination(event, pageIndex);
      }
    }, err => this.city = [])
  }

  getCity(event, startPageIndex) {
    this.city = [];
    this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.city.code = "";
    this.getCityDataByPagination(event, startPageIndex);
  }
  getDistrictGREF(dataCode) {
    if (dataCode != "") {
      let arr: any = [];
      this.service.getGREFDataByDataCode(dataCode).subscribe(res => {
        arr.push(res);
        if (arr) {
          this.district = this.getLanguageWiseLabelValues(arr);
        }
      });
    }
  }
  getBlockGREF(dataCode) {
    if (dataCode != "") {
      let arr = [];
      this.service.getGREFDataByDataCode(dataCode).subscribe(res => {
        arr.push(res);
        if (arr) {

          this.block = this.getLanguageWiseLabelValues(arr);

        }
      });
    }
  }

  getDistrictDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.district, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      //res.forEach(x => this.district.push(x.child));
      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.district.push(o.child)
      })

      console.log('District', this.district);
      if (res.length > 99) {
        pageIndex += 1;
        this.getDistrictDataByPagination(event, pageIndex);
      }
    }, err => this.district = [])
  }
  getBlockDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.block, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.block.push(o.child)
      })
      // res.forEach(x => this.block.push(x.child));
      console.log('Block', this.block);
      if (res.length > 99) {
        pageIndex += 1;
        this.getBlockDataByPagination(event, pageIndex);
      }
    }, err => this.block = [])
  }

  getDistrict(event, startPageIndex) {
    this.district = [];
    this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.district.code = "";
    let pageIndex = startPageIndex;
    console.log('Clicked --1');
    this.getDistrictDataByPagination(event, startPageIndex);
  }
  getBlock(event, startPageIndex) {

    this.block = [];
    this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.block.code = "";
    let pageIndex = startPageIndex;
    console.log('Clicked --1');
    this.getBlockDataByPagination(event, startPageIndex);
  }

  // getCity(event, startPageIndex) {
  //   this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.city.code = "";
  //   let pageIndex = startPageIndex;
  //   this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.city, sessionStorage['auth_token'], pageIndex).subscribe(res => {
  //     res.forEach(x => this.city.push(x.child));
  //     console.log('Cities', this.city);
  //     if (res.length > 99) {
  //       pageIndex += 1;
  //       this.getCity(event, pageIndex);
  //       console.log('City List', pageIndex);
  //     }
  //     if (this.city.length == 0)
  //       this.city = [];
  //     console.log(this.city);
  //   }, err => {
  //     this.city = [];
  //   })
  // }
  // getCity(event) {
  //   this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.city, sessionStorage['auth_token'],1).subscribe(res => {
  //     this.city = res.map(x => x.child);
  //     if (this.city.length == 0)
  //       this.city = [];
  //     console.log(this.city);
  //   })
  // }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  navigateNext() {
    //PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
    
  
    console.log(this.copyaddress);
    
    if (this.copyaddress) {
  
      const { uuid, id, ...address } = this.traderJson.traderProfile.address
  
      this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress = { ...this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress, ...address }
  
    } else {
  
      if (this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode.toString().length < 5 || this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode.toString().length > 6) {
         this.toastr.showError('Please enter a valid pinCode!');
        this.loader = false;
      }
      //PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
      if (this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode.toString().length == 5) {
        this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode = '0' + this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode;
      }
    }
  
  
    // if (this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode.toString().length == 5)
    //   this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode = '0' + this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode;
  
    if (this.readOnlyMode) {
      let lazyComp: any = import('../../vendor-profile-forms/finance-division-contact-details/finance-division-contact-details.component').then(({ FinanceDivisionContactDetailsComponent }) => FinanceDivisionContactDetailsComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'FinanceDivisionContactDetailsComponent' });
    }
    else {
      
      this.service.patchTraderProfile(this.traderJson).subscribe(res => {
        let lazyComp: any = import('../../vendor-profile-forms/finance-division-contact-details/finance-division-contact-details.component').then(({ FinanceDivisionContactDetailsComponent }) => FinanceDivisionContactDetailsComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'FinanceDivisionContactDetailsComponent' });
        console.log('Details Updated Successfully')
      })
    }
  }
  
  navigatePrevious(){
    let lazyComp: any = import(
      "../../vendor-profile-forms/registered-address/registered-address.component"
    ).then(({ RegisteredAddressComponent }) => RegisteredAddressComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "RegisteredAddressComponent",
    });
  }
  updateTraderDetails() {

    this.loader = true;
    let isFormValid = true;


    if (isFormValid) {
      this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.city = null;

      if (this.copyaddress) {

        const { uuid, id, ...address } = this.traderJson.traderProfile.address

        this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress = { ...this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress, ...address }

      } else {

        if (this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode.toString().length < 5 || this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode.toString().length > 6) {
          this.toastr.showError('Please enter a valid pinCode!');
          isFormValid = false;
          this.loader = false;
        }
        //PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
        if (this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode.toString().length == 5) {
          this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode = '0' + this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.pinCode;
        }
      }



      this.service.patchTraderProfile(this.traderJson).subscribe({
        next: (res) => {
          this.editableFlag = false;
           this.toastr.showSuccess('Details Updated Successfully');
          console.log('Details Updated Successfully');
          this.service.traderDetails.next(res);
          this.loader = false;
        },
        error: () => {
          isFormValid = false;
          this.loader = false;
        },
        complete: () => { }
      })
    }
  }

  updateCommunicationAdressAsSame(event) {
    console.log(event.target.checked);

    console.log(this.currentIndex);

    console.log(this.traderJson);
    if (this.traderJson.traderProfile.address.hasOwnProperty('district') && this.traderJson.traderProfile.address.district.code)
      this.getDistrictGREF(this.traderJson.traderProfile.address.district.code);
    else {
      this.traderJson.traderProfile.address.district.code = '';
      this.getDistrict(this.traderJson.traderProfile.traderContactPersons[this.currentIndex].personAddress.state.code, 1);
    }
    if (this.traderJson.traderProfile.address.hasOwnProperty('block') && this.traderJson.traderProfile.address.block.code)
      this.getBlockGREF(this.traderJson.traderProfile.address.block.code);
    else {
      this.traderJson.traderProfile.address['block'] = { code: '' };
      this.getBlock(this.traderJson.traderProfile.address.district.code, 1);
    }




  }
  isFieldInvalid(): boolean {
    this.mandatoryFields = this.service.dataArray;
    const divisionCode = "Communication Address"
    return this.mandatoryFields.includes(divisionCode);
}
  
}



