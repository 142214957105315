<div class="additional-kyc">
    <div class="container-fluid" *ngIf="traderJson">
        <button class="btn" style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()">


            <span *ngIf="readOnlyMode && !editableFlag">
                <i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i>
            </span>
            <span *ngIf="readOnlyMode && editableFlag">
                <i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i>
            </span>

        </button>

        <form #addkyc="ngForm">
            <div class="row">

                <div class="col-12 col-md-4" *ngIf="appLabel.cin.required">
                    <div class="form-group">
                        <label for="" class="text-label d-block mb-2">{{appLabel.cin.label}} </label>
                        <input type="text" class="form-control" aria-describedby="helpId" placeholder="" name="cin"
                            [(ngModel)]="traderJson.traderProfile.cin" [disabled]="!editableFlag && readOnlyMode">
                    </div>
                </div>
                <!-- <div class="col-12 col-md-4"  *ngIf="appLabel.tanNumber.required">
                    <div class="form-group">
                        <label for="" class="text-label d-block mb-2">{{appLabel.tanNumber.label}}</label>
                        <input type="text" class="form-control" aria-describedby="helpId" placeholder=""
                             [(ngModel)]="traderJson.traderProfile.license"
                            [disabled]="!editableFlag && readOnlyMode">
                    </div>
                </div> -->
                <div class="col-12 col-md-4"  *ngIf="appLabel.organisation.required">
                    <div class="form-group">
                        <label for="" class="text-label d-block mb-2">{{appLabel.organisation.label}}</label>
                        <input type="text" class="form-control" aria-describedby="helpId" placeholder=""
                            name="organisation" [(ngModel)]="traderJson.traderProfile.license"
                            [disabled]="!editableFlag && readOnlyMode">
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group">
                        <label for="" class="text-label d-block mb-2">GST Number  <span class="text-danger">*</span></label>
                       
                        <input type="text" class="form-control" aria-describedby="helpId" placeholder=""
                            name="gstNumber" [(ngModel)]="traderJson.traderProfile.gstNumber"
                            [disabled]="!editableFlag && readOnlyMode" required>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 col-md-4">

                    <label for="" class="text-label d-block mb-2">{{appLabel.tanNumber.label}} </label>
                    <button class="outline-upload-button" style=" margin-right: 10px;"
                        (click)="uploadAttachment1.click()" [disabled]="!editableFlag && readOnlyMode"> <span
                            *ngIf="!tanNumberAttachment">Upload</span>
                        <span *ngIf="tanNumberAttachment">Re-Upload</span></button>
                    <input #uploadAttachment1 id="TanAttachement" type="file" accept="image/*"
                        (change)="addAttachment($event,'tanNumber')" style="display: none" 
                        (click)="$event.target.value=null" />
                    <span class="muted-notification-text d-block">Image (.jpg,.jpeg,.png)of Size less than 6mb can be
                        uploaded.</span>



                </div>
                <div class="col-12 col-md-4" [ngClass]="{'has-error': isFieldInvalid(uploadAttachment2.id)}" *ngIf="appLabel.nameOfAuthorizedSignatory.required">

                    <label for="" class="text-label d-block mb-2">{{appLabel.nameOfAuthorizedSignatory.label}} <span
                            class="text-danger">*</span></label>

                    <button class="outline-upload-button" style=" margin-right: 10px;"
                        (click)="uploadAttachment2.click()" [disabled]="!editableFlag && readOnlyMode"><span
                            *ngIf="!authorizedName">Upload</span>
                        <span *ngIf="authorizedName">Re-Upload</span></button>
                    <input #uploadAttachment2 id="SignatoryAttachment" type="file" accept="image/*"
                        (change)="addAttachment($event,'authorizedSignature')" style="display: none" required
                        (click)="$event.target.value=null" />
                    <span class="muted-notification-text d-block">Image (.jpg,.jpeg,.png)of Size less than 6mb can be
                        uploaded.</span>


                </div>
                <div class="col-12 col-md-4"  [ngClass]="{'has-error': isFieldInvalid(uploadAttachment3.id)}"  *ngIf="appLabel.tanNumber.required">

                    <label for="" class="text-label d-block mb-2">{{appLabel.gstAttachment.label}} <span class="text-danger">*</span></label>
                    <button class="outline-upload-button" style=" margin-right: 10px;"
                        (click)="uploadAttachment3.click()" [disabled]="!editableFlag && readOnlyMode"><span
                            *ngIf="!resGst">Upload</span>
                        <span *ngIf="resGst">Re-Upload</span></button>
                    <input #uploadAttachment3 id="GstAttachment" type="file" accept="image/*,application/pdf"
                        (change)="addAttachment($event,'gstNumber')" style="display: none;"
                        (click)="$event.target.value=null" [disabled]="!editableFlag && readOnlyMode" />
                    <span class="muted-notification-text d-block">Image (.jpg,.jpeg,.png)of Size less than 6mb can be
                        uploaded.</span>



                </div>

            </div>
            <div class="row mt-4">
                <div class="col-12 col-md-4"  *ngIf="appLabel.tanNumber.required">
                    <label for="" class="text-label d-block mb-2">{{appLabel.tanNumber.label}}</label>
     
                    <div class="file-container">
                        <div class="image-wrap" [hidden]="!imageLoaderTAN && !imgFlagTAN">
                            <img *ngIf="imgFlagTAN; else pdfDoc" [src]="tanNumberAttachment" alt="Loading Image"
                                class="image-size" />
                            <span *ngIf="imageLoaderTAN" class="text-center ml-50"><i
                                    class="fa fa-spinner fa-pulse fa-x fa-fw"></i></span>
                            <ng-template #pdfDoc>
                                <p> Tan Number Attachment </p>
                            </ng-template>
                        </div>
     
     
                    </div>
                </div>
                <div class="col-12 col-md-4" *ngIf="appLabel.nameOfAuthorizedSignatory.required">
                    <label for="" class="text-label d-block mb-2">ID Of Authorized Signatory</label>
     
     
                    <div class="file-container">
                        <div class="image-wrap" [hidden]="!imageLoaderAuthorizedName && !imgFlagAuthorizedName">
                            <img *ngIf="imgFlagAuthorizedName; else pdfDocSign" [src]="authorizedName" alt="Loading Image"
                                class="image-size" />
                            <span *ngIf="imageLoaderAuthorizedName" class="text-center ml-50"><i
                                    class="fa fa-spinner fa-pulse fa-x fa-fw"></i></span>
                            <ng-template #pdfDocSign>
                                <p> Authorized Name </p>
                            </ng-template>
     
     
                        </div>
     
     
                    </div>
                </div>
                <div class="col-12 col-md-4"  *ngIf="appLabel.tanNumber.required">
                    <label for="" class="text-label d-block mb-2">{{appLabel.gstAttachment.label}}</label>
     
     
                    <div class="file-container">
                       <div   *ngIf="resGst">
                        <div *ngIf="!imgFlagGST">
                            <iframe [src]="safeIframeUrl" width="100%" height="500px"></iframe>
                            <span *ngIf="imageLoaderGST" class="text-center ml-50"><i
                                    class="fa fa-spinner fa-pulse fa-x fa-fw"></i></span>
                        </div>
                        <div *ngIf="imgFlagGST">
     
                            <img id="gst" [src]="gstAttachment" alt="Loading" style="max-width: 100%;
                            height: 235px;" />
                            <span *ngIf="imageLoaderGST" class="text-center ml-50"><i
                                    class="fa fa-spinner fa-pulse fa-x fa-fw"></i></span>
     
                        </div>
                    </div>
     
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;">



                <div class="full-width-div">
                    <div class="row">
                        <div class="col-12"
                            style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">
                            <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                                style="height:28px;width:100px;left:15px" [disabled]="editableFlag"
                                (click)="navigatePrevious()">
                                <i class="fa fa-angle-double-left"
                                    style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;Previous </button>
                            <button type="button" *ngIf="editableFlag && readOnlyMode" [disabled]="!authorizedName ||!gstAttachment ||addkyc.form.invalid "
                                class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                                style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>

                            <button type="button" *ngIf="!editableFlag && !readOnlyMode" 
                                class="btn btn-warning btn-sm page-header-button" (click)="submitDetails()" [disabled]="!authorizedName ||!gstAttachment "
                                style="color:#fff;height:28px;width:100px;background-color: orange;">Submit
                                Details</button>
                            
                                
                        </div>


                    </div>

                </div>

            </div>
        </form>

    </div>

</div>
<ng-template #download let-modal let-c="close">
    <div class="modal-header">
        <h5 class="modal-title">Trader Details PDF</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <app-trader-details-pdf  (pdfClicked)="onPdfClicked($event)"></app-trader-details-pdf>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
    </div> -->

</ng-template>