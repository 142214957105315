import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-warehouse-shipping-address',
  templateUrl: './warehouse-shipping-address.component.html',
  styleUrls: ['./warehouse-shipping-address.component.scss']
})
export class WarehouseShippingAddressComponent implements OnInit  {
  warehouses: any = [];
  model: {};
  warehouseLength: any;
  traderJson: any;
  readOnlyMode: boolean;
  editableFlag: boolean;
  country: any;
  states: any;
  city: any[];
  block: Array<any> = [];
  district: Array<any> = [];
  cityArr: any = [];
  warehouse: any;
  isDataLoaded;
  appLanguage: string;
  refDataCodes: any;
  traderRefDataCodes: any;
  loader: boolean;
  labelValueLanguage: string;
  serviciablePincodes = [];
  servingArea: string;
  servingAreaJson: any;
  mandatoryFields: any[];
  appLabel:any;

  constructor(private service: SharedService,private componentLoaderService:ComponentLoaderService,
    private toastr : ToastrCustomService) { }
  @ViewChild('warehouseInfo') wareHouseForm;

  ngOnInit(): void {
    // this.servingArea = 'REGION_COUNTRY.INDIA'
    this.appLanguage = sessionStorage.getItem("appLanguage");
    if (this.appLanguage) {
      this.labelValueLanguage = this.appLanguage.split('-')[0];
    }
    this.getDataCodes();
    this.getPanIndiaServiceablePincodes();
    this.setJson();
    this.getAppLabels();

    // this.getCountryGREF();
    // this.getStatesGREF();
    // this.traderCenters();

    if (sessionStorage.getItem('isLoggedIn') == 'true') {
      //console.log('Logged In True')
      this.readOnlyMode = true;
    }
  };
  getAppLabels() {
    this.service
    .getJSONData("app-labels/" + this.appLanguage + "/traderProfile")
      .subscribe((res) => (this.appLabel = res["wareHouseInfo"]));
  }
  
  getPanIndiaServiceablePincodes() {
    this.service.getGREFDataByClassCode('GREF.PIN_CODE', sessionStorage['auth_token']).subscribe((response) => {
      this.serviciablePincodes = response;
    })
  };

  setJson(){
    this.servingAreaJson = [
      {
        "code": "REGION_COUNTRY.INDIA",
        "globalRefClass": {
          "code": "GREF.REGION_COUNTRY",
          "name": "Country"
        },
        "name": "India",
        "description": "India",
        "labels": [
        ]
      }
    ]
  }
  filterFunction() { }

  getLanguageWiseLabelValues(arr: Array<any>) {
    arr.forEach((o, i) => {
      let _index = o.labels.findIndex((obj, ind) => {
        return obj.language.code == this.labelValueLanguage;
      })
      if (_index != 0) {
        let _obj = o.labels.splice(_index, 1);
        let tempArray: any[] = [];
        tempArray.push(_obj[0]);
        tempArray.push(o.labels);
        o.labels = tempArray;
      }
    })
    return arr;
  };

  getDataCodes() {
    this.service.getJson('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.service.getJson('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getCountryGREF();
        this.getStatesGREF();
        this.traderCenters();
      });
    }
    );
  }

  traderCenters() {
    this.service.getTraderCenterByTraderId(sessionStorage['trader_id'], sessionStorage['auth_token']).subscribe(res => {
     
      this.warehouse = res;

      if (this.warehouse.length == 0) {
        this.addWarehouse();
      }
      else {
        let worknodeIds = this.warehouse.map(x => x.workNode.id).toString();
        //console.log('Worknode Id', worknodeIds);
        this.service.getWorknodeById(worknodeIds, sessionStorage['auth_token']).subscribe(resWN => {
          //console.log('Worknodes Response', resWN);

          let worknodes: any = [];
          worknodes = resWN;

          if (worknodes && worknodes.length == 0) {
            this.addWarehouse();
          }
          else {
            let i = 0;
            this.warehouse.map(x => worknodes.filter(y => {
              if (x.workNode.id == y.id) {
                //Sorting worknodeContact
                y.workNodeContact = y.workNodeContact.sort(function (a, b) {
                  var x = a.contactType.code.toLowerCase();
                  var y = b.contactType.code.toLowerCase();
                  if (x < y) { return -1; }
                  if (x > y) { return 1; }
                  return 0;
                });
                // this.getCityGREF(y.workNodeAddress.city.code);

                if (y.workNodeAddress.district && y.workNodeAddress.district.code) {
                  this.getDistrictGREF(y.workNodeAddress.district.code);
                }
                else {
                  y.workNodeAddress['district'] = { code: '' };
                  this.getDistrict(y.workNodeAddress.state.code, 1, i);
                }
                if (y.workNodeAddress.block && y.workNodeAddress.block.code)
                  this.getBlockGREF(y.workNodeAddress.block.code);
                else {
                  y.workNodeAddress['block'] = { code: '' };
                  this.getBlock(y.workNodeAddress.district.code, 1, i);
                }

                x.workNode = y;
                //if (i == 0) {
                //  this.warehouse = x;
                //}
                //else {
                this.warehouses.push(x);
                //}
                i++;
                this.warehouseLength = this.warehouses.length;
              }
            }))
          }
        })
      }
      this.isDataLoaded = true;
    })
  }
  getCountryGREF() {
    this.service.getGREFDataByDataCode(this.refDataCodes.country).subscribe(res => {
      let arr: any = [];
      arr.push(res);
      if (arr) {
        this.country = this.getLanguageWiseLabelValues(arr);
      }
    });
  }
  getStatesGREF() {
    this.service.getGREFDataHierarchyByParentCode(this.refDataCodes.country, this.refDataCodes.state, sessionStorage['auth_token'], 1).subscribe(res => {
      let _tempStates = res.map(x => x.child);
      if (_tempStates) {
        this.states = this.getLanguageWiseLabelValues(_tempStates);
      }
      this.states.forEach(data => {
        data.labels = data.labels.filter(cData => cData != null || cData != undefined);
      });
    });
  }
  getCityGREF(dataCode) {
    if (dataCode != "") {
      this.service.getGREFDataByDataCode(dataCode).subscribe(res => {
        this.cityArr.push(res);
        this.city = this.cityArr;
      });
    }
  }
  getDistrictGREF(dataCode) {
    if (dataCode != "") {
      let arr = [];
      this.service.getGREFDataByDataCode(dataCode).subscribe(res => {
        arr.push(res);
        if (arr) {
          this.district.push(this.getLanguageWiseLabelValues(arr)[0]);
        }
      });
    }
  }
  getBlockGREF(dataCode) {
    if (dataCode != "") {
      let arr = [];
      this.service.getGREFDataByDataCode(dataCode).subscribe(res => {
        arr.push(res);
        if (arr) {
          this.block.push(this.getLanguageWiseLabelValues(arr)[0]);
        }
      });
    }
  }

  getDistrictDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.district, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      //res.forEach(x => this.district.push(x.child));
      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.district.push(o.child)
      })
      if (res.length > 99) {
        pageIndex += 1;
        this.getDistrictDataByPagination(event, pageIndex);
      }
    }, err => this.district = [])
  }
  getBlockDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.block, sessionStorage['auth_token'], pageIndex).subscribe(res => {
     // res.forEach(x => this.block.push(x.child));

      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.block.push(o.child);
      })
      //console.log('Block', this.block);
      if (res.length > 99) {
        pageIndex += 1;
        this.getBlockDataByPagination(event, pageIndex);
      }
    }, err => this.block = [])
  }
  getCityDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.city, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      //res.forEach(x => this.city.push(x.child));
      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.city.push(o.child)
      })
      //console.log('City', this.city);
      if (res.length > 99) {
        pageIndex += 1;
        this.getCityDataByPagination(event, pageIndex);
      }
    }, err => this.city = [])
  }

  getCity(event, startPageIndex, index) {
    this.city = [];
    this.warehouses[index].workNode.workNodeAddress.city.code = "";
    this.getCityDataByPagination(event, startPageIndex);
  }
  getDistrict(event, startPageIndex, index) {
    this.district = [];
    // if (this.warehouses && this.warehouses.length > 0) {
    // this.warehouses[index].workNode.workNodeAddress.district.code = "";
    let pageIndex = startPageIndex;
    //console.log('Clicked --1');
    this.getDistrictDataByPagination(event, startPageIndex);
    // }
  }
  getBlock(event, startPageIndex, index) {
    this.block = [];
    // this.warehouses[index].workNode.workNodeAddress.block.code = "";
    let pageIndex = startPageIndex;
    //console.log('Clicked --1');
    this.getBlockDataByPagination(event, startPageIndex);
  }

  // getCity(event) {
  //   this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.city, sessionStorage['auth_token'],1).subscribe(res => {
  //     this.city = res.map(x => x.child);
  //     if (this.city.length == 0)
  //       this.city = [];
  //     //console.log(this.city);
  //   })
  // }
  addWarehouse() {
    //console.log('This Model', this.wareHouseForm);
    this.service.getJson('app-models/' + this.appLanguage + '/post-jsons/traderCenter').subscribe(res => {
      this.warehouses.push(res[0]);
      this.warehouseLength = this.warehouses.length;
      
      //console.log(this.warehouses);
    });
  }


  removeWarehouse(index) {
    this.warehouses.splice(index, 1);
    this.warehouseLength = this.warehouses.length;
  }

  navigateNext() {
    //PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
    if (this.warehouses[0].workNode.workNodeAddress.pinCode.toString().length == 5)
      this.warehouses[0].workNode.workNodeAddress.pinCode = '0' + this.warehouses[0].workNode.workNodeAddress.pinCode;
    // this.warehouses[0].workNode.workNodeAddress.city=null;
    if (this.readOnlyMode) {
      let lazyComp: any = import(
        "../../vendor-profile-forms/product-information/product-information.component"
      ).then(({ ProductInformationComponent }) => ProductInformationComponent);
      this.componentLoaderService.storeLazyComponent({
        component: lazyComp,
        componentName: "ProductInformationComponent",
      });
    }
    else {
      for (let i = 0; i < this.warehouses.length; i++) {
        this.warehouses[i].workNode.name = this.warehouses[i].centerName;
        this.warehouses[i].traderMarketAccount = sessionStorage['traderMarketAccount'];
      }
      // this.warehouses[index].workNode.name = this.warehouses[index].centerName;
      // this.warehouses[index].traderMarketAccount = sessionStorage['traderMarketAccount'];

      this.service.createTraderCenter(this.warehouses, sessionStorage['trader_id'], sessionStorage['traderMarketAccount'], sessionStorage['auth_token']).subscribe(res => {
        console.log("new trade center created");
        let lazyComp: any = import('../product-information/product-information.component').then(({ ProductInformationComponent }) => ProductInformationComponent);
        this.service.storeLazyComponent({ 'component': lazyComp, 'componentName': 'ProductInformationComponent' });
        //console.log('Details Updated Successfully')
      })
    }
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  //Make sure to make changes in Trader PDF also once this is fixed
  updateTraderDetails() {

    this.loader = true;
    ////PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
    //if (this.warehouses[0].workNode.workNodeAddress.pinCode.length == 5)
    //  this.warehouses[0].workNode.workNodeAddress.pinCode = '0' + this.warehouses[0].workNode.workNodeAddress.pinCode;

    //Worknode will not get updated from traderCenter put
    //console.log('Warehouse - 2', this.warehouses);

    for (let i = 0; i < this.warehouses.length; i++) {
      if (this.warehouses[i].workNode.workNodeAddress.pinCode.toString().length < 5 || this.warehouses[i].workNode.workNodeAddress.pinCode.toString().length > 6) {
         this.toastr.showError('Please enter a valid pinCode for warehouse no. ' + (i + 1) + '!');
      }
      //PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
      if (this.warehouses[i].workNode.workNodeAddress.pinCode.toString().length == 5)
        this.warehouses[i].workNode.workNodeAddress.pinCode = '0' + this.warehouses[i].workNode.workNodeAddress.pinCode;
      this.warehouses[i].traderMarketAccount = sessionStorage['traderMarketAccount'];
      this.warehouses[i].workNode.name = this.warehouses[i].centerName;
      
      if (this.warehouses[i].id == 0) {
        let newWarehouse: any = [];
        newWarehouse.push(this.warehouses[i]);
        this.service.createTraderCenter(newWarehouse, sessionStorage['trader_id'], sessionStorage['traderMarketAccount'], sessionStorage['auth_token']).subscribe(WNRes => {
          //console.log('Worknode Created Successfully', WNRes);

          //Added Later if someone logs out w/o creating trader center
           this.toastr.showSuccess('Warehouse Updated Successfully');
          this.service.warehouseDetails.next(this.warehouses);
          
          // this.traderCenters()
        })
      }
      else {
        
        console.log(this.warehouses[i]);
        this.service.updateTraderCenter(this.warehouses[i], sessionStorage['auth_token']).subscribe(res => {
          //console.log('Details Updated Successfully');
          this.service.updateWorknode(this.warehouses[i].workNode, sessionStorage['auth_token']).subscribe(worknodeRes => {
             this.toastr.showSuccess('Warehouse Updated Successfully');
            this.service.warehouseDetails.next(this.warehouses);
            //console.log('Worknode Updated Successfully', worknodeRes);
            //this.loader = false;
          })
        })
      }

      this.editableFlag = false;
      this.loader = false;
    }
  }

  navigatePrevious() {
    let lazyComp: any = import(
      "../../vendor-profile-forms/display-information/display-information.component"
    ).then(({ DisplayInformationComponent }) => DisplayInformationComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "DisplayInformationComponent",
    });
  }
  isFieldInvalid(): boolean {
    this.mandatoryFields = this.service.dataArray;
    const divisionCode ="Warehouse Information"
    return this.mandatoryFields.includes(divisionCode);
}
 

}

  

