import { Component, OnInit, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';

@Component({
  selector: 'app-vendor-profile-sidenav',
  templateUrl: './vendor-profile-sidenav.component.html',
  styleUrls: ['./vendor-profile-sidenav.component.scss']
})
export class VendorProfileSidenavComponent implements OnInit {
  currentActiveComponent: any = {
    "label": "Basic Information",
  }
  appLabel: any;
  // lazyComp: Promise<Type<BasicInformationComponent>>;
  lazyComp: any;
  link: any;
  selectedIndex: any;
  lazyComp$: Observable<Type<any> | null> = new BehaviorSubject<Type<any> | null>(null);
  lazyCompName: any;
  mandatoryFields: any[];

  constructor(
    private componentLoaderService: ComponentLoaderService, private ViewContainerRef: ViewContainerRef,private service: SharedService
  ) {
    this.lazyComp$ = new BehaviorSubject<Type<any> | null>(null)
  }

  ngOnInit(): void {
    this.getAppLabels();
    // this.initVaraibles();
    this.componentLoaderService.getCurrentLazyComponentName().subscribe(res => {
      this.lazyCompName = res;
      if (this.appLabel) {
        this.currentActiveComponent = this.appLabel.menu.filter((x: any) => x.template == res)[0];
        this.selectedIndex = this.appLabel.menu.filter((x: any) => x.template == res)[0].componentIndex;
      }
    });
    this.componentLoaderService.getCurrentLazyComponent().subscribe((res: any) => this.lazyComp$ = res);
  }

  getAppLabels() {
    this.componentLoaderService.getJSONForSideNav('profile-side-nav').subscribe((res: any) => {
      console.log('Side Nav JSON', res);
      this.appLabel = res;
      this.initVaraibles();
    })
  }

  initVaraibles() {
    this.lazyComp = import('./vendor-profile-forms/basic-information/basic-information.component').then(({ BasicInformationComponent }) => BasicInformationComponent
    );
    this.componentLoaderService.storeLazyComponent({ 'component': this.lazyComp, 'componentName': 'BasicInformationComponent' });
  }

  async loadComponent(item: any, index: any) {
    
   this.isFieldInvalid(item.label);   
    // this.currentActiveComponent = item
    this.selectedIndex = index;
    this.ViewContainerRef.clear();
    this.lazyComp = import('./vendor-profile-forms/' + item.templatePath + '/' + item.templatePath + '.component').then(x => (x['' + item.template + '']))
    this.componentLoaderService.storeLazyComponent({ 'component': this.lazyComp, 'componentName': item.template });
  }
  isFieldInvalid(fieldName: string): boolean {
    this.mandatoryFields = this.service.dataArray;
  return this.mandatoryFields.includes(fieldName);


}
}