import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {
  showHidePassword = false;

  userNameValidation = false;
  userPasswordValidation = false;
  @ViewChild('forgot') private forgot;

  organizationValidation = false;
  checkInitialClick = false;
  convertedData: any = []
  // model: any = { "username": "", "password": "" };
  model: any = { "mobile": "", "loginId": "", "password": "" };
  postJson = {
    "password": "",
    "userLogin": ""
  };
  isMobileNumber = false;
  invalidFlag: boolean;
  loader: boolean;
  errorFlag: boolean;
  countryLabel: any;
  languageFlag: boolean;
  appLanguage: any;
  organizationNotAvailable: any = false;


  models: any = {
    login: "",
    messageId: "",
    newPassword: "",
    otp: ""
  };
  mobile: string = "";
  loginId: any;
  confirmPassword: any;
  // loader: boolean;
  invalidPassword: any;
  // errorFlag: boolean;
  messageId: any;
  otpFlag: boolean = true;
  apiKeyJson: Object;
  invalidOTPFlag: boolean;
  //showPassword = false;
  constructor(private router: Router, private sharedService: SharedService,
    private toastr: ToastrCustomService,
    private modalService: NgbModal,
  ) {
    sessionStorage.clear();
    localStorage.clear();

  }

  ngOnInit(): void {
    // this.getAppLabels();
    this.organizationNotAvailable = false;
    this.sharedService.getJSONData('country').subscribe(res => {

      this.countryLabel = res
    });

    // this.getTraderJson();
    // this.router.navigate(['vendorDashboard/vendor-profile']);
  }

  getAppLabels() {
  }

  forgotPassword() {
    console.log("forgot")
    this.getClientApiKey();
    const modalRef = this.modalService.open(this.forgot, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    //const modalRef = this.modalService.open(UserLoginComponent, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    //const modalRef = this.modalService.open(ForgotPasswordModalComponent, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' })
  }
  login() {

    // this.postJson.userLogin = this.model.username + '@@' + this.model.username;
    this.organizationValidation = false
    if (!this.model.mobile || this.model.mobile == '') {
      this.userNameValidation = true
    }
    if (!this.model.password || this.model.password == '') {
      this.userPasswordValidation = true
    }
    if (this.model.loginId !== ''&&this.model.mobile !== ''&&this.model.password !== '') {
      this.loader = true;
      if (this.isMobileNumber) {
        let appLanguage = sessionStorage.getItem('appLanguage');
        let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '+91' : '+1';
        this.postJson.userLogin = prefix + this.model.mobile + "@@" + this.model.loginId;
      } else {
        this.postJson.userLogin = this.model.mobile + "@@" + this.model.loginId;
      }
      this.postJson.password = this.model.password;

      this.sharedService.login(this.postJson).subscribe(res => {
        sessionStorage.setItem('auth_token', res['tokenType'] + ' ' + res['accessToken']);
        let authToken = res['tokenType'] + ' ' + res['accessToken'];
        this.sharedService.getLoggedInUserDetails(authToken).subscribe((userResponse: any) => {
          sessionStorage.setItem('tenant_id', userResponse.tenantId);
          sessionStorage.setItem('user_id', userResponse.id);
          sessionStorage.setItem('user_name', userResponse.name);
          sessionStorage.setItem('tenant_name', userResponse.tenant.name);

          this.sharedService.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe((traderResponse: any) => {
            this.loader = false;
            if (traderResponse && traderResponse.length > 0) {


              sessionStorage.setItem('trader_id', traderResponse[0].id);
              sessionStorage.setItem('actor_id', traderResponse[0].actor.id);
              sessionStorage.setItem('actor_type', traderResponse[0].actor.actorType);
              sessionStorage.setItem('trader_name', traderResponse[0].traderProfile.traderName);
              sessionStorage.setItem('traderMarketAccount', traderResponse[0].traderMarketAccounts[0].id);
              //Store Contact Number
              const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (!emailReg.test(this.model.mobile))
                sessionStorage.setItem('mobile', this.model.mobile);

              sessionStorage.setItem('isLoggedIn', 'true');

              //Setting country code in localStorage
              if (traderResponse[0].traderProfile.address.country.code == this.countryLabel.india.code) {
                sessionStorage.setItem('appLanguage', 'ENG-IN')
                this.router.navigate(['vendorDashboard/vendor-profile/', userResponse.id]);
                console.log(userResponse);
              }
              else if (traderResponse[0].traderProfile.address.country.code == this.countryLabel.usa.code) {
                sessionStorage.setItem('appLanguage', 'ENG-US')
                this.router.navigate(['vendorDashboard/vendor-profile/', userResponse.id]);
                console.log(userResponse);
              }
            }
            else {
              this.router.navigate(['vendorDashboard/vendor-profile/', userResponse.id]);
            }

          }
            , (error: any) => {

            }) // sessionStorage.setItem('user_roles', userResponse['roles'].map(x => x));



        }, (error: any) => {
          this.errorFlag = true;
          this.loader = false;
        })
      }, (error: any) => {
        if (error.status == 401) {
          this.invalidFlag = true;
          this.loader = false;
        }

      })


    } else {
      this.organizationNotAvailable = true;
      this.organizationValidation = true
    }

  }

  checkIfLoginIdValid() {

    if (this.model.mobile.match('[a-zA-z]+')) {
      this.isMobileNumber = false;
      // if (this.model[0] == '+')
      //   this.model = appLanguage.includes('IN') ? this.model.substr(3) : this.model.substr(2);
    } else {
      // if (this.model[0] !== '+')

      this.isMobileNumber = true;
    }
    let isNumberValid = (this.isMobileNumber && this.model.mobile.length == 10) ? true : false;
    if (isNumberValid || this.model.mobile.match('^[a-zA-Z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$')) {
      return true;
    } else {
      return false;
    }
    // let isNumberValid = this.model.mobile[0] == '+' ? (this.model.mobile.includes('+1') ? (this.model.mobile == 12 ? true : false) : (this.model.mobile.includes('+91') ? (this.model.mobile.length == 13 ? true : false) : false)) : false;

    // if (isNumberValid || this.model.mobile.match('^[a-zA-Z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$')) {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  // getTraderJson() {
  //   this.service.getJson('app-models/trader').subscribe(res => {
  //     // Store form json in subject
  //     this.service.storeFormData(res);
  //   });
  // }
  // Adding App Language
  updateAppLanguage(event) {
    console.log(event);
    this.languageFlag = true;
    sessionStorage.setItem('appLanguage', event);
    this.appLanguage = event;



  }

  getUserTenancyByUserLogin() {

    this.checkInitialClick = false
    let userLogin = '';

    if (this.model.mobile.match('[a-zA-z]+')) {
      this.isMobileNumber = false;
      userLogin = this.model.mobile;
    } else {
      // if (this.model[0] !== '+')

      this.isMobileNumber = true;
      let appLanguage = sessionStorage.getItem('appLanguage');
      let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '%2B91' : '%2B1';
      userLogin = prefix + this.model.mobile;

    }
    this.sharedService.getTenancyByUserLogin(userLogin).subscribe(tenancyList => {
      this.convertedData = Object.entries(tenancyList).map(([key, value]) => ({ key, value }));
      console.log("convertedData::::::::::", this.convertedData);
      if (this.convertedData.length === 0) {
        this.checkInitialClick = true
      }
      if (this.convertedData.length == 1) {
        this.model.loginId = this.convertedData[0].value
      }



    }, err => {

    })
  }


  changeSelectedOption(event) {
    this.organizationValidation = false
    this.organizationNotAvailable = false;

  }
  register() {
    this.router.navigate(['user-registration']);
  }

  getClientApiKey() {


    sessionStorage.setItem('auth_token', environment.token);
  }
  sendOtp() {
    console.log('Clicked Send Otp');
    let isMobileNumber = false;
    let tenantLogin;

    if (this.mobile.match('[a-zA-z]+')) {
      isMobileNumber = false;
      tenantLogin = this.mobile + '@@' + this.loginId;
    }
    else {
      isMobileNumber = true;
      let appLanguage = sessionStorage.getItem('appLanguage');
      let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '+91' : '+1';
      tenantLogin = prefix + this.mobile + '@@' + this.loginId;
    }
    this.sharedService.forgotPasswordOTP(tenantLogin, sessionStorage['auth_token']).subscribe(res => {
      this.otpFlag = false;
      this.messageId = res['messageId'];
    }, err => {
      if (err.status == 404)
        this.toastr.showError('User not found!');
      else this.toastr.showError('Something went wrong!');
    })
  }
  updatePassword() {
    this.invalidOTPFlag = false;
    let isMobileNumber = false;

    if (this.mobile.match('[a-zA-z]+')) {
      isMobileNumber = false;
      this.models.login = this.mobile + '@@' + this.loginId;
    }
    else {
      isMobileNumber = true;
      let appLanguage = sessionStorage.getItem('appLanguage');
      let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '+91' : '+1';
      this.models.login = prefix + this.mobile + '@@' + this.loginId;
    }

    //this.model.login = this.mobile + '@@' + this.loginId;
    this.models.messageId = this.messageId;
    this.sharedService.forgotPasswordUpdate(this.models, sessionStorage['auth_token']).subscribe(res => {
      sessionStorage.clear();
      //this.activeModal.close("dismissed");
      this.modalService.dismissAll();
      this.toastr.showSuccess('Password Updated Successfully');
    }, err => {
      if (err.error.errorMessage == "Invalid OTP, Authentication Failed")
        this.invalidOTPFlag = true;
    })
  }
  close() {
    sessionStorage.clear();
    this.modalService.dismissAll();
  }
}
