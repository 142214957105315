<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top"
    style="box-shadow: 0 6px 7px -1px rgba(0, 0, 0, 0.1);    padding: 20px 10px 0px 10px;z-index: 30;">
    <a class="navbar-brand">
        <img src="./assets/images/industree-logo.png" style="height:40px">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">

            <li class="nav-item  nav-width">
                <a class="nav-link" routerLinkActive="router-link-active"
                    [ngClass]="currentActiveMenuVal==='PROFILEPAGE' ? 'nav-link menu-active' : 'nav-link'"
                    (click)="navigateMenues('PROFILE')">Profile</a>
            </li>
            <li class="nav-item  nav-width">
                <a class="nav-link " routerLinkActive="router-link-active"
                    [ngClass]="currentActiveMenuVal==='CHANNELPAGE' ? 'nav-link menu-active' : 'nav-link'"
                    (click)="navigateMenues('CHANNELS')">My Channels</a>
            </li>
            <li class="nav-item  nav-width">
                <a class="nav-link" routerLinkActive="router-link-active"
                    [ngClass]="currentActiveMenuVal==='PRODUCTPAGE' ? 'nav-link menu-active' : 'nav-link'"
                    (click)="navigateMenues('PRODUCTS')">Product</a>
            </li>
            <li class="nav-item  nav-width">
                <a class="nav-link" routerLinkActive="router-link-active"
                    [ngClass]="currentActiveMenuVal==='INVENTORYPAGE' ? 'nav-link menu-active' : 'nav-link'"
                    (click)="navigateMenues('INVENTORY')">Inventory</a>
            </li>
            <li class="nav-item  nav-width">
                <a class="nav-link" routerLinkActive="router-link-active"
                    [ngClass]="currentActiveMenuVal==='SELLERPAGE' ? 'nav-link menu-active' : 'nav-link'"
                    (click)="navigateMenues('SELLER-DASHBOARD')">Seller Dashboard</a>
            </li>
        </ul>
        <!-- <a class="navbar-brand">
            <img src="./assets/images/industree-logo.png" style="height:40px">     
            </a>
        <button style="float: right;">Test</button> -->

    </div>
    <!-- <button class="btn btn-primary ml-auto profile-btn"> <img
            src="./assets/images/Sonata_Softwarew-Logo.png" alt="">
    </button> -->
    <div class="col-auto" style="width: 15%">
        <div class="form-group">
            <div style="border-bottom: 5px !important;" > <label class="text-label-user" style="word-wrap: normal;"> {{loggedinUserName|titlecase}}</label></div>
            <select class="form-select form-select-sm selected-hide" style="    border-radius: 0px; margin-left: 10px;
            border-bottom: 4px solid orange;" [(ngModel)]="menuOperation" (change)="navigateMenues(menuOperation)">
                <ng-container *ngFor="let drpMenu of userDropDown">
                    <option [value]="drpMenu.dropdownValue" class="option-list">{{drpMenu.dropdownName}}</option>
                </ng-container>
            </select>
        </div>
    </div>


</nav>