import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';
@Component({
  selector: 'app-registered-address',
  templateUrl: './registered-address.component.html',
  styleUrls: ['./registered-address.component.scss']
})
export class RegisteredAddressComponent implements OnInit {

  traderJson: any;
  readOnlyMode: boolean;
  country: any;
  states: any;
  city: any;
  district: Array<any> = [];
  block: Array<any> = [];
  editableFlag: boolean;
  appLanguage: any;
  appLabel: any
  refDataCodes: any;
  traderRefDataCodes: any;
  loader: boolean;
  isDataLoaded;
  labelValueLanguage: string;
  isLicitPincode: boolean = true;

  fd: FormData;
  tanNumberAttachment: any;
  imageLoaderTAN: boolean;
  imageLoaderAuthorizedName: boolean;
  imgFlagTAN: boolean;
  imgFlagAuthorizedName: boolean;
  mandatoryFields: any[];

  authorizedName: any;
  constructor(private service: SharedService, private componentLoaderService:ComponentLoaderService,
    private toastr :ToastrCustomService) { }
  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    if (this.appLanguage) {
      this.labelValueLanguage = this.appLanguage.split('-')[0];
    }
    this.getAppLabels();
    this.getDataCodes();
    // this.getCountryGREF();
    // this.getStatesGREF();
    // this.getTraderJson();
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;
  }
  getAttachments() {
    this.imageLoaderTAN = true;
    this.imageLoaderAuthorizedName = true;

    // console.log("this.traderRefDataCodes",this.traderRefDataCodes);
    
  
    // console.log(this.traderRefDataCodes.attachment.gstTan, sessionStorage['trader_id'], sessionStorage['auth_token']);
    
    this.service.getAttachment(this.traderRefDataCodes.attachment.gstTan, sessionStorage['trader_id']).subscribe(resTAN => {
      // console.log("resTAN",resTAN);
      
      if (resTAN) {
        this.imgFlagTAN = true;
        // console.log('Attachment Response', resTAN);
        this.tanNumberAttachment = "data:image/png;base64," + (resTAN.attachment);
        this.imageLoaderTAN = false;
        // console.log(this.tanNumberAttachment);
      }
      // this.tanNumberAttachment = reader.readAsDataURL(resTAN.attachment);
      // this.tanNumberAttachment = resTAN.attachment;
    }, err => {
      this.imageLoaderTAN = false;
    })
    // this.service.getAttachment('TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER', sessionStorage['trader_id'], sessionStorage['auth_token']).subscribe(resNodal => {
    //   if (resNodal) {
    //     this.imgFlag = true;
    //     this.nodalLetterAttachment = "data:image/png;base64," + resNodal.attachment;
    //   }
    // })
    this.service.getAttachment(this.traderRefDataCodes.attachment.authorizedSignatory, sessionStorage['trader_id'], ).subscribe(resSignatory => {
      if (resSignatory) {
        this.imgFlagAuthorizedName = true;
        this.authorizedName = "data:image/png;base64," + resSignatory.attachment;
        this.imageLoaderAuthorizedName = false;
      }
    }, err => {
      this.imageLoaderAuthorizedName = false;
    })
  }


  addAttachment(event, attachmentName) {
    // console.log((event.target.files[0]));
    const attachedFile = event.target.files[0];

    if (attachedFile.type != 'application/pdf') {
      // this.imgFlag = true;
      this.fd = new FormData();
      this.fd.append('file', attachedFile);
      const reader = new FileReader();
      reader.onload = ((e) => {
        if (attachmentName == 'tanNumber') {
          this.imgFlagTAN = true;
          this.tanNumberAttachment = e.target['result'];
          //Checking file size
          if (attachedFile.size > (6 * 1048576)) {
            this.tanNumberAttachment = '';
            this.toastr.showError('Image size should be less than 6Mb');
          }
          
        }
        // else if (attachmentName == 'nodalLetter') {
        //   this.nodalLetterAttachment = e.target['result'];
        //   this.service.addAttachment(sessionStorage['trader_id'], this.fd, 'TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER', sessionStorage['auth_token']).subscribe(attachmentResponse => {
        //     // console.log('Attachment Added Successfully', attachmentResponse);
        //   this.service.traderAttachment.next(['TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER',this.nodalLetterAttachment]);
        //   })
        // }
        else if (attachmentName == 'authorizedSignature') {
          this.imgFlagAuthorizedName = true;
          this.authorizedName = e.target['result'];
          //Checking file size
          if (attachedFile.size > (6 * 1048576)) {
            this.authorizedName = '';
            this.toastr.showError('Image size should be less than 6Mb');
          }
          else {
            this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.authorizedSignatory, ).subscribe(attachmentResponse => {
              // console.log('Attachment Added Successfully', attachmentResponse);
              this.service.traderAttachment.next([this.traderRefDataCodes.attachment.authorizedSignatory, this.authorizedName]);
            })
          }
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
    else {
      this.fd = new FormData();
      this.fd.append('file', attachedFile);
      if (attachmentName == 'tanNumber')
        this.tanNumberAttachment = attachedFile.name;
      // else if (attachmentName == 'nodalLetter')
      //   this.nodalLetterAttachment = attachedFile.name;
      else if (attachmentName == 'authorizedSignature')
        this.authorizedName = attachedFile.name;
      // this.imgFlag = false;
      this.imgFlagTAN = false;
      this.imgFlagAuthorizedName = false;
    }
  }







  getLanguageWiseLabelValues(arr: Array<any>) {
    arr.forEach((o, i) => {
      let _index = o.labels.findIndex((obj, ind) => {
        return obj.language.code == this.labelValueLanguage;
      })

      if (_index != 0) {
        let _obj = o.labels.splice(_index, 1);
        let tempArray: any[] = [];
        tempArray.push(_obj[0]);
        tempArray.push(o.labels);
        o.labels = tempArray;
      }
    })

    return arr;
  };

  getAppLabels() {
    this.service
    .getJSONData("app-labels/" + this.appLanguage + "/traderProfile")
      .subscribe((res) => (this.appLabel = res["registeredAddress"]));
  }
  getDataCodes() {
    this.service.getJson('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.service.getJson('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getCountryGREF();
        this.getStatesGREF();
        
    this.getAttachments();
      });
    }
    );
  }
  getCountryGREF() {
    this.service.getGREFDataByDataCode(this.refDataCodes.country).subscribe(res => {
      let arr: any = [];
      arr.push(res);
      if (arr) {
        this.country = this.getLanguageWiseLabelValues(arr);
      }
    });
  }
  getStatesGREF() {
    this.service.getGREFDataHierarchyByParentCode(this.refDataCodes.country, this.refDataCodes.state, sessionStorage['auth_token'], 1).subscribe(res => {
      let _tempStates = res.map(x => x.child);
      if (_tempStates) {
        this.states = this.getLanguageWiseLabelValues(_tempStates);
      }
      console.log(this.states);
      this.states.forEach(data => {
        data.labels = data.labels.filter(cData => cData != null || cData != undefined);
      });
      this.getTraderJson();
    });
  }
  getTraderJson() {
    this.service.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(res => {
      this.traderJson = res[0];

       console.log("traderJson",this.traderJson);
      

      if (this.traderJson.traderProfile.address.pinCode == "" || this.traderJson.traderProfile.address.pinCode == "000000") {
        this.traderJson.traderProfile.address.pinCode = null;
        this.isLicitPincode = false;
        // this.editableFlag = true;
      }
      if (this.traderJson.traderProfile.address.hasOwnProperty('district') && this.traderJson.traderProfile.address.district.code) {
        this.getDistrictGREF(this.traderJson.traderProfile.address.district.code);
      }
      else {
        this.traderJson.traderProfile.address['district'] = { code: '' };
        this.getDistrict(this.traderJson.traderProfile.address.state.code, 1);
      }
      if (this.traderJson.traderProfile.address.hasOwnProperty('block') && this.traderJson.traderProfile.address.block.code)
        this.getBlockGREF(this.traderJson.traderProfile.address.block.code);
      else {
        this.traderJson.traderProfile.address['block'] = { code: '' };
        this.getBlock(this.traderJson.traderProfile.address.district.code, 1);
      }
      this.isDataLoaded = true;
    })
  }
  getCityGREF(dataCode) {
    if (dataCode != "") {
      let arr = [];
      this.service.getGREFDataByDataCode(dataCode,).subscribe(res => {
        arr.push(res);
        this.city = arr;
      });
    }
  }
  getDistrictGREF(dataCode) {
    if (dataCode != "") {
      let arr = [];
      this.service.getGREFDataByDataCode(dataCode,).subscribe(res => {
        //arr.push(res);

        //if (arr) {
        //  arr.forEach((o, i) => {
        let _index = res.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = res.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(res.labels);
          res.labels = tempArray;
        }
        this.district.push(res);

        this.district.forEach(data => {
          data.labels = data.labels.filter(cData => cData != null || cData != undefined);
        });
        console.log(this.district);
        //  })
        //}

      });
    }
  }
  getBlockGREF(dataCode) {
    if (dataCode != "") {
      let arr = [];
      this.service.getGREFDataByDataCode(dataCode,).subscribe(res => {
        arr.push(res);
        if (arr) {
          arr.forEach((o, i) => {
            let _index = o.labels.findIndex((obj, ind) => {
              return obj.language.code == this.labelValueLanguage;
            })

            if (_index != 0) {
              let _obj = o.labels.splice(_index, 1);
              let tempArray: any[] = [];
              tempArray.push(_obj[0]);
              tempArray.push(o.labels);
              o.labels = tempArray;
            }
            this.block.push(o);
          })
        }
      });
    }
  }

  getDistrictDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.district, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      //res.forEach(x => this.district.push(x.child));
      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.district.push(o.child)
      })
      // console.log('District', this.district);
      if (res.length > 99) {
        pageIndex += 1;
        this.getDistrictDataByPagination(event, pageIndex);
      }
    }, err => this.district = [])
  }
  getBlockDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.block, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      // res.forEach(x => this.block.push(x.child));
      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.block.push(o.child)
      })
      // console.log('Block', this.block);
      if (res.length > 99) {
        pageIndex += 1;
        this.getBlockDataByPagination(event, pageIndex);
      }
    }, err => this.block = [])
  }

  getCityDataByPagination(event, startPageIndex) {
    let pageIndex = startPageIndex;
    this.service.getGREFDataHierarchyByParentCode(event, this.refDataCodes.city, sessionStorage['auth_token'], pageIndex).subscribe(res => {
      // res.forEach(x => this.city.push(x.child));
      res.forEach((o, i) => {
        let _index = o.child.labels.findIndex((obj, ind) => {
          return obj.language.code == this.labelValueLanguage;
        })

        if (_index != 0) {
          let _obj = o.child.labels.splice(_index, 1);
          let tempArray: any[] = [];
          tempArray.push(_obj[0]);
          tempArray.push(o.labels);
          o.child.labels = tempArray;
        }
        this.city.push(o.child)
      })
      // console.log('City', this.city);
      if (res.length > 99) {
        pageIndex += 1;
        this.getCityDataByPagination(event, pageIndex);
      }
    }, err => this.city = [])
  }

  getCity(event, startPageIndex) {
    this.city = [];
    this.traderJson.traderProfile.address.city.code = "";
    let pageIndex = startPageIndex;
    // console.log('Clicked');
    this.getCityDataByPagination(event, startPageIndex);
  }
  getDistrict(event, startPageIndex) {
    this.district = [];
    this.traderJson.traderProfile.address.district.code = "";
    let pageIndex = startPageIndex;
    // console.log('Clicked --1');
    this.getDistrictDataByPagination(event, startPageIndex);
  }
  getBlock(event, startPageIndex) {
    this.block = [];
    this.traderJson.traderProfile.address.block.code = "";
    let pageIndex = startPageIndex;
    // console.log('Clicked --1');
    this.getBlockDataByPagination(event, startPageIndex);
  }
  navigateNext() {
    //PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
    if (this.traderJson.traderProfile.address.pinCode?.toString().length == 5)
      this.traderJson.traderProfile.address.pinCode = '0' + this.traderJson.traderProfile.address.pinCode;

    if (this.readOnlyMode) {
      let lazyComp: any = import('../../vendor-profile-forms/communication-address/communication-address.component').
      then(({ CommunicationAddressComponent }) => CommunicationAddressComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'CommunicationAddressComponent' });
    }
    else {
      this.service.patchTraderProfile(this.traderJson,).subscribe(res => {
        let lazyComp: any = import('../../vendor-profile-forms/communication-address/communication-address.component').then(({ CommunicationAddressComponent }) => CommunicationAddressComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'CommunicationAddressComponent' });
        // console.log('Details Updated Successfully')
      })
    }
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;
    let isFormValid = true;
    

    if (!this.isLicitPincode && this.traderJson.traderProfile.address.pinCode == null) {
      this.traderJson.traderProfile.address.pinCode = "000000";
    }
    if (this.traderJson.traderProfile.address.pinCode.toString().length < 5 || this.traderJson.traderProfile.address.pinCode.toString().length > 6) {
      this.toastr.showError('Please enter a valid pinCode!');
      isFormValid = false;
      this.loader = false;
    }
    //PINCODE APPENDING SPACE IF PINCODE IS OF LENGTH 5
    if (this.traderJson.traderProfile.address.pinCode.toString().length == 5) {
      this.traderJson.traderProfile.address.pinCode = '0' + this.traderJson.traderProfile.address.pinCode + ' ';
    }

    if (isFormValid) {
      this.traderJson.traderProfile.address.city = null;
      this.service.patchTraderProfile(this.traderJson).subscribe({
        next: (res) => {
          this.editableFlag = false;
          this.toastr.showSuccess('Details Updated Successfully');
          this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.gstTan).subscribe(attachmentResponse => {
            // console.log('Attachment Added Successfully', attachmentResponse);
            this.service.traderAttachment.next([this.traderRefDataCodes.attachment.gstTan, this.tanNumberAttachment]);
          })
          this.service.traderDetails.next(res);
          this.loader = false;
        },
        error: () => {
          isFormValid = false;
          this.loader = false;
        },
        complete: () => { }
      })
    }
  }
  navigatePrevious(){
    let lazyComp: any = import(
      "../../vendor-profile-forms/basic-comapny-information/basic-comapny-information.component"
    ).then(({ BasicComapnyInformationComponent }) => BasicComapnyInformationComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "BasicComapnyInformationComponent",
    });
  }
  isFieldInvalid(fieldName?: string): boolean {
    this.mandatoryFields = this.service.dataArray;
    const field = "address"
   
  return this.mandatoryFields.includes(fieldName) || this.mandatoryFields.includes(field);
    
}

}
