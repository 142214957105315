import { Component, OnInit } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-display-information',
  templateUrl: './display-information.component.html',
  styleUrls: ['./display-information.component.scss']
})
export class DisplayInformationComponent implements OnInit {
  traderJson: any;
  readOnlyMode: boolean;
  editableFlag: boolean;
  currentIndex: number;
  contactsMailIndex: any;
  contactsMobileIndex: any;
  appLanguage: string;
  refDataCodes: any;
  traderRefDataCodes: any;
  loader: boolean;

  mandatoryFields: any[];

  constructor(private sharedService: SharedService, private componentLoaderService: ComponentLoaderService,
    private toastr: ToastrCustomService) { }

  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getDataCodes();
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;
  }

  getDataCodes() {
    this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getTraderJson();
      });
    }
    );
  }
  getTraderJson() {
    this.sharedService.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(traderResponse => {
      let arr = [];
      arr = traderResponse[0]['traderProfile']['traderContactPersons'].filter((x, index) => {
        if (x.division.code == this.traderRefDataCodes.division.displayInformation) {
          this.currentIndex = index;
          x.personContacts.filter((y, index) => {
            if (y.contactType.code == "CONTACT_TYPE.MAIL")
              this.contactsMailIndex = index;
            if (y.contactType.code == "CONTACT_TYPE.MOBILE")
              this.contactsMobileIndex = index;
          })
          return x;
        }
      });
      if (traderResponse[0]['traderProfile']['traderContactPersons'].length == 0 || arr.length == 0) {
        console.log('Trader-Details Undefined');
        this.sharedService.getJSONData('app-models/' + this.appLanguage + '/post-jsons/trader').subscribe(resJson => {
          traderResponse[0]['traderProfile']['traderContactPersons'].push(resJson['traderProfile']['traderContactPersons'][6]);
          this.traderJson = traderResponse[0];
          this.currentIndex = this.traderJson.traderProfile.traderContactPersons.length - 1;
          this.contactsMobileIndex = 0;
          this.contactsMailIndex = 1;
          console.log('Trader-Json-Updated', this.traderJson);
        })
      }
      else this.traderJson = traderResponse[0];
    })
  }
  navigateNext() {
    if (this.readOnlyMode) {
      let lazyComp: any = import('../../vendor-profile-forms/warehouse-shipping-address/warehouse-shipping-address.component').then(({ WarehouseShippingAddressComponent }) => WarehouseShippingAddressComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'WarehouseShippingAddressComponent' });
    }
    else {
      this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
        let lazyComp: any = import('../../vendor-profile-forms/warehouse-shipping-address/warehouse-shipping-address.component').then(({ WarehouseShippingAddressComponent }) => WarehouseShippingAddressComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'WarehouseShippingAddressComponent' });
        console.log('Details Updated Successfully')
      })
    }
  }

  navigatePrevious() {
    let lazyComp: any = import(
      "../../vendor-profile-forms/brand-details/brand-details.component"
    ).then(({ BrandDetailsComponent }) => BrandDetailsComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "BrandDetailsComponent",
    });
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;
    this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
      this.editableFlag = false;
       this.toastr.showSuccess('Details Updated Successfully');
      console.log('Details Updated Successfully');
      this.sharedService.traderDetails.next(res);
      this.loader = false;
    })
  }
  isFieldInvalid(): boolean {
    this.mandatoryFields = this.sharedService.dataArray;
    const divisionCode = "Display Information"
    return this.mandatoryFields.includes(divisionCode);
}

}
