import { Component, OnInit } from '@angular/core';
import { SharedService } from  'src/app/application-services/core-services/shared.service';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {
  attachedDoc: any;
  fd: FormData;
  imgFlag: boolean;
  traderJson: any;
  readOnlyMode: boolean;
  editableFlag: boolean;
  appLanguage: string;
  appLabel: any;
  refDataCodes: any;
  traderRefDataCodes: any;
  imageLoader: boolean;
  accountType: any;
  imageChangeFlag: boolean;
  loader: boolean;
  mandatoryFields: any[];

  constructor(private service: SharedService,private componentLoaderService:ComponentLoaderService, private toastr: ToastrCustomService) { }

  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getAppLabels();
    this.getDataCodes();

    // this.getTraderJson();
    // this.getAttachments();
    this.attachedDoc = '';
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;
  }
  getAppLabels() {
    this.service
      .getJson("app-labels/" + this.appLanguage + "/traderProfile")
      .subscribe((res) => (this.appLabel = res["bankDetails"]));
  }
  getDataCodes() {
    this.service.getJson('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.service.getJson('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getBankAccountTypes();
        this.getTraderJson();
        this.getAttachments();
      });
    }
    );
  }
  getBankAccountTypes() {
    this.service.getTraderGREFDataByClassCode(this.traderRefDataCodes.bankAccountType.classCode,).subscribe(res => {
      console.log('Bank Account Types->', res);
      this.accountType = res;
    })
  }
  getAttachments() {
    this.imageLoader = true;
    this.service.getAttachment(this.traderRefDataCodes.attachment.bankIdentityProof, sessionStorage['trader_id'], ).subscribe(resBank => {
      if (resBank) {
        this.imgFlag = true;
        console.log('Attachment Response', resBank);
        this.attachedDoc = "data:image/png;base64," + (resBank.attachment);
        console.log(this.attachedDoc);
        this.imageLoader = false;
      }
      // this.tanNumberAttachment = reader.readAsDataURL(resTAN.attachment);
      // this.tanNumberAttachment = resTAN.attachment;
    },
      err => {
        this.imageLoader = false;
      })
  }
  getTraderJson() {
    this.service.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(traderResponse => {
      console.log('Trader Details', traderResponse[0]);
      console.log('Trader Details', traderResponse[0]['traderProfile']['traderBankAccounts']);
      if (traderResponse[0]['traderProfile']['traderBankAccounts'].length == 0) {
        console.log('Trader-Details Undefined');
        // this.service.getJson('app-models/trader').subscribe(resJson => {
        this.service.getJson('app-models/' + this.appLanguage + '/post-jsons/trader').subscribe(resJson => {
          traderResponse[0]['traderProfile']['traderBankAccounts'] = resJson['traderProfile']['traderBankAccounts'];
          this.traderJson = traderResponse[0];
          console.log('Trader-Json-Updated', this.traderJson);
        })
      }
      else this.traderJson = traderResponse[0];
    })
  }

  addAttachment(event) {
    console.log((event.target.files[0]));
    const attachedFile = event.target.files[0];

    if (attachedFile.type != 'application/pdf') {
      this.imgFlag = true;
      this.imageChangeFlag = true;
      this.fd = new FormData();
      this.fd.append('file', attachedFile);
      const reader = new FileReader();
      reader.onload = ((e) => {
        this.attachedDoc = e.target['result'];
        //Checking file size
        if (attachedFile.size > (6 * 1048576)) {
          this.attachedDoc = '';
          console.log('File size is greater than 6Mb', this.attachedDoc);
          this.toastr.showError('Image size should be less than 6Mb');
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
    else {
      this.fd = new FormData();
      this.fd.append('file', attachedFile);
      this.attachedDoc = attachedFile.name;
      this.imgFlag = false;
    }
  }
  navigateNext() {
    if (this.readOnlyMode) {
      let lazyComp: any = import('../../vendor-profile-forms/brand-details/brand-details.component').then(({ BrandDetailsComponent }) => BrandDetailsComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'BrandDetailsComponent' });
    }
    else {
      this.service.patchTraderProfile(this.traderJson,).subscribe(res => {
        if (this.imgFlag && this.attachedDoc != '') {
          this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.bankIdentityProof, ).subscribe(attachmentResponse => {
            console.log('Attachment Added Successfully', attachmentResponse);
          })
        }
        let lazyComp: any = import('../../vendor-profile-forms/brand-details/brand-details.component').then(({ BrandDetailsComponent }) => BrandDetailsComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'BrandDetailsComponent' });
        console.log('Details Updated Successfully')
      })
    }
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;
    this.service.patchTraderProfile(this.traderJson, ).subscribe(res => {
      this.editableFlag = false;
      console.log('Details Updated Successfully');
      this.service.traderDetails.next(res);
      if (this.imageChangeFlag && this.attachedDoc != '') {
        this.service.addAttachment(sessionStorage['trader_id'], this.fd, this.traderRefDataCodes.attachment.bankIdentityProof, ).subscribe(attachmentResponse => {
          console.log('Inside imageChange true');
          console.log('Attachment Added Successfully', attachmentResponse);
          this.service.traderAttachment.next([this.traderRefDataCodes.attachment.bankIdentityProof, this.attachedDoc]);
          this.imageChangeFlag = false;
        }, err => {
          this.toastr.showError('Attachment update failed');
        })
      }
      console.log('Inside imageChange false');
    this.toastr.showSuccess('Details Updated Successfully');
      this.loader = false;
    })
  }
  navigatePrevious(){
    let lazyComp: any = import(
      "../../vendor-profile-forms/finance-division-contact-details/finance-division-contact-details.component"
    ).then(({ FinanceDivisionContactDetailsComponent }) => FinanceDivisionContactDetailsComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "FinanceDivisionContactDetailsComponent",
    });
  }
  isFieldInvalid(): boolean {
    const fieldName="traderBankAccounts"
    this.mandatoryFields = this.service.dataArray;
  return this.mandatoryFields.includes(fieldName);



    
}
}
