<div class="modal-header">
    <h5 class="d-inline">Change Password</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('Dismissed')"></button>


  </div>
  


<div class="modal-body">
    <form #resetPassword="ngForm" (ngSubmit)="resetPassword.form.valid && updatePassword()">
        <div class="p-2">
            <div class="form-floating">
                <div class="input-group input-group-sm ">
                    <input type="{{ showHidePassword ? 'text' : 'password' }}"  class="form-control" id="oldPassword" name="oldPassword"
                    [(ngModel)]="model.oldPassword" placeholder="Enter Old Password" required>
                    <span class="input-group-text" id="3"
                    (click)="showHidePassword=!showHidePassword"
                    style="border-radius: 0px 5px 5px 0;border-left :  0;height: 40px;">
                    <i class="fa fa-eye" style="font-size:18px;color: #1A237F;"
                        *ngIf="showHidePassword"></i>
                    <i class="fa fa-eye-slash" style="font-size:18px;color: #1A237F;"
                        *ngIf="!showHidePassword"></i>
                </span>
                </div>
                <!-- <label for="oldPassword">Enter Old Password</label> -->
            </div>
        </div>
        <div class="p-2">
            <div class="form-floating">
                <div class="input-group input-group-sm ">
                    <input type="{{ showHideNewPassword ? 'text' : 'password' }}" class="form-control" id="newPassword" name="newPassword"
                    [(ngModel)]="model.newPassword" placeholder="Enter New Password" required>
                    <span class="input-group-text" id="1"
                    (click)="showHideNewPassword=!showHideNewPassword"
                    style="border-radius: 0px 5px 5px 0;border-left :  0;height: 40px;">
                    <i class="fa fa-eye" style="font-size:18px;color: #1A237F;"
                        *ngIf="showHideNewPassword"></i>
                    <i class="fa fa-eye-slash" style="font-size:18px;color: #1A237F;"
                        *ngIf="!showHideNewPassword"></i>
                </span>
                </div>
                <!-- <label for="newPassword">Enter New Password</label> -->
                <small class="form-text text-muted">Password needs a minimum of 8 characters, max 15 characters
                    with at least 1 number and 1 special character like (@$%).</small>
            </div>
        </div>
        <div class="p-2">
            <div class="form-floating">
                <div class="input-group input-group-sm ">
                <input type="{{ showHideConfirmPassword ? 'text' : 'password' }}" class="form-control" id="confirmPassword" name="confirmPassword"
                    [(ngModel)]="confirmPassword" placeholder="Confirm New Password" required>
                    <div class="input-group-append">
                    <span class="input-group-text" id="2"
                    (click)="showHideConfirmPassword=!showHideConfirmPassword"
                    style="border-radius: 0px 5px 5px 0;border-left :  0;height: 40px;">
                    <i class="fa fa-eye" style="font-size:18px;color: #1A237F;"
                        *ngIf="showHideConfirmPassword"></i>
                    <i class="fa fa-eye-slash" style="font-size:18px;color: #1A237F;"
                        *ngIf="!showHideConfirmPassword"></i>
                </span>
                    </div>
                </div>

                <!-- <label for="confirmPassword">Confirm New Password</label> -->
                <span *ngIf="confirmPassword && model.newPassword !== confirmPassword"
                    class="form-text text-danger">Password & Confirm Password do not match</span>
                <span *ngIf="confirmPassword && model.newPassword === confirmPassword"
                    class="form-text text-success">Password matches</span>









            </div>
        </div>
        <div class="p-2">
            <span *ngIf="invalidPassword" class="form-text text-danger">Invalid Password</span>
            <span *ngIf="errorFlag" class="form-text text-danger">Some Error occurred... Please try again later</span>
            <br>
            <button class="btn btn-primary" type="submit" [disabled]="resetPassword.form.invalid || loader || model.newPassword !== confirmPassword">
                <span *ngIf="loader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                UPDATE PASSWORD
            </button>
        </div>
    </form>
</div>
