import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MenuNavigationComponent } from './menu-navigation/menu-navigation.component';
import { VendorProfileSidenavComponent } from './vendor-profile-sidenav/vendor-profile-sidenav.component';
import { BasicInformationComponent } from './vendor-profile-sidenav/vendor-profile-forms/basic-information/basic-information.component';
import { AdditionalKycComponent } from './vendor-profile-sidenav/vendor-profile-forms/additional-kyc/additional-kyc.component';
import { BankDetailsComponent } from './vendor-profile-sidenav/vendor-profile-forms/bank-details/bank-details.component';
import { BrandDetailsComponent } from './vendor-profile-sidenav/vendor-profile-forms/brand-details/brand-details.component';
import { CommunicationAddressComponent } from './vendor-profile-sidenav/vendor-profile-forms/communication-address/communication-address.component';
import { DisplayInformationComponent } from './vendor-profile-sidenav/vendor-profile-forms/display-information/display-information.component';
import { FinanceDivisionContactDetailsComponent } from './vendor-profile-sidenav/vendor-profile-forms/finance-division-contact-details/finance-division-contact-details.component';
import { ProductInformationComponent } from './vendor-profile-sidenav/vendor-profile-forms/product-information/product-information.component';
import { RegisteredAddressComponent } from './vendor-profile-sidenav/vendor-profile-forms/registered-address/registered-address.component';
import { WarehouseShippingAddressComponent } from './vendor-profile-sidenav/vendor-profile-forms/warehouse-shipping-address/warehouse-shipping-address.component';
import { EmergencyContactComponent } from './vendor-profile-sidenav/vendor-profile-forms/emergency-contact/emergency-contact.component';
import { BasicComapnyInformationComponent } from './vendor-profile-sidenav/vendor-profile-forms/basic-comapny-information/basic-comapny-information.component';
import { TraderDetailsPdfComponent } from './trader-details-pdf/trader-details-pdf.component';
import { PaginationControlComponent } from './pagination-control/pagination-control.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UnAuthorizedLoginComponent } from './un-authorized-login/un-authorized-login.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';






@NgModule({
  declarations: [
    MenuNavigationComponent,
    VendorProfileSidenavComponent,
    BasicInformationComponent,
    AdditionalKycComponent,
    BankDetailsComponent,
    BrandDetailsComponent,
    CommunicationAddressComponent,
    DisplayInformationComponent,
    FinanceDivisionContactDetailsComponent,
    ProductInformationComponent,
    RegisteredAddressComponent,
    WarehouseShippingAddressComponent,
    EmergencyContactComponent,
    BasicComapnyInformationComponent,
    TraderDetailsPdfComponent,
    PaginationControlComponent,
    UnAuthorizedLoginComponent,
    ResetPasswordModalComponent,
  
   
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
  imports: [
    CommonModule,
    FormsModule,
    NgbModule
  ],
  exports:[MenuNavigationComponent,VendorProfileSidenavComponent,PaginationControlComponent,TraderDetailsPdfComponent,UnAuthorizedLoginComponent]
})
export class SharedModuleModule { }
