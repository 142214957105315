import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss']
})
export class BasicInformationComponent implements OnInit ,OnDestroy {
  appLabel: any;
  appLanguage: any;
  middleName: any = '';
  vendorId: any;
  traderMobile: any;
  readOnlyMode: any;
  contactsMobileIndex: any;
  contactsMailIndex: any;
  traderJson: any;
  editableFlag: boolean = false;
  loader:any=false
  mandatoryFields: any[];

  constructor(private sharedService: SharedService,private componentLoaderService:ComponentLoaderService,
    private toastr:ToastrCustomService) { }

  ngOnInit(): void {
   
    console.log( this.mandatoryFields,"hiii")
    this.vendorId = sessionStorage['trader_id'];
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getAppLabels();
    this.traderMobile = sessionStorage["mobile"];
    console.log(this.traderMobile);

    this.getTraderJson();
    if (sessionStorage.getItem("isLoggedIn") == "true")
      this.readOnlyMode = true;

  }

  getAppLabels() {
    this.sharedService
    .getJson("app-labels/" + this.appLanguage + "/traderProfile")
    .subscribe(res => {
      
      this.appLabel = res['basicInformation']
      this.getTraderJson();
    });
  }
  getTraderJson() {
    this.sharedService
      .getTraderDetailsForLoggedInUser(sessionStorage["auth_token"])
      .subscribe((traderResponse) => {
        console.log("Trader Details Middle Name", traderResponse[0].traderProfile.firstName);
        if (this.appLanguage == 'ENG-US') {
          this.middleName = traderResponse[0].traderProfile.firstName.split(' ')[1];
          if (this.middleName == undefined) this.middleName = '';
          traderResponse[0].traderProfile.firstName = traderResponse[0].traderProfile.firstName.split(' ')[0];
        }
        console.log("Trader Details Middle Name", this.middleName);
        
        console.log(
          "Trader Details",
          traderResponse[0]["traderProfile"]["contacts"]
        );

        traderResponse[0]["traderProfile"]["contacts"].filter((x, index) => {
          if (x.contactType.code == "CONTACT_TYPE.MAIL")
            this.contactsMailIndex = index;
          if (x.contactType.code == "CONTACT_TYPE.MOBILE")
            this.contactsMobileIndex = index;
        });

        if (traderResponse[0]["traderProfile"]["contacts"].length == 0) {
          console.log("Trader-Details Undefined");
          this.sharedService.getJSONData("app-models/" + this.appLanguage + "/post-jsons/trader").subscribe((resJson) => {
            traderResponse[0]["traderProfile"]["contacts"] =
              resJson["traderProfile"]["contacts"];
            this.contactsMailIndex = 0;
            this.contactsMobileIndex = 1;
            this.traderJson = traderResponse[0];
            console.log("Trader-Json-Updated", this.traderJson);
          });
        } else this.traderJson = traderResponse[0];
      });
  }

  toggleEdit() {
    console.log('Clicked', this.editableFlag)
    this.editableFlag = !this.editableFlag;
  }

  navigateNext() {
    if (this.appLanguage == 'ENG-US') {
      this.traderJson.traderProfile.firstName = this.traderJson.traderProfile.firstName + ' ' + this.middleName;
    }
    //While Logged In And Navigating back and forth
    if (this.readOnlyMode) {
      let lazyComp: any = import(
        "../../vendor-profile-forms/basic-comapny-information/basic-comapny-information.component"
      ).then(
        ({ BasicComapnyInformationComponent }) =>
          BasicComapnyInformationComponent
      );
      this.componentLoaderService.storeLazyComponent({
        component: lazyComp,
        componentName: "BasicComapnyInformationComponent",
      });
    } else {
      //While signing up
      this.sharedService
        .patchTraderProfile(this.traderJson )
        .subscribe((res) => {
          let lazyComp: any = import(
            "../../vendor-profile-forms/basic-comapny-information/basic-comapny-information.component"
          ).then(
            ({ BasicComapnyInformationComponent }) =>
              BasicComapnyInformationComponent
          );
          this.componentLoaderService.storeLazyComponent({
            component: lazyComp,
            componentName: "BasicComapnyInformationComponent",
          });
          //console.log("Details Updated Successfully");
        });
    }
  }
  updateTraderDetails() {
    this.loader = true;
    if (this.appLanguage == 'ENG-US') {
      console.log(this.middleName)
      this.traderJson.traderProfile.firstName = this.traderJson.traderProfile.firstName + ' ' + this.middleName;
    }
    //Update while loggedIn
    console.log(this.traderJson);
    this.sharedService
      .patchTraderProfile(this.traderJson)
      .subscribe((res) => {
        this.editableFlag = false;
        if (this.appLanguage == 'ENG-US') {
          this.middleName = res.traderProfile.firstName.split(' ')[1];
          this.traderJson.traderProfile.firstName = res.traderProfile.firstName.split(' ')[0];
        }
        this.toastr.showSuccess('Details Updated Successfully');
        console.log("Details Updated Successfully");
        //this.service.traderDetails.next(res);
        this.loader = false;
      });
  }


  ngOnDestroy(): void {
    console.log("Destroying loop"); // ngOnDestroy is not triggering
   // this.sharedService
    //this.clearAutoRefreshLoop();
  }
  isFieldInvalid(fieldName: string): boolean {
    this.mandatoryFields = this.sharedService.dataArray;
  return this.mandatoryFields.includes(fieldName)



    
}


    


}
