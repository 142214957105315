<div class="user-registration">
    <section class="main-section background-img">
        <div class="container-fluid">
            <div class="content">
                <div class="row">
                    <div class="col-12 mr-3 mt-3 text-right">
                        <img class="logo" src="./assets/images/logo+tagline_fawn.png">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="card col signup-form">
                            <div class="card-body">
                                <h5 class="header text-left mt-4">Create your Seller Account</h5>
                                <div class="text-left mt-4 fs-6">
                                    <label class="radio-inline p-2"><input type="radio" name="appLanguage"
                                            value="ENG-IN"
                                            (click)="updateAppLanguage($event.target.value)" style=" accent-color: rgba(13,110,253,1);">&nbsp;India</label>
                                    <label class="radio-inline p-2"><input type="radio" name="appLanguage"
                                            value="ENG-US"
                                            (click)="updateAppLanguage($event.target.value)" style=" accent-color: rgba(13,110,253,1);">&nbsp;USA</label>


                                </div>
                                <div *ngIf="tenantJson && languageFlag">
                                    <div class="form-input">
                                        <label class="col-12 col-md-12 pl-0">Mobile Number / Email ID</label>
                                        <div class="input-group input-group-sm mb-3">
                                            <input type="text" class="form-control" (keyup)="checkInputType($event)"
                                                #inputReference="ngModel" [(ngModel)]="createUserJson.login"
                                                aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                [disabled]="smsOtpFlag" style="margin-right: 10px;" required>
                                            <div class="input-group-append">
                                                <button type="button"
                                                    class="btn  btn-sm profile-side-nav-next-prev-button"
                                                    style="padding: 3px 7px; font-size: 12px; height: 30px; border-radius: 4px; border-color: rgba(13,110,253,1) !important;color: rgba(13,110,253,1);"
                                                    (click)=" sendOtp()" [disabled]="!checkIdLoginIdValid()">Send
                                                    OTP</button>
                                            </div>
                                        </div>
                                        <div *ngIf="inputReference.touched">
                                            <!-- <small class="text-danger"
                                                *ngIf="inputReference.errors?.required">Email/mobile is a required
                                                field!</small> -->
                                            <small class="text-danger" *ngIf="!checkIdLoginIdValid()">Please
                                                enter a valid Email/mobile!</small>
                                        </div>
                                        <small *ngIf="smsOtpFlag && !validateOTPFlag" class="text-success">
                                            OTP sent.
                                            <ng-container *ngIf="mobileFlag">If the number is registered for DND, OTP
                                                won't get delivered, in such cases we urge you to register via
                                                email</ng-container>
                                        </small>
                                        <small *ngIf="userExists" class="text-danger">User Already Exists!</small>
                                    </div>


                                    <div class="form-input" *ngIf="smsOtpFlag">
                                        <label class="col-12 col-md-12 pl-0">Enter OTP</label>
                                        <div class="input-group input-group-sm mb-3">
                                            <input type="text" class="form-control" [pattern]="'^[0-9]{6}$'"
                                                aria-label="Small" [(ngModel)]="otpEntered"
                                                aria-describedby="inputGroup-sizing-sm" style="margin-right: 10px;"
                                                [disabled]="correctOTP" required>
                                            <div class="input-group-append">
                                                <button type="button"
                                                    class="btn  btn-sm profile-side-nav-next-prev-button"
                                                    style="padding: 3px 7px; font-size: 12px; height: 30px; border-radius: 4px;border-color: rgba(13,110,253,1) !important;color: rgba(13,110,253,1);"
                                                    [disabled]="correctOTP" (click)="validateOtp()">Verify OTP</button>
                                            </div>
                                        </div>
                                        <small *ngIf="!validateOTPFlag" class="text-muted">We have sent the OTP to this
                                            mobile/email.</small>
                                        <small *ngIf="correctOTP" class="text-success">
                                            OTP verified &nbsp;<i class="fa fa-check" aria-hidden="true"></i>
                                        </small>
                                        <small *ngIf="validateOTPFlag && !correctOTP" class="text-danger">
                                            Incorrect OTP &nbsp;<i class="fa fa-times" aria-hidden="true"></i>
                                        </small>
                                    </div>

                                    <div class="form-input">
                                        <label class="col-12 col-md-12 pl-0">Name</label>
                                        <div class="input-group input-group-sm mb-3">
                                            <input type="text" [(ngModel)]="createUserJson.name" class="form-control"
                                                [pattern]="'^[a-zA-Z0-9\-_]{0,40}$'" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm" required>
                                        </div>
                                    </div>

                                    <div class="form-input">
                                        <label class="col-12 col-md-12 pl-0">Organization</label>
                                        <div class="input-group input-group-sm mb-3">
                                            <input type="text" [pattern]="'^[a-zA-Z0-9\-_]{0,40}$'" class="form-control"
                                                aria-label="Small" [(ngModel)]="tenantJson.login"
                                                aria-describedby="inputGroup-sizing-sm" style="margin-right: 10px;">
                                            <div class="input-group-append">
                                                <button type="button" (click)="checkLoginName()" required
                                                    class="btn  btn-sm profile-side-nav-next-prev-button"
                                                     [disabled]="!tenantJson.login"
                                                    style="padding: 3px 7px; font-size: 12px; height: 30px; border-radius: 4px;border-color: rgba(13,110,253,1) !important;color: rgba(13,110,253,1);">Check
                                                    </button>
                                            </div>
                                        </div>
                                        <small *ngIf="tenantExists" class="text-danger">
                                            Organisation name Already exists! &nbsp;<i class="fa fa-times"
                                                aria-hidden="true"></i>
                                        </small>
                                        <small *ngIf="!tenantExists && tenantCheckFlag" class="text-success">
                                            Organisation name available
                                            &nbsp;<i class="fa fa-check" aria-hidden="true"></i>
                                        </small>
                                    </div>


                                    <div class="form-input">
                                        <label class="col-12 col-md-12 pl-0 input-label">Select State</label>
                                        <ng-container *ngIf="states && states.length >0">
                                            <select
                                                style="width: 100%;color:rgb (119, 119, 119); opacity: 0.8; font-size: 14px;height: 35px;"
                                                [(ngModel)]="state.code" placeholder="Select State">

                                                <option value="" selected disabled>Select state</option>
                                                <option *ngFor="let dropdownElement of states"
                                                    [value]="dropdownElement.code">
                                                    {{dropdownElement.labels[0].text}}

                                                </option>
                                            </select>

                                        </ng-container>

                                    </div>

                                    <div class="form-input">
                                        <label class="col-12 col-md-12 pl-0 input-label">Password   <span  (click)="showInfo = !showInfo">
                                            <i class="info-icon fa fa-info" aria-hidden="true"></i>
                                            <div *ngIf="showInfo" class="info-message">
                                              Password needs a minimum of 8 characters, max 15 characters with at least 1 number and 1 special character like (@$%).
                                            </div>
                                        </span></label>
                                        <div class="input-group input-group-sm mb-3">
                                            <input type="{{ showHidePassword ? 'text' : 'password' }}"  #inputReference1="ngModel"  [ngClass]="isPassword?'input-field':''" [(ngModel)]="password" id="passwordInput" [pattern]="'^(?=.*?)(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$'"
                                            class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" required>
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="inputGroup-sizing-sm" (click)="showHidePassword=!showHidePassword" style="border-radius: 0px 5px 5px 0;border-left :  0;height: 40px;">
                                                    <i class="fa fa-eye" style="font-size:18px;color: #1A237F;" *ngIf="showHidePassword"></i>
                                                    <i class="fa fa-eye-slash" style="font-size:18px;color: #1A237F;" *ngIf="!showHidePassword"></i>
                                                </span>
                                            </div>
                                            
                                           
                                        </div>
                                        <div *ngIf="inputReference1.touched">
                                            <small class="text-danger" *ngIf="inputReference1.errors?.required">Password is a required field!</small>
                                            <small class="text-danger" *ngIf="inputReference1.errors?.pattern">Please enter a valid Password!</small>
                                          </div>
                                    </div>
                                  
                                      
                                    <div class="form-input text-center">
                                        <button type="button" (click)="signUp()" [disabled]="!correctOTP || tenantExists || !tenantCheckFlag || !password || !state.code" 
                                            style="width:100%; background-color: #1A237F;color:white">Register Account</button>
                                    </div>

                                    <div class="form-input text-center " style="margin-bottom: 5px;">
                                        <small class="text-muted">
                                            Already have an account? <a class="link" (click)="login()">Login Here</a>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div class="footer ">
            <div class="text-center">
                <small class="title" style="font-size: 14px !important;font-weight: normal !important;">Powered By</small>
                <br>
                <div style="background-color: white;padding: 10px;border-radius: 5px; ">
                    
                    <img class="logo1" src="./assets/images/Sonata_Softwarew-Logo.png" width="70px" height="30px">
                    <img class="logo1" src="./assets/images/pcf-logo.png" width="75px" height="30px"
                        >
                </div>
            </div>
        </div>
    </section>
</div>