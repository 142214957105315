<div class="user-Login">
    <section class="main-section background-img">
        <div class="container-fluid">
            <div class="content">
                <div class="row">
                    <div class="col-12 mr-3 mt-3 text-right">
                        <img class="logo" src="./assets/images/logo+tagline_fawn.png">
                    </div>
                </div>

                <div class="row ">
                    <div class="col-1 col-md-1"></div>
                    <div class="col col-md-4 sign ">
                        <div class="card col signup-form">
                            <div class="header mt-3" style="font-size: 26px;">
                                Seller App Login
                            </div>
                            <div class="text-left mt-3" style="font-size: 16px;">
                                <label class="radio-inline p-2"><input type="radio" name="appLanguage" value="ENG-IN"
                                        (click)="updateAppLanguage($event.target.value)"
                                        style=" accent-color: rgba(13,110,253,1);">&nbsp;India</label>
                                <label class="radio-inline p-2"><input type="radio" name="appLanguage" value="ENG-US"
                                        (click)="updateAppLanguage($event.target.value)"
                                        style=" accent-color: rgba(13,110,253,1);">&nbsp;USA</label>
                                <div class="form-input" *ngIf="!languageFlag">
                                    <small class="text-muted">New User? <a class="link" (click)="register()">Sign Up
                                            Here</a></small>
                                </div>

                            </div>

                            <form #sellerUserLoginForm="ngForm">
                                <div class="card-body" *ngIf="languageFlag" style="padding-left: 0px;">
                                    <div class="form-input text-left">
                                        <label class="col-12 col-md-12 pl-0">Mobile Number / Email ID</label>
                                        <div class="input-group input-group-sm ">
                                            <input [(ngModel)]="model.mobile" #sellerUserName="ngModel"
                                                [ngModelOptions]="{ standalone: true }" type="text"
                                                class="form-control fs-6" style="margin-right: 10px;border-radius:5px"
                                                aria-label="Small" aria-describedby="inputGroup-sizing-sm" required>
                                            <div class="input-group-append">
                                                <button type="button" [disabled]="!model.mobile||model.mobile==''"
                                                    class="btn  btn-sm fs-7 "
                                                    style="padding: 3px 7px;  height: 30px; border-radius: 4px;border-color: rgba(13,110,253,1);color: rgba(13,110,253,1);width: 150px;"
                                                    (click)="getUserTenancyByUserLogin()">Get Organizations</button>
                                                <!-- <span class="input-group-text" id="inputGroup-sizing-sm"
                                                    >Get
                                                    Organization</span> -->
                                            </div>
                                        </div>
                                        <span *ngIf="sellerUserName.touched||userNameValidation">
                                            <small *ngIf="sellerUserName.errors?.required" class="text-danger">Mobile
                                                Number/ Email ID is
                                                required</small>
                                        </span>

                                        <span class="text-danger" *ngIf="checkInitialClick"> No Organization associated
                                            with provided Login details , Please provide valid details.</span>


                                    </div>
                                    <!-- Enter login Name -->
                                    <div class="form-input mt-3">
                                        <label class="col-12 col-md-12 pl-0">Organization Name</label>
                                        <ng-container *ngIf="convertedData.length>1">
                                            <select [disabled]="convertedData.length==0"
                                                style="width: 100%;color:rgb (119, 119, 119); opacity: 0.8; font-size: 14px;height: 35px;border-radius:5px"
                                                [(ngModel)]="model.loginId" [ngModelOptions]="{standalone: true}"
                                                placeholder="Select Organization"
                                                (change)="changeSelectedOption($event)">
                                                <option value="" disabled selected class="singleEntry"
                                                    style="color:rgb (119, 119, 119); opacity: 0.8; font-size: 14px;">
                                                    Select Organization</option>

                                                <option *ngFor="let organizationName of  convertedData
                              ;let j = index;" [value]="organizationName.value"
                                                    style="color:rgb (119, 119, 119); opacity: 0.8; font-size: 14px;">
                                                    {{organizationName.key | titlecase }}</option>
                                            </select>

                                        </ng-container>
                                        <ng-container *ngIf="convertedData.length==1||convertedData.length==0">
                                            <input type="text" class="form-control"
                                                placeholder="Enter Organization Name" [(ngModel)]="model.loginId"
                                                [ngModelOptions]="{standalone: true}" disabled
                                                style="color:rgb (119, 119, 119); opacity: 0.8; font-size: 14px;">
                                        </ng-container>
                                        <div *ngIf="organizationValidation" class="text-danger mt-1">Please Provide
                                            Valid Organization Name</div>

                                        <!-- <span *ngIf="organizationValidation" class="text-danger">Please provide valid organization details.</span> -->
                                    </div>
                                    <!-- Ends Here -->

                                    <div class="form-input mt-3">
                                        <label class="col-12 col-md-12 pl-0 ">Password</label>
                                        <div class="input-group input-group-sm ">
                                            <input type="{{ showHidePassword ? 'text' : 'password' }}"
                                                [(ngModel)]="model.password" #sellerUserPassword="ngModel"
                                                [ngModelOptions]="{ standalone: true }"
                                                class="form-control text-break fs-5" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm" style="border-right: 0;"
                                                required>
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="inputGroup-sizing-sm"
                                                    (click)="showHidePassword=!showHidePassword"
                                                    style="border-radius: 0px 5px 5px 0;border-left :  0;height: 40px;">
                                                    <i class="fa fa-eye" style="font-size:18px;color: #1A237F;"
                                                        *ngIf="showHidePassword"></i>
                                                    <i class="fa fa-eye-slash" style="font-size:18px;color: #1A237F;"
                                                        *ngIf="!showHidePassword"></i>
                                                </span>
                                            </div>
                                        </div>



                                        <span *ngIf="sellerUserPassword.touched||userPasswordValidation">
                                            <small *ngIf="sellerUserPassword.errors?.required"
                                                class="text-danger">Password is
                                                required</small>
                                        </span>






                                        <div *ngIf="invalidFlag" class="text-center text-danger mt-1">Verify Username
                                            and
                                            Password</div>
                                        <div *ngIf="errorFlag" class="text-center text-danger mt-1">Something Went wrong
                                            Please try Again..
                                        </div>
                                    </div>
                                    <div class="form-input">
                                        <!-- Forgot Password -->
                                        <div class="form-input " style="margin-left: 265px;">
                                            <small class="text-muted"><a class="link" (click)="forgotPassword()">Forgot
                                                    Password?</a></small>
                                        </div>
                                    </div>
                                    <div class="form-input mt-3">
                                        <button type="button" (click)="login()"
                                            class="btn btn-primary btn-sm btn-block "
                                            style="width:100%; background-color: #1A237F;border-color: #1A237F;">LOGIN</button>
                                        <!-- <app-primary-button [button_label]="'LOGIN'" [disabledFlag]="loginForm.form.invalid" [isLoading]="loader" (button_pressed)="login()"></app-primary-button> -->
                                        <!-- <app-primary-button [button_label]="'LOGIN'" (button_pressed)="login()"></app-primary-button> -->

                                    </div>
                                    <div class="horizontal-lines">
                                        <div class="line"></div>
                                        <div style="width: 1%;"></div> <!-- Adjust the gap width as needed -->
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-input text-center mt-3">
                                        <small class="text-muted">Dont have an account? <a class="link "
                                                (click)="register()">Sign Up
                                                Here</a></small>
                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                    <div class="col-1  col-md-7">
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="footer ">
            <div class="text-center">
                <small class="title" style="font-size: 14px !important;font-weight: normal !important;">Powered
                    By</small>
                <br>
                <div style="background-color: white;padding: 10px;border-radius: 5px; ">

                    <img class="logo1" src="./assets/images/Sonata_Softwarew-Logo.png" width="70px"
                        height="30px">
                    <img class="logo1" src="./assets/images/pcf-logo.png" width="75px" height="30px">
                </div>
            </div>
        </div>

    </section>
    <ng-template #forgot let-c="close" let-d="dismiss" style="width: 100%; height: 1000px;">
        <div class="modal-header">
            <h5 class="d-inline">Forgot Password?</h5>
            <button type="button" class="close " style="border: none;background-color: white; " aria-label="Close"
                (click)="close()">
                <span aria-hidden="true" style="font-size: 32px;">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <form #forgotPasswordform="ngForm">

                <div class="p-2">
                    <input type="text" class="form-control" placeholder="Enter mobile/email" name="mobile/email"
                        [(ngModel)]="mobile" required>
                </div>

                <div class="p-2">
                    <input type="text" class="form-control" placeholder="Enter organisation Name"
                        name="organisation name" [(ngModel)]="loginId" required>
                </div>

                <div class="p-2" *ngIf="otpFlag">
                    <button type="button" class="btn btn-primary" [disabled]="forgotPasswordform.form.invalid || loader"
                        (click)="sendOtp()" style="background-color: #1A237F;border-color: #1A237F;">SEND OTP</button>
                </div>

                <div class="p-2" *ngIf="!otpFlag">
                    <input type="text" class="form-control" placeholder="Enter OTP" name="otp" [(ngModel)]="models.otp"
                        required>
                    <small *ngIf="invalidOTPFlag" class="text-danger">Incorrect OTP &nbsp;<i class="fa fa-times"
                            aria-hidden="true"></i></small>
                </div>

                <div class="p-2" *ngIf="!otpFlag">
                    <input type="password" class="form-control" placeholder="Enter New Password" name="newPassword"
                        [(ngModel)]="models.newPassword"
                        pattern="^(?=.*?)(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$" required>
                    <small class="text-muted">Password needs a minimum of 8 characters, max 15 characters with atleast 1
                        number and 1 special character like(@$%).</small>
                </div>

                <div class="p-2" *ngIf="!otpFlag">
                    <input type="password" class="form-control" placeholder="Confirm New Password"
                        name="confirmpassword" [(ngModel)]="confirmPassword"
                        pattern="^(?=.*?)(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$" required>
                    <span *ngIf="confirmPassword && models.newPassword !== confirmPassword">
                        <small class="text-danger">Password & Confirm Password does not match</small>
                    </span>
                    <span *ngIf="confirmPassword && models.newPassword === confirmPassword">
                        <small class="text-success">Password matches</small>
                    </span>
                </div>

                <div class="p-2" *ngIf="!otpFlag">
                    <button type="button" class="btn btn-primary"
                        [disabled]="forgotPasswordform.form.invalid || loader || models.newPassword !== confirmPassword"
                        (click)="updatePassword()" style="background-color: #1A237F;border-color: #1A237F;">Update
                        Password</button>
                </div>
            </form>
        </div>
    </ng-template>


</div>