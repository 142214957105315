<!-- pagination.component.html -->
<div class="custom-pagination">

    <ngb-pagination
      class="d-flex justify-content-center"
      [collectionSize]="collectionSize"
      (pageChange)="pageChange.emit($event)"
      [maxSize]="2"
      [pageSize]="pageSize"
      [rotate]="true"
      [ellipses]="true"
      [boundaryLinks]="true"
      [(page)]="currentPage"
    ></ngb-pagination>
    </div>