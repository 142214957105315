import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './application-services/Authentication-services/auth-guard/auth.guard';
import { UserLoginComponent } from './user-access/user-login/user-login.component';
import { UserRegistrationComponent } from './user-access/user-registration/user-registration.component';

const routes: Routes = [
  { path: '', redirectTo: 'user-login', pathMatch: 'full' },
  { path: 'user-login', component: UserLoginComponent },
  { path: 'user-registration', component: UserRegistrationComponent },
  { path: 'vendorDashboard', loadChildren: () => import('./self-vendor-service/self-vendor-service.module').then(mod => mod.SelfVendorServiceModule), canActivate: [AuthGuard] },//, canActivate: [AuthGuard]
  { path: 'products', loadChildren: () => import('./product-management/product-management.module').then(m => m.ProductManagementModule), canActivate: [AuthGuard] },//, canActivate: [AuthGuard]
  { path: '**', redirectTo: 'user-login' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
