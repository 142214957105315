import { Component, OnInit } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-brand-details',
  templateUrl: './brand-details.component.html',
  styleUrls: ['./brand-details.component.scss']
})
export class BrandDetailsComponent implements OnInit {
  brands: any = [];
  model = {};
  brandLength: any;
  traderJson: any;
  readOnlyMode: boolean;
  editableFlag: boolean;
  brandOwnerships: any;
  appLanguage:any;
  traderRefDataCodes: any;
  refDataCodes: any;
  loader: any;
  mandatoryFields: any[];

  constructor(private sharedService:SharedService,private componentLoaderService:ComponentLoaderService,
    private toastr:ToastrCustomService) { }

  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem('appLanguage');
    this.brandLength = 1;
    this.getDataCodes();
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;
  
  }

  getDataCodes() {
    this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.sharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {

        this.traderRefDataCodes = traderRef;
        this.getBrandOwnerShips();
        this.getTraderJson();
     
      });
    }
    );
  }
 
  getBrandOwnerShips() {
    this.sharedService.getTraderGREFDataByClassCode(this.traderRefDataCodes.brand.classCode).subscribe(res => this.brandOwnerships = res);
    console.log("true",this.brandOwnerships)
  }
  getTraderJson() {
    this.sharedService.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(traderResponse => {
      console.log('Trader Details', traderResponse[0]);
      console.log('Trader Details', traderResponse[0]['traderProfile']['traderBrands']);



      if (traderResponse[0]['traderProfile']['traderBrands'].length == 0) {
        console.log('Trader-Details Undefined');


        this.sharedService.getJSONData('app-models/'+this.appLanguage+'/post-jsons/trader').subscribe(resJson => {
          traderResponse[0]['traderProfile']['traderBrands'] = resJson['traderProfile']['traderBrands'];
          this.traderJson = traderResponse[0];
          console.log('Trader-Json-Updated', this.traderJson);
        })


      }
      else if( traderResponse[0]['traderProfile']['traderBrands'][0]['brandOwnerShip'] == undefined ) 
      {

        this.sharedService.getJSONData('app-models/'+this.appLanguage+'/post-jsons/trader').subscribe(resJson => {
          traderResponse[0]['traderProfile']['traderBrands'][0]['brandOwnerShip']= resJson['traderProfile']['traderBrands'][0]['brandOwnerShip']
          this.traderJson = traderResponse[0];
          console.log('Trader-Json-Updated', this.traderJson);
        })

      }
      
      
      
      else this.traderJson = traderResponse[0];
    })
  }
  navigateNext() {
    if (this.readOnlyMode || this.traderJson.traderProfile.traderBrands[0].brandOwnerShip.code == '') {
      let lazyComp: any = import('../../vendor-profile-forms/display-information/display-information.component').then(({ DisplayInformationComponent }) => DisplayInformationComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'DisplayInformationComponent' });
    }
    else {
      this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
        let lazyComp: any = import('../../vendor-profile-forms/display-information/display-information.component').then(({ DisplayInformationComponent }) => DisplayInformationComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'DisplayInformationComponent' });
        console.log('Details Updated Successfully')
      })
    }
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;

    if (this.traderJson.traderProfile.traderBrands[0].brandOwnerShip  && this.traderJson.traderProfile.traderBrands[0].brandOwnerShip.code=="") {
      delete this.traderJson.traderProfile.traderBrands[0].brandOwnerShip;
    }
 

    this.sharedService.patchTraderProfile(this.traderJson).subscribe(res => {
      this.editableFlag = false;
     this.toastr.showSuccess('Details Updated Successfully');
      console.log('Details Updated Successfully');
      this.sharedService.traderDetails.next(res);
      this.loader = false;
    });
         // Set a timer to call a method after 5 seconds (5000 milliseconds)
    setTimeout(() => {
      this.getTraderJson();
    }, 1000);
  }
  navigatePrevious() {
    let lazyComp: any = import(
      "../../vendor-profile-forms/bank-details/bank-details.component"
    ).then(({ BankDetailsComponent }) => BankDetailsComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "BankDetailsComponent",
    });
  }

  isFieldInvalid(): boolean {
    const fieldName="traderBrands"
    this.mandatoryFields = this.sharedService.dataArray;
  return this.mandatoryFields.includes(fieldName);



    
}

}
