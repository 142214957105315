<div class="expired-authentication">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Session Timeout! Please Login Again...</h4>
        <!-- <button type="button" class="close button-close" data-dismiss="modal" (click)="close()" aria-label="Close">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button> -->
    </div>
    <div class="modal-body" style="padding: 25px 70px 70px;">
        <div class="text-center mb-3">
            <img  src="./assets/images/logo+tagline_fawn.png" style="height: 75px;">
            <!-- <label class="radio-inline p-2"><input type="radio" name="appLanguage" value="ENG-IN"
                    (click)="updateAppLanguage($event.target.value)">&nbsp;India</label>
            <label class="radio-inline p-2"><input type="radio" name="appLanguage" value="ENG-US"
                    (click)="updateAppLanguage($event.target.value)">&nbsp;USA</label>
           -->
        </div>
        <div class="mb-2 login-form">
            <form #sellerUserTimeoutForm="ngForm">
                <div class="form-input">
                    <label class="text-label d-block">Enter mobile/email</label>
                    <div class="input-group input-group-sm ">
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="model.mobile"
                        #sellerUserName="ngModel"
                        [ngModelOptions]="{ standalone: true }" required  style="margin-right: 5px;" placeholder="Enter mobile/email">
                        <div class="input-group-append org-btn-container" style="margin-left: 5px;">
                            <button type="button" class="btn btn-outline-primary btn-sm"  [disabled]="!model.mobile||model.mobile==''"
                                (click)="getUserTenancyByUserLogin()">Get Organization</button>
                        </div>
                    </div>
                </div>
                <span *ngIf="sellerUserName.touched||userNameValidation">
                    <small *ngIf="sellerUserName.errors?.required" class="text-danger">Mobile
                        Number/ Email ID is
                        required</small>
                </span>

                <span class="text-danger" *ngIf="checkInitialClick"> No Organization associated with provided Login details , Please provide valid details.</span>
                <div class="form-input mt-4">
                    <label class="text-label d-block">Please Select Organization</label>
                    <div class=" ">
                        <ng-container *ngIf="convertedData.length==1||convertedData.length==0">
                            <input class="form-control form-control-sm" type="text"
                                [disabled]="convertedData.length==0||convertedData.length==1" [(ngModel)]="model.loginId"
                                [ngModelOptions]="{ standalone: true }" placeholder="Please Select Organization">
                        </ng-container>
                        <ng-container *ngIf="convertedData.length>1">
                            <select class="form-select form-select-sm" [(ngModel)]="model.loginId"  [ngModelOptions]="{ standalone: true }"
                                (change)="changeSelectedOption($event)" placeholder="Select Organization">
                                <option value="" disabled selected class="singleEntry"
                                    style="color:rgb (119, 119, 119); opacity: 0.8; font-size: 14px;">
                                    Select Organization</option>
                                <option *ngFor="let organizationName of  convertedData
                                ;let j = index;" [value]="organizationName.value"
                                    style="color:rgb (119, 119, 119); opacity: 0.8; font-size: 14px;">
                                    {{organizationName.key | titlecase }}</option>
    
                            </select>
                        </ng-container>
                        <div *ngIf="organizationValidation" class="text-danger mt-1">Please Provide Valid Organization Name</div>
              
                    </div>
                </div>
                <div class="form-input mt-4">
                    <label for="dateOfBirth" class="text-label d-block">Please Enter Password</label>
                    <div class="input-group input-group-sm ">
                        <input type="{{ showHidePassword ? 'text' : 'password' }}" class="form-control password-input "
                            [(ngModel)]="model.password"  #sellerUserPassword="ngModel"  [ngModelOptions]="{ standalone: true }" required placeholder="Please Enter Password">
                        <div class="input-group-append org-btn-container">
                            <span class="input-group-text password-visibility-span" id="inputGroup-sizing-sm"
                                (click)="showHidePassword=!showHidePassword">
                                <i class="fa fa-eye" style="font-size:18px;color: #1A237F;" *ngIf="showHidePassword"></i>
                                <i class="fa fa-eye-slash" style="font-size:18px;color: #1A237F;"
                                    *ngIf="!showHidePassword"></i>
                            </span>
                        </div>
                    </div>
                    <span *ngIf="sellerUserPassword.touched||userPasswordValidation">
                        <small *ngIf="sellerUserPassword.errors?.required" class="text-danger">Password is
                            required</small>
                    </span>
    
                </div>
    
                                        
                <div *ngIf="invalidFlag" class="text-center text-danger mt-1">Verify Username and
                    Password</div>
                <div *ngIf="errorFlag" class="text-center text-danger mt-1">Something Went wrong
                    Please try Again..
                </div>
    
                <div class="form-input mt-3 " style="padding-top: 20px;">
                    <button type="button" class="btn btn-primary btn-sm btn-block login-btn" (click)="userLogin()"
                        style="width:100%">LOGIN</button>
    
                </div>
            </form>
        
        </div>
    </div>
    <!-- <div class="modal-footer">
        <div class="btn-group btn-group-sm" role="group" aria-label="...">
            <button type="button" class="btn btn-secondary close-btn">Close</button>
            <button type="button" class="btn btn-secondary submit-btn" style="    margin-right: 10px;
border-radius: 3px;
">Crop Image</button>
        </div>
    </div> -->
</div>