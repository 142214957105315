import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import jsPDF from "jspdf";
// import * as jsPDF from 'jspdf';

import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { saveAs } from 'file-saver';
import { ToastrCustomService } from '../toastr-custom.service';



declare var $: any;
@Component({
  selector: 'app-trader-details-pdf',
  templateUrl: './trader-details-pdf.component.html',
  styleUrls: ['./trader-details-pdf.component.scss']
})
export class TraderDetailsPdfComponent implements OnInit {
  @Output() pdfClicked =  new EventEmitter<boolean>();
  @Output() sendData: EventEmitter<any> = new EventEmitter();
  @ViewChild('yourModalContent') private yourModalContent;
  @ViewChild('pdfview') private pdfview;
  @ViewChild('pdfContainer') pdfContainer: ElementRef;

  pdfIcon: any;
  traderProfile: any;
  test: any;
  enityType: any;
  traderDetails: any;
  attachedMSME: any;
  loader: boolean;
  attachedBankIdProof: HTMLImageElement;
  attachedKYCTan: HTMLImageElement;
  attachedKYCNodalLetter: HTMLImageElement;
  attachedKYCAuthorizedSignatory: HTMLImageElement;
  gstNumber: string;
  attachedAddressProof: any;
  traderIcon: any;
  deliveryManagerIcon: any;
  communicationIcon: string | ArrayBuffer;
  customerServiceManagerIcon: string | ArrayBuffer;
  financeDivisionMangerIcon: string | ArrayBuffer;
  ceoIcon: string | ArrayBuffer;
  infoIcon: string | ArrayBuffer;
  attachmentIcon: string | ArrayBuffer;
  appLanguage: any;
  appLabel: any;
  refDataCodes: any;
  traderRefDataCodes: any;
  financeDivisionMangerIconUS: string | ArrayBuffer;
  warehouseDetails: any=[];
  worknodeEntity: any;
 
  arr2: ArrayBuffer;
  gstNumber1: HTMLImageElement;

  messages=[];
  notificationMessages: string[] = []; 
showNotifications = false;
  selectedmsmeType: any;

  

  constructor(private service: SharedService, private modalService: NgbModal,private http: HttpClient,private renderer: Renderer2,
    private toastr:ToastrCustomService) { }

  ngOnInit(): void {
    //console.log("Trader Details", this.traderDetails);
    
    this.appLanguage = sessionStorage['appLanguage'];
    this.getDataCodes();
    this.getAppLabels();
    //Updating the current PDF details
    this.service.traderDetails.subscribe((res) => (this.traderDetails = res));
    this.service.traderAttachment.subscribe((res) => {
      //console.log("Attachments---------",this.traderDetails, res);
      
      // if (res[0] == "TRADER_ATTACHMENT_KIND.MSME") this.attachedMSME = res[1];
      // if (res[0] == "TRADER_ATTACHMENT_KIND.BANK_IDENTITY_PROOF")
      //   this.attachedBankIdProof = res[1];
      // if (res[0] == "TRADER_ATTACHMENT_KIND.KYC_TAN")
      //   this.attachedKYCTan = res[1];
      // if (res[0] == "TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER")
      //   this.attachedKYCNodalLetter = res[1];
      // if (res[0] == "TRADER_ATTACHMENT_KIND.KYC_AUTHORIZED_SIGNATORY")
      //   this.attachedKYCAuthorizedSignatory = res[1];
      if (res.length) {
        
      if (res[0] == this.traderRefDataCodes.attachment.msme) this.attachedMSME = res[1];
      if (res[0] == this.traderRefDataCodes.attachment.bankIdentityProof)
        this.attachedBankIdProof = res[1];
      if (res[0] == this.traderRefDataCodes.attachment.kycTan)
        this.attachedKYCTan = res[1];
      // if (res[0] == "TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER")
      //   this.attachedKYCNodalLetter = res[1];
      if (res[0] == this.traderRefDataCodes.attachment.authorizedSignatory)
        this.attachedKYCAuthorizedSignatory = res[1];
      if (res[0] == this.traderRefDataCodes.attachment.gstNumber)
        this.gstNumber = res[1]; 
      }
    });
    this.service.warehouseDetails.subscribe((details) => {
      // Your existing code to handle trader details
      // ...
      this.warehouseDetails=details
      // Update the warehouse details and regenerate the PDF
    //  this.getWarehouseDetails() 

      // ...
    });

    // this.getTraderDetails();
    this.getIcons();
  }
  onClick() {
    // Emit event when the component is clicked
    this.pdfClicked.emit(false);
  }
  both(){
    this.onClick();
    this.download();
  }
  getDataCodes() {
    this.service.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.service.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getTraderDetails();
        this.getWarehouseDetails();
        
      });
    }
    );
  }
  // openLoginPopup() {
  //   this.modalService.open(LoginModalComponent, { centered: true, backdrop: 'static' });
  // }
  getAppLabels() {
    this.service
      .getJSONData("app-labels/" + this.appLanguage + "/traderPDF")
      .subscribe((res) => (this.appLabel = res));
  }

  getIcons() {
    this.service.getImage("assets/images/warehouse.png").subscribe((data) => {
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.traderIcon = reader.result;
        },
        false
      );
      if (data) {
        reader.readAsDataURL(data);
      }
    });
    this.service.getImage("assets/images/trolley.png").subscribe((data) => {
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.deliveryManagerIcon = reader.result;
        },
        false
      );
      if (data) {
        reader.readAsDataURL(data);
      }
    });
    this.service
      .getImage("assets/images/icons8-communication.png")
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.communicationIcon = reader.result;
          },
          false
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });
    this.service
      .getImage("assets/images/icons8-online-support-100.png")
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.customerServiceManagerIcon = reader.result;
          },
          false
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });
    this.service
      .getImage("assets/images/icons8-rupee-100.png")
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.financeDivisionMangerIcon = reader.result;
          },
          false
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });
    this.service
      .getImage("assets/images/icons8-us-dollar-100.png")
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.financeDivisionMangerIconUS = reader.result;
          },
          false
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });

    this.service
      .getImage("assets/images/icons8-leader-100.png")
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.ceoIcon = reader.result;
          },
          false
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });
    this.service
      .getImage("assets/images/icons8-info-100.png")
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.infoIcon = reader.result;
          },
          false
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });
    this.service
      .getImage("assets/images/icons8-attach-100.png")
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.attachmentIcon = reader.result;
          },
          false
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });
  }

  getTraderDetails() {
    this.service
      .getTraderDetailsForLoggedInUser(sessionStorage["auth_token"])
      .subscribe((res) => {
        this.traderDetails = res[0];
        this.getAttachment();
        //console.log("----ASYNC RESPONSE------" + JSON.stringify(this.traderDetails));
      });
  }
  //Warehouse Details

  getWarehouseDetails() {
    this.service.getTraderCenterByTraderId(sessionStorage['trader_id'], sessionStorage['auth_token']).subscribe(res => {
      this.warehouseDetails = res;
      console.log("warehouse",this.warehouseDetails);
      let worknodeIds = this.warehouseDetails.map(x => x.workNode.id).toString();
     console.log('Worknode Id', worknodeIds);
      this.service.getWorknodeById(worknodeIds, sessionStorage['auth_token']).subscribe(resWN => {
        console.log('Worknodes Response', resWN);
        let worknodes: any = [];
        worknodes = resWN;
        this.warehouseDetails.map(x => worknodes.filter(y => {
          if (x.workNode.id == y.id) {
            //Sorting worknodeContact
            this.worknodeEntity = y;
            
            y.workNodeContact = y.workNodeContact.sort(function (a, b) {
              var x = a.contactType.code.toLowerCase();
              var y = b.contactType.code.toLowerCase();
              if (x < y) { return -1; }
              if (x > y) { return 1; }
              return 0;
            });
            // this.getCityGREF(y.workNodeAddress.city.code);
            x.workNode = y;
            
           
          }
        }))
      });
    });
  }

 
 
  getAttachment() {
    this.service
      .getAttachment(
        this.traderRefDataCodes.attachment.addressProof,
        sessionStorage["trader_id"],

      )
      .subscribe((res) => {
        if (res) {
          this.attachedAddressProof = new Image();
          this.attachedAddressProof.src =
            "data:image/png;base64," + res.attachment;
        }
      });

    this.service
      .getAttachment(
        this.traderRefDataCodes.attachment.msme,
        sessionStorage["trader_id"]
      )
      .subscribe((res) => {
        if (res) {
          this.attachedMSME = new Image();
          this.attachedMSME.src = "data:image/png;base64," + res.attachment;
        }
      });
    this.service
      .getAttachment(
        this.traderRefDataCodes.attachment.bankIdentityProof,
        sessionStorage["trader_id"]
      )
      .subscribe((res) => {
        if (res) {
          this.attachedBankIdProof = new Image();
          this.attachedBankIdProof.src =
            "data:image/png;base64," + res.attachment;
        }
      });
    this.service
      .getAttachment(
        this.traderRefDataCodes.attachment.kycTan,
        sessionStorage["trader_id"]
      )
      .subscribe((res) => {
        if (res) {
          this.attachedKYCTan = new Image();
          this.attachedKYCTan.src = "data:image/png;base64," + res.attachment;
        }
      });
    // this.service
    //   .getAttachment(
    //     "TRADER_ATTACHMENT_KIND.KYC_NODAL_LETTER",
    //     sessionStorage["trader_id"],
    //     sessionStorage["auth_token"]
    //   )
    //   .subscribe((res) => {
    //     if (res) {
    //       this.attachedKYCNodalLetter = new Image();
    //       this.attachedKYCNodalLetter.src =
    //         "data:image/png;base64," + res.attachment;
    //     }
    //   });
    this.service
      .getAttachment(
        this.traderRefDataCodes.attachment.authorizedSignatory,
        sessionStorage["trader_id"]
      )
      .subscribe((res) => {
        if (res) {
          this.attachedKYCAuthorizedSignatory = new Image();
          this.attachedKYCAuthorizedSignatory.src =
            "data:image/png;base64," + res.attachment;
        }
      });

      // this.service
      // .getAttachment(
      //   this.traderRefDataCodes.attachment.gstNumber,
      //   sessionStorage["trader_id"],
      //   sessionStorage["auth_token"]
      // )
      // .subscribe((res) => {
      //   if (res) {
      //     this.gstNumber = new Image();
      //     this.gstNumber.src =
      //       "data:image/png;base64," + res.attachment;
      //   }
      // });
      this.service.getAttachment(
        this.traderRefDataCodes.attachment.gstNumber,
        sessionStorage["trader_id"]
      ).subscribe((res) => {
        const attachmentData = res.attachment;
        //if (attachmentData.startsWith('data:image'))
        if (!res.fileName.includes('.pdf'))
        {
          // this.gstNumber1 = new Image();
        //  this.gstNumber1.src = "data:image/png;base64," + res.attachment;
        this.gstNumber = "data:image/png;base64," + res.attachment;
        }
        else{
        this.gstNumber = "data:application/pdf;base64," + res.attachment;
        }
      });

  }

  // testDownload() {
  //   var doc = new jsPDF("p", "px", "a4", true);

  //   var specialElementHandlers = {
  //     '#foo': function (element, renderer) {
  //       return true;
  //     }
  //   };
  //   let source = document.getElementById("foo");
  //   doc.fromHTML(source, 15, 15, {
  //     'elementHandlers': specialElementHandlers
  //   });
  //   doc.save('card.pdf');
  //     console.log('clicked');
  // }

  ngOnDestroy() {
    console.log('Destroyed');
  }

  download() {
    let count: number = 0;
    let traderObj: any = Object.entries(this.traderDetails.traderProfile);
    console.log("trader",traderObj);
    //Mandatory fields
    // let mandatoryKeys: any = ["firstName", "lastName", "contacts", "gstNumber", "panNumber", "msmeUAMNumber", "traderBankAccounts", "traderContactPersons"];

    // let mandatoryKeys: any = this.appLabel.mandatoryFields;

    // for (let i = 0; i < traderObj.length; i++) {
    //   for (let keys = 0; keys < mandatoryKeys.length; keys++) {
    //     if (traderObj[i][0] == mandatoryKeys[keys]) {
    //       if ((typeof (traderObj[i][1] == "string") && traderObj[i][1] == "") || (typeof (traderObj[i][1] == "object") && traderObj[i][1].length == 0) || !this.attachedKYCAuthorizedSignatory || !this.attachedBankIdProof) {
    //         this.loader = false;
    //         this.toastr.error('Please update kyc and bank Id');
    //         count = 1;
    //       }
    //     }
    //   }
    // }

let mandatoryKeys: any = this.appLabel.mandatoryFields;

const toastMessages = [];

for (let i = 0; i < traderObj.length; i++) {
  for (let keys = 0; keys < mandatoryKeys.length; keys++) {
    if (traderObj[i][0] == mandatoryKeys[keys].field) {

      if ((typeof traderObj[i][1] === "string" && traderObj[i][1] === "") ||(typeof traderObj[i][1] === "object" && traderObj[i][1].length === 0)) {
    
        toastMessages.push(mandatoryKeys[keys].field);
        toastMessages.push(mandatoryKeys[keys].division);
        count=1;
      }
    }
  }
}

if (!this.attachedKYCAuthorizedSignatory) {
  toastMessages.push("SignatoryAttachment");
  toastMessages.push("Additional KYC");
 
}
if (!this.attachedBankIdProof) {
  toastMessages.push();
}
if (!this.attachedMSME) {
  toastMessages.push("Msmeattachmet");
  toastMessages.push("Basic Company Information"); 

}
if (!this.gstNumber) {
  toastMessages.push("GstAttachment");
  toastMessages.push("Additional KYC");
}  

  
    let arr: any = [];
    this.traderDetails.traderProfile.traderContactPersons.filter(x => {
      if (x.division.code == this.traderRefDataCodes.division.displayInformation || x.division.code == this.traderRefDataCodes.division.financeDivisionContactDetails || x.division.code == this.traderRefDataCodes.division.communicationAddress) {
        arr.push(x.division.code);
      }
    })

    if (arr.length !== 3) {
      this.loader = false;
    
      if (!arr.includes(this.traderRefDataCodes.division.displayInformation)) {
        toastMessages.push("Display Information");
       
      }
    
      if (!arr.includes(this.traderRefDataCodes.division.financeDivisionContactDetails)) {
        toastMessages.push("Finance Division Contact Details");
       
      }
    
      if (!arr.includes(this.traderRefDataCodes.division.communicationAddress)) {
        toastMessages.push("Communication Address");
        
      count = 1;
    }
    if(this.warehouseDetails.length == 0 || this.warehouseDetails === ""){
      toastMessages.push("Warehouse Information");
      this.loader = false;
       count = 1;
    }
    
    }
  // Check if there are toast messages
if (toastMessages.length > 0) {
  this.loader = false;
  count = 1;
  console.log(toastMessages,"hi")

   const modalRef = this.modalService.open(this.yourModalContent, {
      centered: true, // Centers the modal vertically and horizontally
      backdrop: 'static', // Prevents closing the modal by clicking outside
    });

  this.service.dataArray = toastMessages
  
  
  
}


    // if (arr.length != 3) {
    //   this.loader = false;
    //   this.toastr.error('Please update display information');
    //   
    // }

    if (count == 0) {
      if (this.appLanguage == 'ENG-IN')
      {
        this.msmetype();
        this.downloadPDFIndia();
      }
      else if (this.appLanguage == 'ENG-US')
        this.downloadPDFUS();
    }
   
  }


msmetype()
{

  if (this.traderDetails.traderProfile.msmeType != undefined) {
    this.service
      .getTraderGREFDataByDataCode(
        this.traderDetails.traderProfile.msmeType.code,
        sessionStorage["auth_token"]
      )
      .subscribe( (msmeres) => {
        this.selectedmsmeType= msmeres.labels[0].text; });
      }
      else{

        this.selectedmsmeType="";
      }
}

  downloadPDFIndia() {
    this.loader = true;
    this.traderProfile = new jsPDF("p", "px", "a4", true); //Using true for compressing the file size

    const pdfWidth = this.traderProfile.internal.pageSize.getWidth();
    const pdfHeight = this.traderProfile.internal.pageSize.getHeight();


    let imagePropsAddressProof: any;
    let pdfHeightAddressProof: any;
    let imagePropsMSME: any;
    let pdfHeightMSME: any;
    let imagePropsBankIdProof: any;
    let pdfHeightBankIdProof: any;
    let imagePropsKYCAuthorizedSignatory: any;
    let imagePropsKYCGst: any;
    let pdfHeightKYCAuthorizedSignatory: any;
    let pdfGSTheight: any;
    let imagePropsattachedKYCTan: any;
    let pdfHeightattachedKYCTan: any;

    let KYCwidth: any;
    let addressProofWidth: any;
    let MSMEwidth: any;
    let bankIdProofwidth: any;
    let KYCAuthorizedSignatorywidth: any;
    let KYCGstWidth: any


    //Keeping the original aspect ratio of images

    //console.log('Width', this.traderProfile.internal.pageSize.getWidth(), 'Height', this.traderProfile.internal.pageSize.getHeight())

    if (this.attachedAddressProof) {
      imagePropsAddressProof = this.traderProfile.getImageProperties(this.attachedAddressProof);

      let aspectRatio = imagePropsAddressProof.width / imagePropsAddressProof.height;
      // pdfHeightAddressProof = (imagePropsAddressProof.width < 438 ? imagePropsAddressProof.width : 438) * (imagePropsAddressProof.height / imagePropsAddressProof.width);
      // // addressProofWidth = 438 < imagePropsAddressProof.width ? 438 : imagePropsAddressProof.width;
      // addressProofWidth = (imagePropsAddressProof.height < 600 ? imagePropsAddressProof.height : 600) * aspectRatio;

      if (438 > imagePropsAddressProof.width) {
        pdfHeightAddressProof = imagePropsAddressProof.width / aspectRatio;
        addressProofWidth = imagePropsAddressProof.width;
      }
      else {
        pdfHeightAddressProof = 438 / aspectRatio;
        addressProofWidth = 438;
        if (pdfHeightAddressProof > 580) pdfHeightAddressProof = 580
      }


      // let widthRatio = pdfWidth / imagePropsAddressProof.width;
      // let heightRatio = pdfHeight / imagePropsAddressProof.height;

      // let ratio = widthRatio > heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

      // pdfHeightAddressProof = imagePropsAddressProof.height * widthRatio;
      // addressProofWidth = imagePropsAddressProof.width * heightRatio;

      // pdfHeightAddressProof = (imagePropsAddressProof.height * (pdfWidth > imagePropsAddressProof.width ? imagePropsAddressProof.width : pdfWidth)) / imagePropsAddressProof.width;
    }

    if (this.attachedMSME) {
      imagePropsMSME = this.traderProfile.getImageProperties(this.attachedMSME);

      let aspectRatio = imagePropsMSME.width / imagePropsMSME.height;
      // pdfHeightMSME = (imagePropsMSME.width < 438 ? imagePropsMSME.width : 438) * (imagePropsMSME.height / imagePropsMSME.width);
      // MSMEwidth = (imagePropsMSME.height < 580 ? imagePropsMSME.height : 580) * aspectRatio;


      if (438 > imagePropsMSME.width) {
        pdfHeightMSME = imagePropsMSME.width / aspectRatio;
        MSMEwidth = imagePropsMSME.width;
      } else {
        pdfHeightMSME = 438 / aspectRatio;
        MSMEwidth = 438;
        if (pdfHeightMSME > 580) pdfHeightMSME = 580
      }
      // let widthRatio = pdfWidth / imagePropsMSME.width;
      // let heightRatio = pdfHeight / imagePropsMSME.height;

      // let ratio = widthRatio < heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

      // pdfHeightMSME = imagePropsMSME.height * ratio;
      // MSMEwidth = imagePropsMSME.width * ratio;

      // pdfHeightMSME = (imagePropsMSME.height * (pdfWidth < imagePropsMSME.width ? imagePropsMSME.width : pdfWidth)) / imagePropsMSME.width;
    }

    if (this.attachedBankIdProof) {
      imagePropsBankIdProof = this.traderProfile.getImageProperties(this.attachedBankIdProof);


      let aspectRatio = imagePropsBankIdProof.width / imagePropsBankIdProof.height;
      // pdfHeightBankIdProof = (imagePropsBankIdProof.width < 438 ? imagePropsBankIdProof.width : 438) * (imagePropsBankIdProof.height / imagePropsBankIdProof.width);
      // bankIdProofwidth = (imagePropsBankIdProof.height < 580 ? imagePropsBankIdProof.height : 580) * aspectRatio;

      // pdfHeightBankIdProof = 438 / aspectRatio;
      // bankIdProofwidth = 438 < imagePropsBankIdProof.width ? 438 : imagePropsBankIdProof.width;

      if (438 >= imagePropsBankIdProof.width) {
        pdfHeightBankIdProof = imagePropsBankIdProof.width / aspectRatio;
        bankIdProofwidth = imagePropsBankIdProof.width;
      }
      else {
        pdfHeightBankIdProof = 438 / aspectRatio;
        bankIdProofwidth = 438;
        if (pdfHeightBankIdProof > 580) pdfHeightBankIdProof = 580

      }


      // let widthRatio = pdfWidth / imagePropsBankIdProof.width;
      // let heightRatio = pdfHeight / imagePropsBankIdProof.height;

      // let ratio = widthRatio < heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

      // pdfHeightBankIdProof = imagePropsBankIdProof.height * ratio;
      // bankIdProofwidth = imagePropsBankIdProof.width * ratio;
      // pdfHeightBankIdProof = (imagePropsBankIdProof.height * (pdfWidth < imagePropsBankIdProof.width ? imagePropsBankIdProof.width : pdfWidth)) / imagePropsBankIdProof.width;
    }

    if (this.attachedKYCAuthorizedSignatory) {
      imagePropsKYCAuthorizedSignatory = this.traderProfile.getImageProperties(this.attachedKYCAuthorizedSignatory);



      let aspectRatio = imagePropsKYCAuthorizedSignatory.width / imagePropsKYCAuthorizedSignatory.height;
      // pdfHeightKYCAuthorizedSignatory = (imagePropsKYCAuthorizedSignatory.width < 438 ? imagePropsKYCAuthorizedSignatory.width : 438) * (imagePropsKYCAuthorizedSignatory.height / imagePropsKYCAuthorizedSignatory.width);
      // KYCAuthorizedSignatorywidth = (imagePropsKYCAuthorizedSignatory.height < 580 ? imagePropsKYCAuthorizedSignatory.height : 580) * aspectRatio;

      if (438 >= imagePropsKYCAuthorizedSignatory.width) {
        pdfHeightKYCAuthorizedSignatory = imagePropsKYCAuthorizedSignatory.width / aspectRatio;
        KYCAuthorizedSignatorywidth = imagePropsKYCAuthorizedSignatory.width;
      }
      else {
        pdfHeightKYCAuthorizedSignatory = 438 / aspectRatio;
        KYCAuthorizedSignatorywidth = 438;
        if (pdfHeightKYCAuthorizedSignatory > 580) pdfHeightKYCAuthorizedSignatory = 580

      }


      // let widthRatio = pdfWidth / imagePropsKYCAuthorizedSignatory.width;
      // let heightRatio = pdfHeight / imagePropsKYCAuthorizedSignatory.height;

      // let ratio = widthRatio < heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

      // pdfHeightKYCAuthorizedSignatory = imagePropsKYCAuthorizedSignatory.height * ratio;
      // KYCAuthorizedSignatorywidth = imagePropsKYCAuthorizedSignatory.width * ratio;
      // pdfHeightKYCAuthorizedSignatory = (imagePropsKYCAuthorizedSignatory.height * (pdfWidth < imagePropsKYCAuthorizedSignatory.width ? imagePropsKYCAuthorizedSignatory.width : pdfWidth)) / imagePropsKYCAuthorizedSignatory.width;
    }

    if (this.gstNumber) {
      imagePropsKYCGst = this.traderProfile.getImageProperties(this.attachedKYCAuthorizedSignatory);



      let aspectRatio = imagePropsKYCGst.width / imagePropsKYCGst.height;
      // pdfHeightKYCAuthorizedSignatory = (imagePropsKYCAuthorizedSignatory.width < 438 ? imagePropsKYCAuthorizedSignatory.width : 438) * (imagePropsKYCAuthorizedSignatory.height / imagePropsKYCAuthorizedSignatory.width);
      // KYCAuthorizedSignatorywidth = (imagePropsKYCAuthorizedSignatory.height < 580 ? imagePropsKYCAuthorizedSignatory.height : 580) * aspectRatio;

      if (438 >= imagePropsKYCGst.width) {
        pdfGSTheight = imagePropsKYCGst.width / aspectRatio;
        KYCGstWidth = imagePropsKYCGst.width;
      }
      else {
        pdfGSTheight = 438 / aspectRatio;
        KYCGstWidth = 438;
        if (pdfGSTheight > 580) pdfGSTheight = 580

      }


      // let widthRatio = pdfWidth / imagePropsKYCAuthorizedSignatory.width;
      // let heightRatio = pdfHeight / imagePropsKYCAuthorizedSignatory.height;

      // let ratio = widthRatio < heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

      // pdfHeightKYCAuthorizedSignatory = imagePropsKYCAuthorizedSignatory.height * ratio;
      // KYCAuthorizedSignatorywidth = imagePropsKYCAuthorizedSignatory.width * ratio;
      // pdfHeightKYCAuthorizedSignatory = (imagePropsKYCAuthorizedSignatory.height * (pdfWidth < imagePropsKYCAuthorizedSignatory.width ? imagePropsKYCAuthorizedSignatory.width : pdfWidth)) / imagePropsKYCAuthorizedSignatory.width;
    }

    if (this.attachedKYCTan) {
      imagePropsattachedKYCTan = this.traderProfile.getImageProperties(this.attachedKYCTan);


      let aspectRatio = imagePropsattachedKYCTan.width / imagePropsattachedKYCTan.height;
      // pdfHeightattachedKYCTan = (imagePropsattachedKYCTan.width < 438 ? imagePropsattachedKYCTan.width : 438) * (imagePropsattachedKYCTan.height / imagePropsattachedKYCTan.width);
      // KYCwidth = (imagePropsattachedKYCTan.height < 580 ? imagePropsattachedKYCTan.height : 580) * aspectRatio;
      if (438 >= imagePropsattachedKYCTan.width) {
        pdfHeightattachedKYCTan = imagePropsattachedKYCTan.width / aspectRatio;
        KYCwidth = imagePropsattachedKYCTan.width;
      }
      else {
        pdfHeightattachedKYCTan = 438 / aspectRatio;
        KYCwidth = 438;
        if (pdfHeightattachedKYCTan > 580) pdfHeightattachedKYCTan = 580
      }

      // let widthRatio = pdfWidth / imagePropsattachedKYCTan.width;
      // let heightRatio = pdfHeight / imagePropsattachedKYCTan.height;

      // let ratio = widthRatio > heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

      // pdfHeightattachedKYCTan = imagePropsattachedKYCTan.height * ratio;
      // KYCwidth = imagePropsattachedKYCTan.width * ratio;

      // pdfHeightattachedKYCTan = (imagePropsattachedKYCTan.height * (pdfWidth > imagePropsattachedKYCTan.width ? imagePropsattachedKYCTan.width : pdfWidth)) / imagePropsattachedKYCTan.width;

      // console.log('pdfHeightattachedKYCTan', pdfHeightattachedKYCTan);
      // if (pdfHeightattachedKYCTan.height > 580)
      //   pdfHeightattachedKYCTan = 580;

    }

    // const imgProps= this.traderProfile.getImageProperties(imgData);

    // this.traderProfile.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);


    // var elementHTML = $('#content').html();
    // var eventHandlers = {
    //   '#elementH': function (element, renderer) {
    //     return true;
    //   }
    // }
    // this.traderProfile.fromHTML(elementHTML, 15, 15, {
    //   'width': 170,
    //   'elementHandlers': eventHandlers
    // })
    // //Save the PDF
    // this.traderProfile.save('traderProfile.pdf');


    this.service
      .getTraderGREFDataByDataCode(
        this.traderDetails.traderProfile.typeOfEntity.code,
        sessionStorage["auth_token"]
      )
      .subscribe(async(res) =>   {
        this.enityType = res.labels[0].text;
        this.traderProfile.setFontSize(20);
        this.traderProfile.setTextColor(242, 97, 1);
        this.traderProfile.addImage(this.traderIcon, 15, 10, 20, 20);
        this.traderProfile.text(this.appLabel.traderDetails.heading, 40, 25);
        this.traderProfile.setFontSize(14);
        this.traderProfile.setTextColor(0, 0, 0);
        this.traderProfile.text(
          this.appLabel.traderDetails.name +
          " : " +
          this.traderDetails.traderProfile.firstName +
          " " +
          this.traderDetails.traderProfile.lastName,
          20,
          60
        );
        if (sessionStorage["mobile"] != undefined) {
          this.traderProfile.text(
            this.appLabel.traderDetails.contact + " : " + sessionStorage["mobile"],
            20,
            80
          );
        }
        this.traderDetails.traderProfile.contacts.filter((x) => {
          if (x.contactType.code == "CONTACT_TYPE.MAIL") {
            this.traderProfile.text(
              this.appLabel.traderDetails.email + " : " + x.contactValue,
              20,
              100
            );
          }
        });
        this.traderProfile.text(
          this.appLabel.traderDetails.entityName + " : " + this.traderDetails.traderProfile.entityName,
          20,
          120
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.entityType + " : " + this.enityType,
          20,
          140
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.gst + " : " + this.traderDetails.traderProfile.gstNumber,
          20,
          160
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.pan + " : " + this.traderDetails.traderProfile.panNumber,
          20,
          180
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.msmeUAMNumber +
          " : " +
          this.traderDetails.traderProfile.msmeUAMNumber,
          20,
          200
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.cin + " : " + this.traderDetails.traderProfile.cin,
          20,
          220
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.address +
          " : " +
          this.traderDetails.traderProfile.address.addressLine1 +
          "  " +
          this.traderDetails.traderProfile.address.addressLine2,
          20,
          240
        );
        //this.traderProfile.text(
        //  this.appLabel.traderDetails.city + " : " + this.traderDetails.traderProfile.address.city.name,
        //  20,
        //  260
        //);
        this.traderProfile.text(
          this.appLabel.traderDetails.block + " : " + this.traderDetails.traderProfile.address.block.name,
          20,
          260
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.district + " : " + this.traderDetails.traderProfile.address.district.name,
          20,
          280
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.state + " : " + this.traderDetails.traderProfile.address.state.name,
          20,
          300
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.country +
          " : " +
          this.traderDetails.traderProfile.address.country.name,
          20,
          320
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.landmark +
          " : " +
          this.traderDetails.traderProfile.address.landmark,
          20,
          340
        );
        this.traderProfile.text(
          this.appLabel.traderDetails.pinCode + " : " + this.traderDetails.traderProfile.address.pinCode,
          20,
          360
        );
        if (this.traderDetails.traderProfile.traderBankAccounts.length > 0) {
          this.traderProfile.text(
            this.appLabel.traderDetails.bankName +
            " : " +
            this.traderDetails.traderProfile.traderBankAccounts[0].bankName,
            20,
            380
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.accountHolderName +
            " : " +
            this.traderDetails.traderProfile.traderBankAccounts[0]
              .accountHolderName,
            20,
            400
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.accountNumber +
            " : " +
            this.traderDetails.traderProfile.traderBankAccounts[0]
              .accountNumber,
            20,
            420
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.ifsc +
            " : " +
            this.traderDetails.traderProfile.traderBankAccounts[0].ifsc,
            20,
            440
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.branchName +
            " : " +
            this.traderDetails.traderProfile.traderBankAccounts[0].branchName,
            20,
            460
          );
        }
        else {
          this.loader = false;
          //this.toastr.showError('Please update Bank Details');
        }
        this.traderProfile.text(
          this.appLabel.traderDetails.accountType + " : " + this.traderDetails.traderProfile.traderBankAccounts[0].accountType.labels[0].text,
          20,
          480
        )

       

       
          this.traderProfile.text(
            this.appLabel.traderDetails.msmeType + " : " + this.selectedmsmeType,
            20,
            500 );

              // if (this.traderDetails.traderProfile.traderBrands.length > 0) {
              //   let brandLength: any;
              //   brandLength = this.traderDetails.traderProfile.traderBrands.length;
              //   if (brandLength == 1) {
              //     this.traderProfile.text("Brand Name" + ' : ' + accountType, 20, 300);
              //     this.traderDetails.traderProfile.traderBrands[0].brandName;
              //   }
              // }
              if (this.traderDetails.traderProfile.traderBrands.length > 0) {
                this.traderProfile.text(
                  this.appLabel.traderDetails.brandName +
                  " : " +
                  this.traderDetails.traderProfile.traderBrands[0].brandName,
                  20,
                  520
                );
                if( this?.traderDetails?.traderProfile?.traderBrands[0]
                  ?.brandOwnerShip?.labels[0].text !== undefined)
                  {

                 
                this.traderProfile.text(
                  this?.appLabel?.traderDetails.brandOwnership +
                  " : " +
                  this?.traderDetails?.traderProfile?.traderBrands[0]
                    ?.brandOwnerShip?.labels[0].text,
                  20,
                  540
                );
                  }
              }
              this.traderProfile.addPage();
              if (
                this.traderDetails.traderProfile.traderContactPersons.length >
                0
              ) {
                this.traderDetails.traderProfile.traderContactPersons.filter(
                  (x) => {
                    // if (x.division.code == this.traderRefDataCodes.division.deliveryManager) {
                    //   this.traderProfile.setFontSize(20);
                    //   this.traderProfile.setTextColor(242, 97, 1);
                    //   this.traderProfile.addImage(
                    //     this.deliveryManagerIcon,
                    //     15,
                    //     10,
                    //     20,
                    //     20
                    //   );
                    //   this.traderProfile.text(
                    //     this.appLabel.deliveryManagerDetails.heading,
                    //     40,
                    //     25
                    //   );
                    //   this.traderProfile.setFontSize(14);
                    //   this.traderProfile.setTextColor(0, 0, 0);
                    //   this.traderProfile.text(
                    //     this.appLabel.deliveryManagerDetails.name + " : " + x.firstName,
                    //     20,
                    //     45
                    //   );
                    //   x.personContacts.filter((y) => {
                    //     if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                    //       this.traderProfile.text(
                    //         this.appLabel.deliveryManagerDetails.contact + " : " + y.contactValue,
                    //         20,
                    //         65
                    //       );
                    //     else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                    //       this.traderProfile.text(
                    //         this.appLabel.deliveryManagerDetails.email + " : " + y.contactValue,
                    //         20,
                    //         85
                    //       );
                    //   });
                    // }
                    if (x.division.code == this.traderRefDataCodes.division.communicationAddress) {
                      this.traderProfile.setFontSize(20);
                      this.traderProfile.setTextColor(242, 97, 1);
                      this.traderProfile.addImage(
                        this.communicationIcon,
                        15,
                        110,
                        20,
                        20
                      );
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.heading,
                        40,
                        125
                      );
                      this.traderProfile.setFontSize(14);
                      this.traderProfile.setTextColor(0, 0, 0);
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.name + " : " + x.firstName,
                        20,
                        145
                      );
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.signatoryName + " : " + x.signatoryName,
                        20,
                        165
                      );

                      x.personContacts.filter((y) => {
                        if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                          this.traderProfile.text(
                            this.appLabel.communicationAddressDetails.contact + " : " + y.contactValue,
                            20,
                            185
                          );
                        else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                          this.traderProfile.text(
                            this.appLabel.communicationAddressDetails.email + " : " + y.contactValue,
                            20,
                            205
                          );
                      });
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.state + " : " + x.personAddress.state.name,
                        20,
                        225
                      );
                      //this.traderProfile.text(
                      //  this.appLabel.communicationAddressDetails.city + " : " + x.personAddress.city.name,
                      //  20,
                      //  245
                      //);
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.block + " : " + x.personAddress.block.name,
                        20,
                        245
                      );
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.district + " : " + x.personAddress.district.name,
                        20,
                        265
                      );

                    }
                    // if (
                    //   x.division.code == this.traderRefDataCodes.division.customerServiceManager
                    // ) {
                    //   this.traderProfile.setFontSize(20);
                    //   this.traderProfile.setTextColor(242, 97, 1);
                    //   this.traderProfile.addImage(
                    //     this.customerServiceManagerIcon,
                    //     15,
                    //     290,
                    //     20,
                    //     20
                    //   );
                    //   this.traderProfile.text(
                    //     this.appLabel.customerServiceDetails.heading,
                    //     40,
                    //     305
                    //   );
                    //   this.traderProfile.setFontSize(14);
                    //   this.traderProfile.setTextColor(0, 0, 0);
                    //   this.traderProfile.text(
                    //     this.appLabel.customerServiceDetails.name + " : " + x.firstName,
                    //     20,
                    //     325
                    //   );
                    //   x.personContacts.filter((y) => {
                    //     if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                    //       this.traderProfile.text(
                    //         this.appLabel.customerServiceDetails.contact + " : " + y.contactValue,
                    //         20,
                    //         345
                    //       );
                    //     else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                    //       this.traderProfile.text(
                    //         this.appLabel.customerServiceDetails.email + " : " + y.contactValue,
                    //         20,
                    //         365
                    //       );
                    //   });
                    // }
                    // if (x.division.code == this.traderRefDataCodes.division.financeManager) {
                    //   this.traderProfile.setFontSize(20);
                    //   this.traderProfile.setTextColor(242, 97, 1);
                    //   this.traderProfile.addImage(
                    //     this.financeDivisionMangerIcon,
                    //     15,
                    //     390,
                    //     20,
                    //     20
                    //   );
                    //   this.traderProfile.text(
                    //     this.appLabel.financeManagerDetails.heading,
                    //     40,
                    //     405
                    //   );
                    //   this.traderProfile.setFontSize(14);
                    //   this.traderProfile.setTextColor(0, 0, 0);
                    //   this.traderProfile.text(
                    //     this.appLabel.financeManagerDetails.name + " : " + x.firstName,
                    //     20,
                    //     425
                    //   );
                    //   x.personContacts.filter((y) => {
                    //     if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                    //       this.traderProfile.text(
                    //         this.appLabel.financeManagerDetails.contact + " : " + y.contactValue,
                    //         20,
                    //         445
                    //       );
                    //     else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                    //       this.traderProfile.text(
                    //         this.appLabel.financeManagerDetails.email + " : " + y.contactValue,
                    //         20,
                    //         465
                    //       );
                    //   });
                    // }
                    if (
                      x.division.code ==
                      this.traderRefDataCodes.division.financeDivisionContactDetails
                    ) {
                      this.traderProfile.setFontSize(20);
                      this.traderProfile.setTextColor(242, 97, 1);
                      this.traderProfile.addImage(
                        this.financeDivisionMangerIcon,
                        15,
                        490,
                        20,
                        20
                      );
                      this.traderProfile.text(
                        this.appLabel.financeDivisionContactDetails.heading,
                        40,
                        505
                      );
                      this.traderProfile.setFontSize(14);
                      this.traderProfile.setTextColor(0, 0, 0);
                      this.traderProfile.text(
                        this.appLabel.financeDivisionContactDetails.name + " : " + x.firstName,
                        20,
                        525
                      );
                      x.personContacts.filter((y) => {
                        if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                          this.traderProfile.text(
                            this.appLabel.financeDivisionContactDetails.contact + " : " + y.contactValue,
                            20,
                            545
                          );
                        else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                          this.traderProfile.text(
                            this.appLabel.financeDivisionContactDetails.email + " : " + y.contactValue,
                            20,
                            565
                          );
                      });
                    }

                  }
                );
                this.traderProfile.addPage();
              }
              //Adding other details
              this.traderDetails.traderProfile.traderContactPersons.filter(
                (x) => {
                  // if (x.division.code == this.traderRefDataCodes.division.ceo) {
                  //   this.traderProfile.setFontSize(20);
                  //   this.traderProfile.setTextColor(242, 97, 1);
                  //   this.traderProfile.addImage(this.ceoIcon, 15, 10, 20, 20);
                  //   this.traderProfile.text(this.appLabel.ceoDetails.heading, 40, 25);
                  //   this.traderProfile.setFontSize(14);
                  //   this.traderProfile.setTextColor(0, 0, 0);
                  //   this.traderProfile.text(
                  //     this.appLabel.ceoDetails.name + " : " + x.firstName,
                  //     20,
                  //     45
                  //   );
                  //   x.personContacts.filter((y) => {
                  //     if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                  //       this.traderProfile.text(
                  //         this.appLabel.ceoDetails.contact + " : " + y.contactValue,
                  //         20,
                  //         65
                  //       );
                  //     else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                  //       this.traderProfile.text(
                  //         this.appLabel.ceoDetails.email + " : " + y.contactValue,
                  //         20,
                  //         85
                  //       );
                  //   });
                  // }
                  if (x.division.code == this.traderRefDataCodes.division.displayInformation) {
                    this.traderProfile.setFontSize(20);
                    this.traderProfile.setTextColor(242, 97, 1);
                    this.traderProfile.addImage(
                      this.infoIcon,
                      15,
                      110,
                      20,
                      20
                    );
                    this.traderProfile.text(this.appLabel.displayInformation.heading, 40, 125);
                    this.traderProfile.setFontSize(14);
                    this.traderProfile.setTextColor(0, 0, 0);
                    this.traderProfile.text(
                      this.appLabel.displayInformation.name + " : " + this.traderDetails.traderProfile.organisation,
                      20,
                      145
                    );
                    x.personContacts.filter((y) => {
                      if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                        this.traderProfile.text(
                          this.appLabel.displayInformation.contact + " : " + y.contactValue,
                          20,
                          165
                        );
                      else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                        this.traderProfile.text(
                          this.appLabel.displayInformation.email + " : " + y.contactValue,
                          20,
                          185
                        );
                    });
                  }
                }
              );

              // //Warehouse Details
              // if (this.warehouseDetails && this.warehouseDetails[0].workNode.workNodeContact != undefined) {
                //this.traderProfile.addPage();
                if(this.warehouseDetails && this.warehouseDetails.length > 0){
                  if (this.warehouseDetails[0].workNode && this.warehouseDetails[0].workNode.workNodeContact !== undefined) {
                    // Your code here
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(
                  this.deliveryManagerIcon,
                  15,
                  220,
                  20,
                  20
                );
                this.traderProfile.text(this.appLabel.warehouseInformation.heading, 40, 235);
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);

                this.warehouseDetails.forEach((o, i) => {
                  let pageHeightInPx = 0;
                  if ((i != 0) && (i % 2 != 0)) {
                    this.traderProfile.addPage();
                    pageHeightInPx = 0;
                  }
                  else if (i == 0) {
                    pageHeightInPx = 1;
                  }
                  else {
                    pageHeightInPx = 1.5;
                  }

                  this.traderProfile.text(
                    this.appLabel.warehouseInformation.name + " : " + this.warehouseDetails[i].centerName,
                    20,
                    60 + (pageHeightInPx * 200)
                  );
                  //console.log('this.warehouseDetails', this.warehouseDetails)
                  this.warehouseDetails[i].workNode.workNodeContact.filter(warehouse => console.log('x-1', warehouse));
                  this.warehouseDetails[i].workNode.workNodeContact.filter((warehouse) => {
                    if (warehouse.contactType.code == "CONTACT_TYPE.MOBILE")
                      this.traderProfile.text(
                        this.appLabel.warehouseInformation.contact + " : " + warehouse.contactValue,
                        20,
                        80 + (pageHeightInPx * 200)
                      );
                    else if (warehouse.contactType.code == "CONTACT_TYPE.MAIL")
                      this.traderProfile.text(
                        this.appLabel.warehouseInformation.email + " : " + warehouse.contactValue,
                        20,
                        100 + (pageHeightInPx * 200)
                      );
                    else if (warehouse.contactType.code == "CONTACT_TYPE.PERSON_NAME")
                      this.traderProfile.text(
                        this.appLabel.warehouseInformation.warehouseManager + " : " + warehouse.contactValue,
                        20,
                        120 + (pageHeightInPx * 200)
                      );
                  });
                  this.traderProfile.text(
                    this.appLabel.warehouseInformation.address + " : " + this.warehouseDetails[i].workNode.workNodeAddress.addressLine1,
                    20,
                    140 + (pageHeightInPx * 200)
                  );
                  if (this.warehouseDetails[i].workNode.workNodeAddress.block) {
                    this.traderProfile.text(
                      this.appLabel.warehouseInformation.block + " : " + this.warehouseDetails[i].workNode.workNodeAddress.block.name,
                      20,
                      160 + (pageHeightInPx * 200)
                    );
                  }
                  if (this.warehouseDetails[i].workNode.workNodeAddress.district) {
                    this.traderProfile.text(
                      this.appLabel.warehouseInformation.district + " : " + this.warehouseDetails[i].workNode.workNodeAddress.district.name,
                      20,
                      180 + (pageHeightInPx * 200)
                    );
                  }
                  this.traderProfile.text(
                    this.appLabel.warehouseInformation.state + " : " + this.warehouseDetails[i].workNode.workNodeAddress.state.name,
                    20,
                    200 + (pageHeightInPx * 200)
                  );
                  //this.traderProfile.text(
                  //  this.appLabel.warehouseInformation.city + " : " + this.warehouseDetails[i].workNode.workNodeAddress.city.name,
                  //  20,
                  //  375 + (i * 150)
                  //);
                  this.traderProfile.text(
                    this.appLabel.warehouseInformation.pinCode + " : " + this.warehouseDetails[i].workNode.workNodeAddress.pinCode,
                    20,
                    220 + (pageHeightInPx * 200)
                  );
                })
              }
            }
              
              this.traderProfile.addPage();

              //Attachments
              // if (this.attachedMSME) {
              if (this.attachedBankIdProof) {
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(
                  this.attachmentIcon,
                  15,
                  10,
                  20,
                  20
                );
                this.traderProfile.text(this.appLabel.attachments.bank, 40, 25);
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);
                // this.getAttachment();
                this.traderProfile.addImage(
                  // this.attachedMSME,
                  this.attachedBankIdProof,
                  5,
                  40,
                  // 300,
                  // 150
                  // width - 40,
                  // height - 150
                  // pdfWidth > imagePropsBankIdProof.width ? imagePropsBankIdProof.width : pdfWidth, pdfHeightBankIdProof
                  bankIdProofwidth, pdfHeightBankIdProof

                );
                this.traderProfile.addPage();
              }
              // if (this.attachedBankIdProof) {
              //   this.traderProfile.setFontSize(20);
              //   this.traderProfile.setTextColor(242, 97, 1);
              //   this.traderProfile.addImage(
              //     this.attachmentIcon,
              //     15,
              //     240,
              //     20,
              //     20
              //   );
              //   this.traderProfile.text(this.appLabel.attachments.bank, 40, 255);
              //   this.traderProfile.setFontSize(14);
              //   this.traderProfile.setTextColor(0, 0, 0);
              //   this.traderProfile.addImage(
              //     this.attachedBankIdProof,
              //     60,
              //     270,
              //     300,
              //     150
              //   );
              // }
              if (this.attachedKYCTan) {
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(
                  this.attachmentIcon,
                  15,
                  10,
                  20,
                  20
                );
                // this.traderProfile.text(this.appLabel.attachments.tanNumber, 40, 475);
                this.traderProfile.text(this.appLabel.attachments.tanNumber, 40, 25);
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);
                this.traderProfile.addImage(
                  this.attachedKYCTan,
                  5,
                  40,
                  // 300,
                  // 150
                  // width - 40,
                  // height - 150
                  // pdfWidth > imagePropsattachedKYCTan.width ? imagePropsattachedKYCTan.width : pdfWidth, pdfHeightattachedKYCTan
                  KYCwidth, pdfHeightattachedKYCTan

                );
                this.traderProfile.addPage();
              }
              // if(this.attachedBankIdProof){
              if (this.attachedMSME) {
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(
                  this.attachmentIcon,
                  15,
                  10,
                  20,
                  20
                );

                this.traderProfile.text(this.appLabel.attachments.msme, 40, 25);
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);
                this.traderProfile.addImage(
                  this.attachedMSME,
                  // this.attachedBankIdProof,
                  5,
                  40,
                  // 300,
                  // 150
                  // width - 40,
                  // height - 150
                  // pdfWidth > imagePropsMSME.width ? imagePropsMSME.width : pdfWidth, pdfHeightMSME
                  MSMEwidth, pdfHeightMSME
                );
                this.traderProfile.addPage();
              }
              if (this.attachedKYCAuthorizedSignatory) {
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(
                  this.attachmentIcon,
                  15,
                  10,
                  20,
                  20
                );
                this.traderProfile.text(
                  this.appLabel.attachments.kyc,
                  40, 25
                );
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);
                this.traderProfile.addImage(
                  this.attachedKYCAuthorizedSignatory,
                  5,
                  40,
                  // 300,
                  // 150
                  // width - 40,
                  // height - 150
                  // pdfWidth > imagePropsKYCAuthorizedSignatory.width ? imagePropsKYCAuthorizedSignatory.width : pdfWidth, pdfHeightKYCAuthorizedSignatory
                  KYCAuthorizedSignatorywidth, pdfHeightKYCAuthorizedSignatory
                );
               
              }

             

              if (this.attachedAddressProof) {
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(
                  this.attachmentIcon,
                  15,
                  10,
                  20,
                  20
                );
                this.traderProfile.text(this.appLabel.attachments.addressProof, 40, 25);
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);
                this.traderProfile.addImage(
                  this.attachedAddressProof,
                  5,
                  40,
                  // 300,
                  // 150
                  // width - 40,
                  // height - 150
                  // pdfWidth > imagePropsAddressProof.width ? imagePropsAddressProof.width : pdfWidth, pdfHeightAddressProof
                  addressProofWidth, pdfHeightAddressProof
                );
              }
              // this.attachedBankIdProof = this.getAttachment()
              // pdf.addImage(img, 'png', 10, 78, 12, 15)
              
              if (this.gstNumber) 
              {

                if(this.gstNumber.startsWith('data:image'))
                {
                  this.traderProfile.addPage();
                  this.gstNumber1 = new Image();
                   this.gstNumber1.src =this.gstNumber; 

                  this.traderProfile.setFontSize(20);
                  this.traderProfile.setTextColor(242, 97, 1);
                  this.traderProfile.addImage(
                    this.attachmentIcon,
                    15,
                    10,
                    20,
                    20
                  );
                  this.traderProfile.text(
                    "GST KYC Attachment",
                    40, 25
                  );
                  this.traderProfile.setFontSize(14);
                  this.traderProfile.setTextColor(0, 0, 0);
                  this.traderProfile.addImage(
                    this.gstNumber1,
                    5,
                    40,
                    // 300,
                    // 150
                    // width - 40,
                    // height - 150
                    // pdfWidth > imagePropsKYCAuthorizedSignatory.width ? imagePropsKYCAuthorizedSignatory.width : pdfWidth, pdfHeightKYCAuthorizedSignatory
                    KYCGstWidth, pdfGSTheight
                  );
                  this.loader = false;
                  
                  const byteArray = this.traderProfile.output('arraybuffer');
                  const uintArray = new Uint8Array(byteArray);
                  const response = await fetch(this.gstNumber).then((res) => res.arrayBuffer());
                
                  const downloadedPDF = await PDFDocument.load(uintArray);
                  const imagepdf = await downloadedPDF.save();
                  //const pdf = this.traderProfile.save();
                 const modalOptions: NgbModalOptions = {
                  size: 'lg',
                  backdrop: 'static',
                  keyboard: false,
                 
                  };
            
                      const modalRef = this.modalService.open(this.pdfview, modalOptions);
                      const pdfContainer = document.getElementById('pdfContainer');
                    if (pdfContainer) {
                      //const mergedPdfBytes = await mergedPdf.save();
                      const pdfBlob = new Blob([imagepdf], { type: 'application/pdf' });
                      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
                    
                      // Set the inner HTML of pdfContainer
                      pdfContainer.innerHTML = '';
                    
                      // Create a container div to hold the PDF iframe
                      const containerDiv = document.createElement('div');
                      containerDiv.style.height = '500px'; // Adjust the height as needed
                      containerDiv.style.position = 'relative';
                    
                      const pdfIframe = document.createElement('iframe');
                      pdfIframe.setAttribute('src', pdfBlobUrl);
                      pdfIframe.setAttribute('width', '100%');
                      pdfIframe.setAttribute('height', '100%');
                    
                      containerDiv.appendChild(pdfIframe);
                    
                      // Append the container div to the pdfContainer
                      pdfContainer.appendChild(containerDiv);
                    
                      // Add a download button below the PDF preview
                      const downloadButton = document.createElement('button');
                      downloadButton.className = 'btn btn-primary mt-3'; // Adjust the margin as needed
                      downloadButton.innerHTML = 'Download PDF';
                    
                      downloadButton.addEventListener('click', () => {
                        // const blob = new Blob([imagepdf], { type: 'application/pdf' });
                        // saveAs(blob, 'traderProfile.pdf');
                        this.traderProfile.save("traderProfile.pdf");
                        modalRef.close();
                      });
                    
                      // Append the download button to the pdfContainer
                      pdfContainer.appendChild(downloadButton);
                    }
                    
              }
               
              // else{
               
              //   const byteArray = this.traderProfile.output('arraybuffer');
              //   const uintArray = new Uint8Array(byteArray);
              //   // const arr2 = this.traderProfile.output(ArrayBuffer);
              //   // const response = await this.http.get(this.gstNumber, { responseType: 'arraybuffer' }).toPromise();
              //   const response = await fetch(this.gstNumber).then(res => res.arrayBuffer());
                
              //   const downloadedPDF = await PDFDocument.load(uintArray);
                
            
                
              //   const otherPDF = await PDFDocument.load(response);
              //   const mergedPdf = await PDFDocument.create();
              //   const copiedPagesA = await mergedPdf.copyPages(downloadedPDF, downloadedPDF.getPageIndices());
              //   copiedPagesA.forEach((page) => mergedPdf.addPage(page));
              //  const copiedPagesB = await mergedPdf.copyPages(otherPDF, otherPDF.getPageIndices());
              //  copiedPagesB.forEach((page) => mergedPdf.addPage(page));
              //  const mergedPdfFile = await mergedPdf.save();
              
              //  this.loader = false;
              //   const blob = new Blob([mergedPdfFile ], { type: 'application/pdf' });
                
              //   saveAs(blob, 'merged.pdf');
  
           
              // }
              else
              {
              const byteArray = this.traderProfile.output('arraybuffer');
              const uintArray = new Uint8Array(byteArray);
              const response = await fetch(this.gstNumber).then((res) => res.arrayBuffer());
            
              const downloadedPDF = await PDFDocument.load(uintArray);
              const imagepdf = await downloadedPDF.save();
              const otherPDF = await PDFDocument.load(response);
            
              // Add text to each page of the otherPDF
              const pages = otherPDF.getPages();
                const font = await otherPDF.embedFont(StandardFonts.Helvetica);

                const targetPageNumber = 1; 

                pages.forEach(async (page, pageNumber) => {
                  if (pageNumber === targetPageNumber - 1) {
                    const text = 'GST KYC Attachment';
                    const image = await otherPDF.embedPng(this.attachmentIcon);

                    page.drawImage(image, {
                      x: 15,
                      y: 823,
                      width: 15,
                      height: 15,
                    });

                    page.drawText(text, {
                      x: 30,
                      y: 823,
                     
                    
                      size: 15,
                      color: rgb(0.949, 0.38, 0.004),
                     
                    });
                  }
                
              });
            
              // Merge downloadedPDF and otherPDF
              const mergedPdf = await PDFDocument.create();
              const copiedPagesA = await mergedPdf.copyPages(downloadedPDF, downloadedPDF.getPageIndices());
              copiedPagesA.forEach((page) => mergedPdf.addPage(page));
              const copiedPagesB = await mergedPdf.copyPages(otherPDF, otherPDF.getPageIndices());
              copiedPagesB.forEach((page) => mergedPdf.addPage(page));
              const mergedPdfFile = await mergedPdf.save();
              this.loader = false;
              const modalOptions: NgbModalOptions = {
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
               
              };
          
              const modalRef = this.modalService.open(this.pdfview, modalOptions);

              
              const pdfContainer = document.getElementById('pdfContainer');
              if (pdfContainer) {
                const mergedPdfBytes = await mergedPdf.save();
                const pdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
                const pdfBlobUrl = URL.createObjectURL(pdfBlob);
              
                // Set the inner HTML of pdfContainer
                pdfContainer.innerHTML = '';
              
                // Create a container div to hold the PDF iframe
                const containerDiv = document.createElement('div');
                containerDiv.style.height = '500px'; // Adjust the height as needed
                containerDiv.style.position = 'relative';
              
                const pdfIframe = document.createElement('iframe');
                pdfIframe.setAttribute('src', pdfBlobUrl);
                pdfIframe.setAttribute('width', '100%');
                pdfIframe.setAttribute('height', '100%');
              
                containerDiv.appendChild(pdfIframe);
              
                // Append the container div to the pdfContainer
                pdfContainer.appendChild(containerDiv);
              
                // Add a download button below the PDF preview
                const downloadButton = document.createElement('button');
                downloadButton.className = 'btn btn-primary mt-3'; // Adjust the margin as needed
                downloadButton.innerHTML = 'Download PDF';
              
                downloadButton.addEventListener('click', () => {
                  const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
                  saveAs(blob, 'traderProfile.pdf');
                  modalRef.close();
                });
              
                // Append the download button to the pdfContainer
                pdfContainer.appendChild(downloadButton);
              }
              
             
            }
               this.loader = false;
              //  this.traderProfile.save("traderProfile.pdf")

            }
          // }, 
          //   err => {
          //     this.loader = false;
          //     this.toastr.error('Please update gst');
          //   }
                 
        //       },
        //     );

        // }
       




      }
      , err => {
        this.loader = false;
        // this.toastr.error('Please update all mandatory fields');
     
     
      });



    // this.traderProfile.text(this.traderDetails.firstName + ' ' + this.traderDetails.lastName, 60, 25);
    // this.traderProfile.text(this.traderDetails.entityName, 5, 40);
    // this.traderProfile.text('GST Number- ' + this.traderDetails.gstNumber, 5, 55);
    // this.traderProfile.text('GST Number- ' + this.traderDetails.gstNumber, 5, 70);

    // this.traderProfile.addPage();
    // this.traderProfile.text('Do you like that?', 20, 20);

    // Save the PDF
    // this.traderProfile.save('Test.pdf');
  }
  downloadPDFUS() {
    {
      this.loader = true;
      this.traderProfile = new jsPDF("p", "px", "a4", true); //Using true for compressing the file size

      const pdfWidth = this.traderProfile.internal.pageSize.getWidth();
      const pdfHeight = this.traderProfile.internal.pageSize.getHeight();

      let imagePropsAddressProof: any;
      let pdfHeightAddressProof: any;
      let imagePropsBankIdProof: any;
      let pdfHeightBankIdProof: any;
      let imagePropsKYCAuthorizedSignatory: any;
      let pdfHeightKYCAuthorizedSignatory: any;
      let imagePropsattachedKYCTan: any;
      let pdfHeightattachedKYCTan: any;



      let KYCwidth: any;
      let addressProofWidth: any;
      let bankIdProofwidth: any;
      let KYCAuthorizedSignatorywidth: any;

      //Keeping the original aspect ratio of images

      //console.log('Width', this.traderProfile.internal.pageSize.getWidth(), 'Height', this.traderProfile.internal.pageSize.getHeight())

      if (this.attachedAddressProof) {
        imagePropsAddressProof = this.traderProfile.getImageProperties(this.attachedAddressProof);

        let aspectRatio = imagePropsAddressProof.width / imagePropsAddressProof.height;
        // pdfHeightAddressProof = (imagePropsAddressProof.width < 438 ? imagePropsAddressProof.width : 438) * (imagePropsAddressProof.height / imagePropsAddressProof.width);
        // // addressProofWidth = 438 < imagePropsAddressProof.width ? 438 : imagePropsAddressProof.width;
        // addressProofWidth = (imagePropsAddressProof.height < 600 ? imagePropsAddressProof.height : 600) * aspectRatio;

        if (438 > imagePropsAddressProof.width) {
          pdfHeightAddressProof = imagePropsAddressProof.width / aspectRatio;
          addressProofWidth = imagePropsAddressProof.width;
        }
        else {
          pdfHeightAddressProof = 438 / aspectRatio;
          addressProofWidth = 438;
          if (pdfHeightAddressProof > 580) pdfHeightAddressProof = 580
        }


        // let widthRatio = pdfWidth / imagePropsAddressProof.width;
        // let heightRatio = pdfHeight / imagePropsAddressProof.height;

        // let ratio = widthRatio > heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

        // pdfHeightAddressProof = imagePropsAddressProof.height * widthRatio;
        // addressProofWidth = imagePropsAddressProof.width * heightRatio;

        // pdfHeightAddressProof = (imagePropsAddressProof.height * (pdfWidth > imagePropsAddressProof.width ? imagePropsAddressProof.width : pdfWidth)) / imagePropsAddressProof.width;
      }

      if (this.attachedBankIdProof) {
        imagePropsBankIdProof = this.traderProfile.getImageProperties(this.attachedBankIdProof);


        let aspectRatio = imagePropsBankIdProof.width / imagePropsBankIdProof.height;
        // pdfHeightBankIdProof = (imagePropsBankIdProof.width < 438 ? imagePropsBankIdProof.width : 438) * (imagePropsBankIdProof.height / imagePropsBankIdProof.width);
        // bankIdProofwidth = (imagePropsBankIdProof.height < 580 ? imagePropsBankIdProof.height : 580) * aspectRatio;

        // pdfHeightBankIdProof = 438 / aspectRatio;
        // bankIdProofwidth = 438 < imagePropsBankIdProof.width ? 438 : imagePropsBankIdProof.width;

        if (438 >= imagePropsBankIdProof.width) {
          pdfHeightBankIdProof = imagePropsBankIdProof.width / aspectRatio;
          bankIdProofwidth = imagePropsBankIdProof.width;
        }
        else {
          pdfHeightBankIdProof = 438 / aspectRatio;
          bankIdProofwidth = 438;
          if (pdfHeightBankIdProof > 580) pdfHeightBankIdProof = 580

        }


        // let widthRatio = pdfWidth / imagePropsBankIdProof.width;
        // let heightRatio = pdfHeight / imagePropsBankIdProof.height;

        // let ratio = widthRatio < heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

        // pdfHeightBankIdProof = imagePropsBankIdProof.height * ratio;
        // bankIdProofwidth = imagePropsBankIdProof.width * ratio;
        // pdfHeightBankIdProof = (imagePropsBankIdProof.height * (pdfWidth < imagePropsBankIdProof.width ? imagePropsBankIdProof.width : pdfWidth)) / imagePropsBankIdProof.width;
      }

      if (this.attachedKYCAuthorizedSignatory) {
        imagePropsKYCAuthorizedSignatory = this.traderProfile.getImageProperties(this.attachedKYCAuthorizedSignatory);



        let aspectRatio = imagePropsKYCAuthorizedSignatory.width / imagePropsKYCAuthorizedSignatory.height;
        // pdfHeightKYCAuthorizedSignatory = (imagePropsKYCAuthorizedSignatory.width < 438 ? imagePropsKYCAuthorizedSignatory.width : 438) * (imagePropsKYCAuthorizedSignatory.height / imagePropsKYCAuthorizedSignatory.width);
        // KYCAuthorizedSignatorywidth = (imagePropsKYCAuthorizedSignatory.height < 580 ? imagePropsKYCAuthorizedSignatory.height : 580) * aspectRatio;

        if (438 >= imagePropsKYCAuthorizedSignatory.width) {
          pdfHeightKYCAuthorizedSignatory = imagePropsKYCAuthorizedSignatory.width / aspectRatio;
          KYCAuthorizedSignatorywidth = imagePropsKYCAuthorizedSignatory.width;
        }
        else {
          pdfHeightKYCAuthorizedSignatory = 438 / aspectRatio;
          KYCAuthorizedSignatorywidth = 438;
          if (pdfHeightKYCAuthorizedSignatory > 580) pdfHeightKYCAuthorizedSignatory = 580

        }


        // let widthRatio = pdfWidth / imagePropsKYCAuthorizedSignatory.width;
        // let heightRatio = pdfHeight / imagePropsKYCAuthorizedSignatory.height;

        // let ratio = widthRatio < heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

        // pdfHeightKYCAuthorizedSignatory = imagePropsKYCAuthorizedSignatory.height * ratio;
        // KYCAuthorizedSignatorywidth = imagePropsKYCAuthorizedSignatory.width * ratio;
        // pdfHeightKYCAuthorizedSignatory = (imagePropsKYCAuthorizedSignatory.height * (pdfWidth < imagePropsKYCAuthorizedSignatory.width ? imagePropsKYCAuthorizedSignatory.width : pdfWidth)) / imagePropsKYCAuthorizedSignatory.width;
      }

      if (this.attachedKYCTan) {
        imagePropsattachedKYCTan = this.traderProfile.getImageProperties(this.attachedKYCTan);


        let aspectRatio = imagePropsattachedKYCTan.width / imagePropsattachedKYCTan.height;
        // pdfHeightattachedKYCTan = (imagePropsattachedKYCTan.width < 438 ? imagePropsattachedKYCTan.width : 438) * (imagePropsattachedKYCTan.height / imagePropsattachedKYCTan.width);
        // KYCwidth = (imagePropsattachedKYCTan.height < 580 ? imagePropsattachedKYCTan.height : 580) * aspectRatio;
        if (438 >= imagePropsattachedKYCTan.width) {
          pdfHeightattachedKYCTan = imagePropsattachedKYCTan.width / aspectRatio;
          KYCwidth = imagePropsattachedKYCTan.width;
        }
        else {
          pdfHeightattachedKYCTan = 438 / aspectRatio;
          KYCwidth = 438;
          if (pdfHeightattachedKYCTan > 580) pdfHeightattachedKYCTan = 580
        }

        // let widthRatio = pdfWidth / imagePropsattachedKYCTan.width;
        // let heightRatio = pdfHeight / imagePropsattachedKYCTan.height;

        // let ratio = widthRatio > heightRatio ? widthRatio - heightRatio : heightRatio - widthRatio;

        // pdfHeightattachedKYCTan = imagePropsattachedKYCTan.height * ratio;
        // KYCwidth = imagePropsattachedKYCTan.width * ratio;

        // pdfHeightattachedKYCTan = (imagePropsattachedKYCTan.height * (pdfWidth > imagePropsattachedKYCTan.width ? imagePropsattachedKYCTan.width : pdfWidth)) / imagePropsattachedKYCTan.width;

        // console.log('pdfHeightattachedKYCTan', pdfHeightattachedKYCTan);
        // if (pdfHeightattachedKYCTan.height > 580)
        //   pdfHeightattachedKYCTan = 580;

      }

      this.service
        .getTraderGREFDataByDataCode(
          this.traderDetails.traderProfile.typeOfEntity.code,
          sessionStorage["auth_token"]
        )
        .subscribe(async (res) => {
          this.enityType = res.labels[0].text;
          this.traderProfile.setFontSize(20);
          this.traderProfile.setTextColor(242, 97, 1);
          this.traderProfile.addImage(this.traderIcon, 15, 10, 20, 20);
          this.traderProfile.text(this.appLabel.traderDetails.heading, 40, 25);
          this.traderProfile.setFontSize(14);
          this.traderProfile.setTextColor(0, 0, 0);
          this.traderProfile.text(
            this.appLabel.traderDetails.name +
            " : " +
            this.traderDetails.traderProfile.firstName +
            " " +
            this.traderDetails.traderProfile.lastName,
            20,
            60
          );
          if (sessionStorage["mobile"] != undefined) {
            this.traderProfile.text(
              this.appLabel.traderDetails.contact + " : " + sessionStorage["mobile"],
              20,
              80
            );
          }
          this.traderDetails.traderProfile.contacts.filter((x) => {
            if (x.contactType.code == "CONTACT_TYPE.MAIL") {
              this.traderProfile.text(
                this.appLabel.traderDetails.email + " : " + x.contactValue,
                20,
                100
              );
            }
          });
          this.traderProfile.text(
            this.appLabel.traderDetails.entityName + " : " + this.traderDetails.traderProfile.entityName,
            20,
            120
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.entityType + " : " + this.enityType,
            20,
            140
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.pan + " : " + this.traderDetails.traderProfile.panNumber,
            20,
            160
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.cin + " : " + this.traderDetails.traderProfile.cin,
            20,
            180
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.address +
            " : " +
            this.traderDetails.traderProfile.address.addressLine1 +
            "  " +
            this.traderDetails.traderProfile.address.addressLine2,
            20,
            200
          );
          //this.traderProfile.text(
          //  this.appLabel.traderDetails.city + " : " + this.traderDetails.traderProfile.address.city.name,
          //  20,
          //  220
          //);
          this.traderProfile.text(
            this.appLabel.traderDetails.block + " : " + this.traderDetails.traderProfile.address.block.name,
            20,
            220
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.district + " : " + this.traderDetails.traderProfile.address.district.name,
            20,
            240
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.state + " : " + this.traderDetails.traderProfile.address.state.name,
            20,
            260
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.country +
            " : " +
            this.traderDetails.traderProfile.address.country.name,
            20,
            280
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.landmark +
            " : " +
            this.traderDetails.traderProfile.address.landmark,
            20,
            300
          );
          this.traderProfile.text(
            this.appLabel.traderDetails.pinCode + " : " + this.traderDetails.traderProfile.address.pinCode,
            20,
            320
          );
          if (this.traderDetails.traderProfile.traderBankAccounts.length > 0) {
            this.traderProfile.text(
              this.appLabel.traderDetails.bankName +
              " : " +
              this.traderDetails.traderProfile.traderBankAccounts[0].bankName,
              20,
              340
            );
            this.traderProfile.text(
              this.appLabel.traderDetails.accountHolderName +
              " : " +
              this.traderDetails.traderProfile.traderBankAccounts[0]
                .accountHolderName,
              20,
              360
            );
            this.traderProfile.text(
              this.appLabel.traderDetails.accountNumber +
              " : " +
              this.traderDetails.traderProfile.traderBankAccounts[0]
                .accountNumber,
              20,
              380
            );

            this.traderProfile.text(
              this.appLabel.traderDetails.ifsc +
              " : " +
              this.traderDetails.traderProfile.traderBankAccounts[0].ifsc,
              20,
              400
            );
            this.traderProfile.text(
              this.appLabel.traderDetails.branchName +
              " : " +
              this.traderDetails.traderProfile.traderBankAccounts[0].branchName,
              20,
              420
            );
            this.traderProfile.text(
              this.appLabel.traderDetails.accountType + " : " + this.traderDetails.traderProfile.traderBankAccounts[0].accountType.labels[0].text,
              20,
              440
            )
          }
          else {
            this.loader = false;
            // this.toastr.error('Please update Bank Details');
          }
          if (this.traderDetails.traderProfile.traderBrands.length > 0) {

            this.traderProfile.text(
              this.appLabel.traderDetails.brandName +
              " : " +
              this.traderDetails.traderProfile.traderBrands[0].brandName,
              20,
              440
            );
            this.traderProfile.text(
              this.appLabel.traderDetails.brandOwnership +
              " : " +
              this.traderDetails.traderProfile.traderBrands[0]
                .brandOwnerShip.labels[0].text,
              20,
              460
            );
          }
          this.traderProfile.addPage();
          if (
            this.traderDetails.traderProfile.traderContactPersons.length >
            0
          ) {
            this.traderDetails.traderProfile.traderContactPersons.filter(
              (x) => {
                if (x.division.code == this.traderRefDataCodes.division.deliveryManager) {
                  this.traderProfile.setFontSize(20);
                  this.traderProfile.setTextColor(242, 97, 1);
                  this.traderProfile.addImage(
                    this.deliveryManagerIcon,
                    15,
                    10,
                    20,
                    20
                  );
                  this.traderProfile.text(
                    this.appLabel.deliveryManagerDetails.heading,
                    40,
                    25
                  );
                  this.traderProfile.setFontSize(14);
                  this.traderProfile.setTextColor(0, 0, 0);
                  this.traderProfile.text(
                    this.appLabel.deliveryManagerDetails.name + " : " + x.firstName,
                    20,
                    45
                  );
                  x.personContacts.filter((y) => {
                    if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                      this.traderProfile.text(
                        this.appLabel.deliveryManagerDetails.contact + " : " + y.contactValue,
                        20,
                        65
                      );
                    else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                      this.traderProfile.text(
                        this.appLabel.deliveryManagerDetails.email + " : " + y.contactValue,
                        20,
                        85
                      );
                  });
                }
                if (x.division.code == this.traderRefDataCodes.division.communicationAddress) {
                  this.traderProfile.setFontSize(20);
                  this.traderProfile.setTextColor(242, 97, 1);
                  this.traderProfile.addImage(
                    this.communicationIcon,
                    15,
                    110,
                    20,
                    20
                  );
                  this.traderProfile.text(
                    this.appLabel.communicationAddressDetails.heading,
                    40,
                    125
                  );
                  this.traderProfile.setFontSize(14);
                  this.traderProfile.setTextColor(0, 0, 0);
                  this.traderProfile.text(
                    this.appLabel.communicationAddressDetails.name + " : " + x.firstName,
                    20,
                    145
                  );
                  this.traderProfile.text(
                    this.appLabel.communicationAddressDetails.signatoryName + " : " + x.signatoryName,
                    20,
                    165
                  );

                  x.personContacts.filter((y) => {
                    if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.contact + " : " + y.contactValue,
                        20,
                        185
                      );
                    else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                      this.traderProfile.text(
                        this.appLabel.communicationAddressDetails.email + " : " + y.contactValue,
                        20,
                        205
                      );
                  });
                  this.traderProfile.text(
                    this.appLabel.communicationAddressDetails.block + " : " + x.personAddress.block.name,
                    20,
                    225
                  );
                  this.traderProfile.text(
                    this.appLabel.communicationAddressDetails.district + " : " + x.personAddress.district.name,
                    20,
                    245
                  );
                  this.traderProfile.text(
                    this.appLabel.communicationAddressDetails.state + " : " + x.personAddress.state.name,
                    20,
                    265
                  );
                  //this.traderProfile.text(
                  //  this.appLabel.communicationAddressDetails.city + " : " + x.personAddress.city.name,
                  //  20,
                  //  245
                  //);
                }
                if (
                  x.division.code == this.traderRefDataCodes.division.customerServiceManager
                ) {
                  this.traderProfile.setFontSize(20);
                  this.traderProfile.setTextColor(242, 97, 1);
                  this.traderProfile.addImage(
                    this.customerServiceManagerIcon,
                    15,
                    290,
                    20,
                    20
                  );
                  this.traderProfile.text(
                    this.appLabel.customerServiceDetails.heading,
                    40,
                    305
                  );
                  this.traderProfile.setFontSize(14);
                  this.traderProfile.setTextColor(0, 0, 0);
                  this.traderProfile.text(
                    this.appLabel.customerServiceDetails.name + " : " + x.firstName,
                    20,
                    325
                  );
                  x.personContacts.filter((y) => {
                    if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                      this.traderProfile.text(
                        this.appLabel.customerServiceDetails.contact + " : " + y.contactValue,
                        20,
                        345
                      );
                    else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                      this.traderProfile.text(
                        this.appLabel.customerServiceDetails.email + " : " + y.contactValue,
                        20,
                        365
                      );
                  });
                }
                if (x.division.code == this.traderRefDataCodes.division.financeManager) {
                  this.traderProfile.setFontSize(20);
                  this.traderProfile.setTextColor(242, 97, 1);
                  this.traderProfile.addImage(
                    this.financeDivisionMangerIconUS,
                    15,
                    390,
                    20,
                    20
                  );
                  this.traderProfile.text(
                    this.appLabel.financeManagerDetails.heading,
                    40,
                    405
                  );
                  this.traderProfile.setFontSize(14);
                  this.traderProfile.setTextColor(0, 0, 0);
                  this.traderProfile.text(
                    this.appLabel.financeManagerDetails.name + " : " + x.firstName,
                    20,
                    425
                  );
                  x.personContacts.filter((y) => {
                    if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                      this.traderProfile.text(
                        this.appLabel.financeManagerDetails.contact + " : " + y.contactValue,
                        20,
                        445
                      );
                    else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                      this.traderProfile.text(
                        this.appLabel.financeManagerDetails.email + " : " + y.contactValue,
                        20,
                        465
                      );
                  });
                }
                if (
                  x.division.code ==
                  this.traderRefDataCodes.division.financeDivisionContactDetails
                ) {
                  this.traderProfile.setFontSize(20);
                  this.traderProfile.setTextColor(242, 97, 1);
                  this.traderProfile.addImage(
                    this.financeDivisionMangerIconUS,
                    15,
                    490,
                    20,
                    20
                  );
                  this.traderProfile.text(
                    this.appLabel.financeDivisionContactDetails.heading,
                    40,
                    505
                  );
                  this.traderProfile.setFontSize(14);
                  this.traderProfile.setTextColor(0, 0, 0);
                  this.traderProfile.text(
                    this.appLabel.financeDivisionContactDetails.name + " : " + x.firstName,
                    20,
                    525
                  );
                  x.personContacts.filter((y) => {
                    if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                      this.traderProfile.text(
                        this.appLabel.financeDivisionContactDetails.contact + " : " + y.contactValue,
                        20,
                        545
                      );
                    else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                      this.traderProfile.text(
                        this.appLabel.financeDivisionContactDetails.email + " : " + y.contactValue,
                        20,
                        565
                      );
                  });
                }
              }
            );
            this.traderProfile.addPage();
          }
          //Adding other details
          this.traderDetails.traderProfile.traderContactPersons.filter(
            (x) => {
              if (x.division.code == this.traderRefDataCodes.division.ceo) {
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(this.ceoIcon, 15, 10, 20, 20);
                this.traderProfile.text(this.appLabel.ceoDetails.heading, 40, 25);
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);
                this.traderProfile.text(
                  this.appLabel.ceoDetails.name + " : " + x.firstName,
                  20,
                  45
                );
                x.personContacts.filter((y) => {
                  if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                    this.traderProfile.text(
                      this.appLabel.ceoDetails.contact + " : " + y.contactValue,
                      20,
                      65
                    );
                  else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                    this.traderProfile.text(
                      this.appLabel.ceoDetails.email + " : " + y.contactValue,
                      20,
                      85
                    );
                });
              }
              if (x.division.code == this.traderRefDataCodes.division.displayInformation) {
                this.traderProfile.setFontSize(20);
                this.traderProfile.setTextColor(242, 97, 1);
                this.traderProfile.addImage(
                  this.infoIcon,
                  15,
                  110,
                  20,
                  20
                );
                this.traderProfile.text(this.appLabel.displayInformation.heading, 40, 125);
                this.traderProfile.setFontSize(14);
                this.traderProfile.setTextColor(0, 0, 0);
                this.traderProfile.text(
                  this.appLabel.displayInformation.name + " : " + x.firstName,
                  20,
                  145
                );
                x.personContacts.filter((y) => {
                  if (y.contactType.code == "CONTACT_TYPE.MOBILE")
                    this.traderProfile.text(
                      this.appLabel.displayInformation.contact + " : " + y.contactValue,
                      20,
                      165
                    );
                  else if (y.contactType.code == "CONTACT_TYPE.MAIL")
                    this.traderProfile.text(
                      this.appLabel.displayInformation.email + " : " + y.contactValue,
                      20,
                      185
                    );
                });
              }
            }
          );
          //Warehouse Details
          if (this.warehouseDetails && this.warehouseDetails[0].workNode.workNodeContact != undefined) {
            this.traderProfile.setFontSize(20);
            this.traderProfile.setTextColor(242, 97, 1);
            this.traderProfile.addImage(
              this.deliveryManagerIcon,
              15,
              220,
              20,
              20
            );
            this.traderProfile.text(this.appLabel.warehouseInformation.heading, 40, 235);
            this.traderProfile.setFontSize(14);
            this.traderProfile.setTextColor(0, 0, 0);
            this.warehouseDetails.forEach((o, i) => {
              let pageHeightInPx = 0;
              if ((i != 0) && (i % 2 != 0)) {
                this.traderProfile.addPage();
                pageHeightInPx = 0;
              }
              else if (i == 0) {
                pageHeightInPx = 1;
              }
              else {
                pageHeightInPx = 1.5;
              }

              this.traderProfile.text(
                this.appLabel.warehouseInformation.name + " : " + this.warehouseDetails[i].centerName,
                20,
                60 + (pageHeightInPx * 200)
              );
              //console.log('this.warehouseDetails', this.warehouseDetails)
              this.warehouseDetails[i].workNode.workNodeContact.filter(warehouse => console.log('x-1', warehouse));
              this.warehouseDetails[i].workNode.workNodeContact.filter((warehouse) => {
                if (warehouse.contactType.code == "CONTACT_TYPE.MOBILE")
                  this.traderProfile.text(
                    this.appLabel.warehouseInformation.contact + " : " + warehouse.contactValue,
                    20,
                    80 + (pageHeightInPx * 200)
                  );
                else if (warehouse.contactType.code == "CONTACT_TYPE.MAIL")
                  this.traderProfile.text(
                    this.appLabel.warehouseInformation.email + " : " + warehouse.contactValue,
                    20,
                    100 + (pageHeightInPx * 200)
                  );
                else if (warehouse.contactType.code == "CONTACT_TYPE.PERSON_NAME")
                  this.traderProfile.text(
                    this.appLabel.warehouseInformation.warehouseManager + " : " + warehouse.contactValue,
                    20,
                    120 + (pageHeightInPx * 200)
                  );
              });
              this.traderProfile.text(
                this.appLabel.warehouseInformation.address + " : " + this.warehouseDetails[i].workNode.workNodeAddress.addressLine1,
                20,
                140 + (pageHeightInPx * 200)
              );
              if (this.warehouseDetails[i].workNode.workNodeAddress.block) {
                this.traderProfile.text(
                  this.appLabel.warehouseInformation.block + " : " + this.warehouseDetails[i].workNode.workNodeAddress.block.name,
                  20,
                  160 + (pageHeightInPx * 200)
                );
              }
              if (this.warehouseDetails[i].workNode.workNodeAddress.district) {
                this.traderProfile.text(
                  this.appLabel.warehouseInformation.district + " : " + this.warehouseDetails[i].workNode.workNodeAddress.district.name,
                  20,
                  180 + (pageHeightInPx * 200)
                );
              }
              this.traderProfile.text(
                this.appLabel.warehouseInformation.state + " : " + this.warehouseDetails[i].workNode.workNodeAddress.state.name,
                20,
                200 + (pageHeightInPx * 200)
              );
              //this.traderProfile.text(
              //  this.appLabel.warehouseInformation.city + " : " + this.warehouseDetails[i].workNode.workNodeAddress.city.name,
              //  20,
              //  375 + (i * 150)
              //);
              this.traderProfile.text(
                this.appLabel.warehouseInformation.pinCode + " : " + this.warehouseDetails[i].workNode.workNodeAddress.pinCode,
                20,
                220 + (pageHeightInPx * 200)
              );
            })
          }
        
          this.traderProfile.addPage();

          //Attachments
          if (this.attachedBankIdProof) {
            this.traderProfile.setFontSize(20);
            this.traderProfile.setTextColor(242, 97, 1);
            this.traderProfile.addImage(
              this.attachmentIcon,
              15,
              10,
              20,
              20
            );
            this.traderProfile.text(this.appLabel.attachments.bank, 40, 25);
            this.traderProfile.setFontSize(14);
            this.traderProfile.setTextColor(0, 0, 0);
            this.traderProfile.addImage(
              this.attachedBankIdProof,
              5,
              40,
              // 300,
              // 150
              // width - 40,
              // height - 150
              // pdfWidth > imagePropsBankIdProof.width ? imagePropsBankIdProof.width : pdfWidth, pdfHeightBankIdProof
              bankIdProofwidth, pdfHeightBankIdProof
            );
            this.traderProfile.addPage();
          }
          if (this.attachedKYCTan) {
            this.traderProfile.setFontSize(20);
            this.traderProfile.setTextColor(242, 97, 1);
            this.traderProfile.addImage(
              this.attachmentIcon,
              15,
              10,
              20,
              20
            );
            this.traderProfile.text(this.appLabel.attachments.tanNumber, 40, 25);
            this.traderProfile.setFontSize(14);
            this.traderProfile.setTextColor(0, 0, 0);
            this.traderProfile.addImage(
              this.attachedKYCTan,
              5,
              40,
              // 300,
              // 150
              // width - 40,
              // height - 150
              // pdfWidth > imagePropsattachedKYCTan.width ? imagePropsattachedKYCTan.width : pdfWidth, pdfHeightattachedKYCTan
              KYCwidth, pdfHeightattachedKYCTan
            );
            this.traderProfile.addPage();
          }
          // else if (!this.attachedKYCTan)
          //   this.traderProfile.addPage();

          if (this.attachedKYCAuthorizedSignatory) {
            this.traderProfile.setFontSize(20);
            this.traderProfile.setTextColor(242, 97, 1);
            this.traderProfile.addImage(
              this.attachmentIcon,
              15,
              10,
              20,
              20
            );
            this.traderProfile.text(
              this.appLabel.attachments.kyc,
              40,
              25
            );
            this.traderProfile.setFontSize(14);
            this.traderProfile.setTextColor(0, 0, 0);
            this.traderProfile.addImage(
              this.attachedKYCAuthorizedSignatory,
              5,
              40,
              // 300,
              // 150
              // width - 40,
              // height - 150
              // pdfWidth > imagePropsKYCAuthorizedSignatory.width ? imagePropsKYCAuthorizedSignatory.width : pdfWidth, pdfHeightKYCAuthorizedSignatory
              KYCAuthorizedSignatorywidth, pdfHeightKYCAuthorizedSignatory
            );
            this.traderProfile.addPage();
          }
          if (this.attachedAddressProof) {
            this.traderProfile.setFontSize(20);
            this.traderProfile.setTextColor(242, 97, 1);
            this.traderProfile.addImage(
              this.attachmentIcon,
              15,
              10,
              20,
              20
            );
            this.traderProfile.text(this.appLabel.attachments.addressProof, 40, 25);
            this.traderProfile.setFontSize(14);
            this.traderProfile.setTextColor(0, 0, 0);
            this.traderProfile.addImage(
              this.attachedAddressProof,
              5,
              40,
              // 300,
              // 150
              // width - 40,
              // height - 150
              // pdfWidth > imagePropsAddressProof.width ? imagePropsAddressProof.width : pdfWidth, pdfHeightAddressProof
              addressProofWidth, pdfHeightAddressProof
            );
          }
          // this.attachedBankIdProof = this.getAttachment()
          // pdf.addImage(img, 'png', 10, 78, 12, 15)

          this.loader = false;
          this.traderProfile.save("traderProfile.pdf");
          

        }, err => {
          this.loader = false;
          // this.toastr.error('Please update all mandatory fields');
        });
      // this.traderProfile.text(this.traderDetails.firstName + ' ' + this.traderDetails.lastName, 60, 25);
      // this.traderProfile.text(this.traderDetails.entityName, 5, 40);
      // this.traderProfile.text('GST Number- ' + this.traderDetails.gstNumber, 5, 55);
      // this.traderProfile.text('GST Number- ' + this.traderDetails.gstNumber, 5, 70);

      // this.traderProfile.addPage();
      // this.traderProfile.text('Do you like that?', 20, 20);

      // Save the PDF
      // this.traderProfile.save('Test.pdf');
    }
  }


}
