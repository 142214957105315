<div class="emergency-contact" *ngIf="traderJson">
    
    <div class="container-fluid">
        <button  class="btn"
        style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()"><span 
          *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
          *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span> </button>
        <h6>CEO</h6>
            <hr>
        <div class="row">
            
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label"> Name</label>
                    <input type="text" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Name" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCEO].firstName" name="ceoName" [disabled]="!editableFlag && readOnlyMode">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Phone </label>
                    <!-- <input type="text" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCEO].personContacts[1].contactValue" name="ceoPhone" [disabled]="!editableFlag && readOnlyMode"> -->
                        <input type="text" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCEO].personContacts[1].contactValue"
                          name="contactNumber"  #contactNumber="ngModel"  pattern="^([+]\d{2}[ ])?\d{10}$" class="form-control form-control-sm"
                        id="exampleInputEmail1" placeholder="Enter Contact Number"
                        [disabled]="!editableFlag && readOnlyMode" >
                        <!-- required -->
                        <span *ngIf="contactNumber.touched">
                            <small *ngIf="contactNumber.errors?.pattern" class="text-danger">Please enter a valid contact Number</small>
                            <!-- <small *ngIf="contactNumber.errors?.required" class="text-danger">contact Number is required</small> -->
                        </span>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Email </label>
                    <input type="email" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCEO].personContacts[0].contactValue" name="ceoEmail" [disabled]="!editableFlag && readOnlyMode">

                </div>
            </div>

        </div>
        <hr>
        <h6>Finance Manager</h6>
            <hr>
        <div class="row mt-4">
            
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label"> Name</label>
                    <input type="text" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Name" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexFinance].firstName" name="financeName" [disabled]="!editableFlag && readOnlyMode" >
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Phone </label>
                    <!-- <input type="number" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="" name="financePhone" [disabled]="!editableFlag && readOnlyMode" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexFinance].personContacts[1].contactValue" > -->
                        <input type="text" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexFinance].personContacts[1].contactValue"
                        name="contactNumber"  #contactNumber="ngModel"  pattern="^([+]\d{2}[ ])?\d{10}$" class="form-control form-control-sm"
                      id="exampleInputEmail1" placeholder="Enter Contact Number"
                      [disabled]="!editableFlag && readOnlyMode" required>
                      <span *ngIf="contactNumber.touched">
                          <small *ngIf="contactNumber.errors?.pattern" class="text-danger">Please enter a valid contact Number</small>
                          <small *ngIf="contactNumber.errors?.required" class="text-danger">contact Number is required</small>
                      </span>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Email </label>
                    <input type="email" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" name="financeEmail" [disabled]="!editableFlag && readOnlyMode" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexFinance].personContacts[0].contactValue">

                </div>
            </div>
            

        </div>
        <hr>
        <h6>Delivery Manager</h6>
            <hr>
        <div class="row mt-4">
            
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label"> Name</label>
                    <input type="text" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Name" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexDelivery].firstName" name="deliveryName" [disabled]="!editableFlag && readOnlyMode">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Phone </label>
                    <!-- <input type="number" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexDelivery].personContacts[1].contactValue" name="deliveryPhone" [disabled]="!editableFlag && readOnlyMode" > -->
                        <input type="text" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexDelivery].personContacts[1].contactValue"
                        name="contactNumber"  #contactNumber="ngModel"  pattern="^([+]\d{2}[ ])?\d{10}$" class="form-control form-control-sm"
                      id="exampleInputEmail1" placeholder="Enter Contact Number"
                      [disabled]="!editableFlag && readOnlyMode" required>
                      <span *ngIf="contactNumber.touched">
                          <small *ngIf="contactNumber.errors?.pattern" class="text-danger">Please enter a valid contact Number</small>
                          <small *ngIf="contactNumber.errors?.required" class="text-danger">contact Number is required</small>
                      </span>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Email </label>
                    <input type="email" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexDelivery].personContacts[0].contactValue" name="deliveryEmail" [disabled]="!editableFlag && readOnlyMode" >

                </div>
            </div>
            

        </div>
        <hr>
        <h6>Customer Service Manager</h6>
        <hr>
        <div class="row mt-4">
           
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label"> Name</label>
                    <input type="text" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Name" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCustomer].firstName" name="serviceName" [disabled]="!editableFlag && readOnlyMode">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Phone </label>
                    <!-- <input type="number" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCustomer].personContacts[1].contactValue" name="servicePhone" [disabled]="!editableFlag && readOnlyMode" > -->
                        <input type="text" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCustomer].personContacts[1].contactValue"
                        name="contactNumber"  #contactNumber="ngModel"  pattern="^([+]\d{2}[ ])?\d{10}$" class="form-control form-control-sm"
                      id="exampleInputEmail1" placeholder="Enter Contact Number"
                      [disabled]="!editableFlag && readOnlyMode" required>
                      <span *ngIf="contactNumber.touched">
                          <small *ngIf="contactNumber.errors?.pattern" class="text-danger">Please enter a valid contact Number</small>
                          <small *ngIf="contactNumber.errors?.required" class="text-danger">contact Number is required</small>
                      </span>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="" class="text-label">Email </label>
                    <input type="email" class="form-control form-control-sm" id=""
                        aria-describedby="emailHelp" placeholder="Enter Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndexCustomer].personContacts[0].contactValue" name="serviceEmail" [disabled]="!editableFlag && readOnlyMode">

                </div>
            </div>
            

        </div>
        <hr>
        <div class="row" style="margin-top: 50px;">
        <div class="full-width-div" >
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">
                    <button type="button"  class="btn  btn-sm profile-side-nav-next-prev-button" style="height:28px;width:100px;left:15px" [disabled]="editableFlag "
                    (click)="navigatePrevious()">
                      <<&nbsp;&nbsp;Previous</button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>
                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button" [disabled]="editableFlag " (click)="navigateNext()" style=" height:28px;width:100px;left: 135px;
                ">
                     Next&nbsp; &nbsp;>></button>
                     <button type="button" [disabled]="!editableFlag && readOnlyMode"
                                class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                                style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>