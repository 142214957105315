<div class="finance-division-details" *ngIf="traderJson">
    <div class="container-fluid">
        <button class="btn" style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()"><span 
          *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
          *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span> </button>

          <form #findiv="ngForm">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">Name<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm"  [ngClass]="{'has-error': isFieldInvalid()}"
                    [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].firstName" name="name" [disabled]="!editableFlag && readOnlyMode" required placeholder="Please Enter Name">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">Designation <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" name="designation"  [ngClass]="{'has-error': isFieldInvalid()}"
                    [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].designation" [disabled]="!editableFlag && readOnlyMode" required placeholder="Please Enter Designation">
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="appLabel.landline.required">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">Landline Number</label>
                    <input type="text" class="form-control form-control-sm" 
                     name="landline" pattern="^[\+\d]?(?:[\d-.\s()]*)$"
                    [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[0].contactValue" [disabled]="!editableFlag && readOnlyMode" placeholder="Please Enter Landline Number">

                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">{{appLabel.mobile}} <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm"  [ngClass]="{'has-error': isFieldInvalid()}"
                    pattern="^[\+\d]?(?:[\d-.\s()]*)$"
                    [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[2].contactValue" name="mobile" [disabled]="!editableFlag && readOnlyMode" required placeholder="Please Enter Mobile Number">

                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">Email Id<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" 
                    #email="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" name="email"  [ngClass]="{'has-error': isFieldInvalid()}"
                    [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[1].contactValue" [disabled]="!editableFlag && readOnlyMode" required placeholder="Please Enter Email ID">
                    <span *ngIf="email.touched">
                        <small *ngIf="email.errors?.pattern" class="text-danger">Please enter a valid email</small>
                        <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
                      </span>
               
                </div>
            </div>

        </div>
        <div class="full-width-div">
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">

                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                        style="height:28px;width:100px;left:15px" [disabled]="editableFlag " (click)="navigatePrevious()"
                       >
                        <i class="fa fa-angle-double-left" style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                        Previous
                    </button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                    <button [disabled]="editableFlag " type="button"
                        class="btn  btn-sm profile-side-nav-next-prev-button"
                        style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                        Next&nbsp; &nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>


                    <button type="button" [disabled]="findiv.form.invalid || loader ||( !editableFlag && readOnlyMode)" class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                        style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>
          </form>
    </div>
</div>