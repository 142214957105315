import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  //Headers
  headersWithoutAuthToken = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  headersWithAuthToken = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: sessionStorage["auth_token"],
    })
  };
  headersForLocalImages = {
    headers: new HttpHeaders({
      Accept: "image/avif,image/webp,image/apng,image/*,*/*;q=0.8",
    }),
  };
  // Common Headers
  headerValue: any = {
    headers: new HttpHeaders({
      Authorization: sessionStorage["auth_token"],
    }),
  }
  lazyComponent$ = new BehaviorSubject(0);
  lazyComponentName$ = new BehaviorSubject(0);
  constructor(private http: HttpClient) { }

  // To store the current trader's data and pouplate PDF
  traderDetails = new Subject<any>();

  traderAttachment = new Subject<any>();

  warehouseDetails = new Subject<any>();
  formData$ = new BehaviorSubject(0);

  public dataArray: any[] = [];


  resetDataArray() {
    this.dataArray = [];
  }
  //http Calls
  executeRequestOnTokenExpiry(reqType, reqBody, authToken) {
    if (reqType == "GET")
      return this.http.get(reqBody, {
        headers: new HttpHeaders({ Authorization: authToken }),
      });
  }
  forgotPasswordUpdate(payload, authToken) {
    return this.http.post(
      environment.baseUrl + "auth-service/api/v1/users/forgot/password",
      payload, { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  forgotPasswordOTP(tenantLogin, authToken) {
    const encodedLogin = encodeURIComponent(tenantLogin);
    return this.http.post(
      environment.baseUrl + `auth-service/api/v1/users/forgot/password/verify?userLogin=${encodedLogin}`,
      null, { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  //API for Login Page Functionality
  getTenancyByUserLogin(userLogin: any) {
    return this.http.get(environment.baseUrl + 'auth-service/api/v1/tenants/user?userLogin=' + userLogin)
  }

  login(payload: any) {
    return this.http.post(environment.baseUrl + "auth-service/api/auth/v1/login", payload);
  }
  getLoggedInUserDetails = function (authToken) {
    return this.http.get(environment.baseUrl + "auth-service/api/v1/users/me", {
      headers: new HttpHeaders({ Authorization: authToken }),
    });
  };
  getTraderDetailsForLoggedInUser = function (authToken) {
    return this.http.get(
      environment.baseUrl + "trader-service/api/v1/traders/current/tenant",
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  // API Call for getting JSON
  getJSONData(jsonPathVal: any) {
    return this.http.get("./assets/JSON-FILES/" + jsonPathVal + ".json");

  }
  // Markets And Channels page 

  getGREFDataByDataCode = function (GREFDataCode) {
    return this.http.get(
      environment.baseUrl +
      "ref-data-service/api/v1/global-ref-data/" +
      GREFDataCode, {
      headers: new HttpHeaders({
        Authorization: sessionStorage["auth_token"],
      }),
    }
    );
  };
  getTraderDetailsWithMarketChannelForLoggedInUser(fetchMarketChannelFlag: any) {
    return this.http.get(
      environment.baseUrl + "trader-service/api/v1/traders/current/tenant?fetchMarketChannel=" + fetchMarketChannelFlag,
      {
        headers: new HttpHeaders({
          Authorization: sessionStorage["auth_token"],
        }),
      }
    );
  };
  getChannelContracts(traderId: any, consumerPartyType: any) {
    return this.http.get(
      environment.baseUrl + "consent-service/api/v1/consentConsumerParty/" + traderId + "/" + consumerPartyType, {
      headers: new HttpHeaders({
        Authorization: sessionStorage["auth_token"],
      }),
    }
    );
  }

  getMarketsByDomain(domainCode: any) {
    return this.http.get(
      environment.baseUrl + "trader-query-service/api/v1/markets/domain?domain=" + domainCode, {
      headers: new HttpHeaders({
        Authorization: sessionStorage["auth_token"],
      }),
    }
    );
  }

  getChannelByMarketId(marketId: any) {
    return this.http.get(
      environment.baseUrl + "market-service/api/v1/market-channels/markets/" + marketId, {
      headers: new HttpHeaders({
        Authorization: sessionStorage["auth_token"],
      }),
    }
    );
  }

  patchTraderProfile = function (payload) {
    return this.http.put(
      environment.baseUrl + "trader-service/api/v1/traders",
      payload, {
      headers: new HttpHeaders({
        Authorization: sessionStorage["auth_token"],
      }),
    }
    );
  };
  getTraderGREFDataByClassCode = function (GREFClassCode) {
    return this.http.get(
      environment.baseUrl +
      "trader-service/api/v1/ref-data/class/" +
      GREFClassCode +
      "?direction=ASC&page=1&size=100", {
      headers: new HttpHeaders({
        Authorization: sessionStorage["auth_token"],
      }),
    }

    );
  };
  getAttachment = function (attachmentType, traderId) {
    return this.http.get(
      environment.baseUrl +
      "trader-service/api/v1/traders/attachment?attachmentFormat=ATTACHMENT_FILE&attachmentKind=" +
      attachmentType +
      "&traderId=" +
      traderId,
      {
        headers: new HttpHeaders({
          Authorization: sessionStorage["auth_token"],
        }),
      }
    );
  };
  addAttachment(traderId, file, attachmentType) {
    return this.http.post(
      environment.baseUrl +
      "trader-service/api/v1/traders/attachment?attachmentKind=" +
      attachmentType +
      "&traderId=" +
      traderId,
      file,
      {
        headers: new HttpHeaders({
          Authorization: sessionStorage["auth_token"],
        }),
      }
    );
  }
  getCategories(parentCategoryCode, authToken) {
    return this.http.get(
      environment.baseUrl +
      "product-service/api/v1/productclassificationhierarchys/parentClassificationCodes/" +
      parentCategoryCode +
      "?direction=ASC&isPaginationRequired=true&page=1&size=100",
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }


  getTraderCenterByTraderId(traderId, authToken) {
    return this.http.get(
      environment.baseUrl +
      "trader-service/api/v1/traders/trader-centers/" +
      traderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  getImage(imageURL: string): Observable<Blob> {
    return this.http.get(imageURL, { responseType: "blob" });
  }
  getWorknodeById(worknodeIds, authToken) {
    return this.http.get(
      environment.baseUrl +
      "worknode-service/api/v1/worknodes/ids?workNodeIds=" +
      worknodeIds,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  getTraderGREFDataByDataCode = function (GREFClassCode, authToken) {
    return this.http.get(
      environment.baseUrl + "trader-service/api/v1/ref-data/" + GREFClassCode,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  getGREFDataByClassCode = function (GREFClassCode, authToken) {
    return this.http.get(
      environment.baseUrl +
      "ref-data-service/api/v1/global-ref-data/class/" +
      GREFClassCode +
      "?direction=ASC&page=1&size=100",
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  createTraderCenter(payload, traderId, traderMarketAccountId, authToken) {
    return this.http.post(
      environment.baseUrl +
      "trader-service/api/v1/traders/" +
      traderId +
      "/trader-market-accounts/" +
      traderMarketAccountId +
      "/trader-centers/",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  updateTraderCenter(payload, authToken) {
    return this.http.put(
      environment.baseUrl + "trader-service/api/v1/traders/trader-center/",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  updateWorknode = function (payload, authToken) {
    return this.http.patch(
      environment.baseUrl + "worknode-service/api/v1/worknodes",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };


  //API for  functionality For Product Management 

  getRefDataFromClassCode(classCode): Observable<any> {
    return this.http.get(environment.baseUrl + 'product-service/api/v1/ref-data/class/' + classCode + '?direction=ASC&page=1&size=100', {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: sessionStorage["auth_token"],
      })
    });
  }

  getClassificationCodeFromProductType(productType, parentCategoryCode): Observable<any> {
    return this.http.get(environment.baseUrl + 'product-service/api/productclassifications/productType?direction=ASC&page=1&&parentClassificationCodes=' + parentCategoryCode + '&productType=' + productType + '&size=100', {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: sessionStorage["auth_token"],
      })
    });
  }

  getSubClassificationCodeFromCategory(classificationCode): Observable<any> {
    return this.http.get(environment.baseUrl + 'product-service/api/v1/productclassificationhierarchys/parentClassificationCodes/' + classificationCode + '?direction=ASC&isPaginationRequired=true&page=1&size=100', {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: sessionStorage["auth_token"],
      })
    })
  }
  uploadLineSheet(formData, id): Observable<any> {
    return this.http.post(environment.baseUrl + 'product-service/api/v1/product/file/upload?classificationId=' + id, formData, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }
  getCurrentTenantProducts(pageNumber: number, classificationCode: any[], sortBy: string, orderBy: string): Observable<any> {
    return this.http.get(environment.baseUrl + 'product-service/api/products?direction=' + orderBy + '&page=' + pageNumber + '&productClassificationCodes=' + classificationCode + '&size=10&sortBy=' + sortBy, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }

  getMarketProductsByIds(tempIds) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/market-channel-products?page=0&size=1000&` + tempIds, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }




  getJson(path) {
    return this.http.get("./assets/JSON-FILES/" + path + ".json");
  }
  // getGREFDataByDataCode = function (GREFDataCode, authToken) {
  //   return this.http.get(
  //     environment.baseUrl +
  //     "product-service/api/v1/productclassificationhierarchys/parentClassificationCodes/" +
  //     parentCategoryCode +
  //     "?direction=ASC&isPaginationRequired=true&page=1&size=100",
  //     { headers: new HttpHeaders({ Authorization: authToken }) }
  //   );
  // }

  getGREFDataHierarchyByParentCode = function (
    parentCode,
    childClassCode,
    authToken,
    startPageIndex
  ) {
    return this.http.get(
      environment.baseUrl +
      "ref-data-service/api/v1/global-ref-data-hierarchy/parent?childClassCode=" +
      childClassCode +
      "&direction=ASC&page=" + startPageIndex + "&parentCode=" +
      parentCode +
      "&size=100",
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  storeLazyComponent(schoolsData) {
    // console.log("data stored", schoolsData);
    this.lazyComponent$.next(schoolsData.component);
    this.lazyComponentName$.next(schoolsData.componentName);
  }
  getProductsForMarketChannelBasedOnStatusAndClassification(requestObj) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/market-channel-products?page=` + requestObj.pageNumber + `&productStatus=` + requestObj.productStatus + `&productSubClassificationCode=` + requestObj.productSubClassificationCode + `&size=` + requestObj.pageSize + `&traderId=` + requestObj.traderId + '&direction=DESC&sortBy=productId', { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }


  sendNotificationMail(requestObj) {
    return this.http.post(environment.baseUrl + `notification-service/api/v1/notification`, requestObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }
  updateContractStatus = function (
    consentConsumerPartyId,
    payload,
    statusCode,
    authToken
  ) {
    return this.http.patch(
      environment.baseUrl +
      "consent-service/api/v1/consentConsumerParty?consentConsumerPartyId=" +
      consentConsumerPartyId +
      "&statusCode=" +
      statusCode,
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  //JOIN Channel Functionality
  createTraderMarketAccount = function (traderId, payload, authToken) {
    return this.http.post(
      environment.baseUrl +
      "trader-service/api/v1/traders/" +
      traderId +
      "/trader-market-accounts/",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  addChannelForTrader = function (
    traderId,
    traderMarketAccountId,
    payload,
    authToken
  ) {
    return this.http.post(
      environment.baseUrl +
      "trader-service/api/v1/traders/" +
      traderId +
      "/trader-market-accounts/" +
      traderMarketAccountId +
      "/channel-for-traders/",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }

  addTraderAttachmentForApproval(
    channelForTraderId,
    file,
    traderId,
    authToken
  ) {
    return this.http.post(
      environment.baseUrl +
      "trader-service/api/v1/traders/attachment/channel/?channelForTraderId=" +
      channelForTraderId +
      "&traderId=" +
      traderId,
      file,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  reApplyForChannelAssociation = function (
    traderId,
    traderMarketAccountId,
    associationId,
    remarks,
    authToken
  ) {
    return this.http.patch(
      environment.baseUrl +
      "trader-service/api/v1/traders/" +
      traderId +
      "/trader-market-accounts/" +
      traderMarketAccountId +
      "/channel-for-traders/" +
      associationId +
      "/reapply?remarks=" +
      remarks,
      null,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };

  //Functionality for creating Linesheet
  getFlourishSKUCodesForSKU(requestObj) {
    return this.http.post(environment.baseUrl + 'product-service/api/v1/product/file/update/flourish-sku?fileName=' + requestObj.fileName, requestObj.skuList, { headers: new HttpHeaders({ "Content-Type": "application/json", 'Authorization': sessionStorage['auth_token'], }), observe: 'response' })
  }

  generateNewLinesheet(product): Observable<any> {

    product.productSKUList.forEach(skuElement => {
      if (skuElement.imageAttachmentArr) {
        delete skuElement.imageAttachmentArr;
      }

      skuElement.productSKUSpecificationDTOList.forEach(specElement => {
        if (specElement.isDropDownField) {
          delete specElement.isDropDownField
        }
        if (specElement.dropDownValues) {
          delete specElement.dropDownValues
        }
      });
      skuElement.productSKUExtraAttribute.forEach(extraElement => {
        if (extraElement.isDropDownField) {
          delete extraElement.isDropDownField
        }
        if (extraElement.dropDownValues) {
          delete extraElement.dropDownValues
        }
      });
    });
    return this.http.post(environment.baseUrl + 'product-service/api/products', product, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }
  getProduct(productId: number): Observable<any> {
    return this.http.get(environment.baseUrl + 'product-service/api/products/' + productId, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }
  uploadImagesForSKU(formData, productSKUId, attachmentIdentifier): Observable<any> {
    return this.http.post(environment.baseUrl + `product-service/api/v1/product-sku-attachments/${productSKUId}?identifier=` + attachmentIdentifier, formData, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }), observe: 'response', responseType: 'text' })
  }


  //update functionality for Products 
  getProductsForMarketChannelBasedOnClassification(requestObj) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/market-channel-products?page=` + requestObj.pageNumber + `&productSubClassificationCode=` + requestObj.productSubClassificationCode + `&size=1000&traderId=` + requestObj.traderId, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }

  getProductsForMarketChannelClassifiedProd(requestObj) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/market-channel-products?page=` + requestObj.pageNumber + `&size=1000&traderId=` + requestObj.traderId, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }

  getChannelProductDetails(productID) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/market-channel-products/` + productID, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })

  }

  getImagesByProductSKUId(productSkuId): Observable<any> {
    return this.http.get(environment.baseUrl + 'product-service/api/v1/product-sku-attachments/' + productSkuId, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }

  getClassificationCodeByChildClassification(childClassificationCode): Observable<any> {
    return this.http.get(environment.baseUrl + 'product-service/api/v1/productclassificationhierarchys/childClassificationCodes/' + childClassificationCode + '?direction=ASC&isPaginationRequired=true&page=1&size=10', { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }
  deleteProductSKUAttachment(attachmentId, reason): Observable<any> {
    return this.http.delete(environment.baseUrl + 'product-service/api/v1/product-sku-attachments?productSKUAttachmentId=' + attachmentId + '&reason=' + reason, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }

  getEntityTags(entityId, entityType): Observable<any> {
    return this.http.get(environment.baseUrl + 'metric-data-service/api/v1/entity/tags?entityId=' + entityId + '&entityType=' + entityType, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }
  getTagCategories(tagClassCode): Observable<any> {
    return this.http.get(environment.baseUrl + 'domain-tag-service/api/v1/ref-data/class/' + tagClassCode + '?direction=ASC&page=1&size=100', { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }
  getDomainTagsForProduct(domainCodes: any[]): Observable<any> {
    return this.http.get(environment.baseUrl + 'domain-tag-service/api/v1/tag/all/page/domain-code?direction=ASC&domainCodes=' + domainCodes + '&page=1&size=100', { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }

  updateChannelProductStatus(requestObj) {
    return this.http.put(environment.baseUrl + `market-service/api/v1/market-channel-products/` + requestObj.productId + `?productStatus=` + requestObj.productStatus + `&remarks=` + requestObj.remark + `&productStatusSeller=` + requestObj.productStatusSeller, requestObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }

  updateProduct(product): Observable<any> {
    return this.http.put(environment.baseUrl + 'product-service/api/products', product, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }

  uploadImages(formData, productSKUId): Observable<any> {
    return this.http.post(environment.baseUrl + `product-service/api/v1/product-sku-attachments/${productSKUId}`, formData, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }
  updateImages(formData, productSKUId, attachmentUUID): Observable<any> {
    return this.http.put(environment.baseUrl + `product-service/api/v1/product-sku-attachments/${productSKUId}/${attachmentUUID}`, formData, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }), observe: 'response', responseType: 'text' })
  }


  sendProductForApproval(reqBody) {
    return this.http.post(environment.baseUrl + `market-service/api/v1/market-channel-products`, reqBody, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }

  approveBySeller(reqObj) {
    return this.http.post(environment.baseUrl + 'product-service/api/v1/shopify/product/approve?productId=' + reqObj, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }

  rejectBySeller(reqObj) {
    return this.http.post(environment.baseUrl + 'product-service/api/v1/shopify/product/reject?productId=' + reqObj, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage['auth_token'] }) })
  }



  //Inventory Management
  getInventoryProductsList(reqObj) {
    return this.http.get(environment.baseUrl + 'product-service/api/v1/shopify/publish-product?pageNo=' + reqObj.pageNumber + '&pageSize=' + reqObj.pageSize, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
  }

  getInventoryProductsDetails(requestObj) {
    //updated  changes for Test Server
    return this.http.get(environment.baseUrl + 'product-service/api/v1/shopify/product/details?product_id=' + requestObj.product_id + '&pageNo=' + requestObj.pageNumber + '&pageSize=' + requestObj.pageSize, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
    // return this.http.get(environment.baseUrl + 'product-service/api/v1/shopify/product/details?product_id=' + requestObj.product_id + '&pageNo=' + requestObj.pageNumber + '&pageSize=' + requestObj.pageSize, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })
   }


  getInventoryProductsDiscountPrice(variant_Id) {
    return this.http.get(environment.baseUrl + 'product-service/api/v1/shopify/product/details/compare-price?variantId=' + variant_Id, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })

    //return this.http.get(environment.baseUrl + 'product-service/api/v1/shopify/product/details/compare-price?variantId=' + variant_Id, { headers: new HttpHeaders({ 'Authorization': sessionStorage['auth_token'] }) })

  }

  updateInventoryDetails(reqObj) {
    if (reqObj.inventoryItemQuantity) {
      return this.http.post(environment.baseUrl+'product-service/api/v1/shopify/fields/updates?product_id=' + reqObj.productId + '&shopifyId=' + reqObj.shopifyId + '&variantId=' + reqObj.variantId + '&available_adjustment=' + reqObj.inventoryItemQuantity + '&mrp_update=' + reqObj.variantPrice + '&compare_price=' + reqObj.discount, {}, { headers: new HttpHeaders({ 'Content-Type': 'text/plain', 'Authorization': sessionStorage['auth_token'] }), observe: 'response', responseType: 'text' })
    } else {
      return this.http.post(environment.baseUrl+'product-service/api/v1/shopify/fields/updates?product_id=' + reqObj.productId + '&shopifyId=' + reqObj.shopifyId + '&variantId=' + reqObj.variantId + '&mrp_update=' + reqObj.variantPrice + '&compare_price=' + reqObj.discount, {}, { headers: new HttpHeaders({ 'Content-Type': 'text/plain', 'Authorization': sessionStorage['auth_token'] }), observe: 'response', responseType: 'text' })
    }
  }

  storeFormData(formData) {
    this.formData$.next(formData);
    // this.formData1 = formData;
    // localStorage.setItem('FormData',JSON.stringify(formData));
  }
  getOtp(payload, authToken) {
    return this.http.post(
      environment.baseUrl + "notification-service/api/v1/notification",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  validateOtp = function (messageId, otp, authToken) {
    return this.http.post(
      environment.baseUrl +
      "notification-service/api/v1/notification/otp/validate?messageId=" +
      messageId +
      "&otp=" +
      otp,
      "",
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  getTenantDetailsByLoginId = function (tenantLogin, authToken) {
    return this.http.get(
      environment.baseUrl + "auth-service/api/v1/tenants/login/" + tenantLogin,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  createTenant(payload, authToken) {
    return this.http.post(
      environment.baseUrl + "auth-service/api/v1/tenants",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  createRolesForTenant = function (payload, authToken) {
    return this.http.post(
      environment.baseUrl + "auth-service/api/v1/roles",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };

  createUser = function (payload, password) {
    return this.http.post(
      environment.baseUrl + "auth-service/api/v1/users",
      payload,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: sessionStorage["auth_token"],
          "X-PASS": password,
        }),
      }
    );
  };
  assignUserRoles(payload, authToken) {
    return this.http.post(
      environment.baseUrl + "auth-service/api/v1/roles/assign/user",
      payload,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: authToken,
        }),
      }
    );
  }

  createTrader = function (payload, authToken) {
    return this.http.post(
      environment.baseUrl + "trader-service/api/v1/traders",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  getMarketByMarketCode = function (marketCode, authToken) {
    return this.http.get(
      environment.baseUrl +
      "trader-query-service/api/v1/markets/market-code?marketCode=" +
      marketCode,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  getProductSearh(reqObj) {
    return this.http.get(environment.baseUrl + 'product-service/api/v1/shopify/inventory/inventorySearch?pageNo=' +
      reqObj.pageNo + '&pageSize=' + reqObj.pageSize + '&value=' + reqObj.value,
      { headers: new HttpHeaders({ 'authorization': sessionStorage['auth_token'] }) })
  }

  resetPassword(payload, authToken) {
    return this.http.post(
      environment.baseUrl + "auth-service/api/v1/users/reset/password",
      payload,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  };
  //Seller Dashboard
  getTotalsales(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/total-sales?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  getSalesTrend(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/sales-trends?Year=` + reqObj.Year + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  getTopSelling(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/top-selling-product?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }

  getOrderVolume(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/total-order/count?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  getstocklevels(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/product/stock-level?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
  getuniquelocation() {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/unique-location`,
      { headers: new HttpHeaders({ Authorization: sessionStorage['auth_token'] }) }
    )
  }
  getRecentOrders(reqObj, authToken) {
    return this.http.get(environment.baseUrl + 'market-service/api/v1/seller/recent-orders?TraderId=' + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }

  getAverageOrderValue(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/order/average-value?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }

  getReturnRates(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/average-return-product?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }

  getTotalProduct(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `product-service/api/v1/shopify/total-product/count?TraderId=` + reqObj.TraderId + `&fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }

  getProductListing(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `product-service/api/v1/shopify/product/status/count?TraderId=` + reqObj.TraderId + `&fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }


  getrevenueloc(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/revenue/region?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId + `&Location=` + reqObj.Location,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    )
  }
  getpayout(reqObj, authToken) {
    return this.http.get(environment.baseUrl + `market-service/api/v1/seller/order/payout-summary?fromDate=` + reqObj.fromDate + `&toDate=` + reqObj.toDate + `&TraderId=` + reqObj.TraderId,
      { headers: new HttpHeaders({ Authorization: authToken }) }
    );
  }
}
