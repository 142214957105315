import { Component, OnInit } from '@angular/core';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';

@Component({
  selector: 'app-basic-comapny-information',
  templateUrl: './basic-comapny-information.component.html',
  styleUrls: ['./basic-comapny-information.component.scss']
})
export class BasicComapnyInformationComponent implements OnInit {
  readOnlyMode: any;
  editableFlag: any = false;
  traderJson: any;
  businessTypes: any;
  msmeTypes: any;
  appLanguage: any;
  appLabel: any;
  refDataCodes: any;
  traderRefDataCodes: any;
  attachedDoc: any;
  imageChangeFlag:boolean;
  imgFlag:any;
  fd: FormData;
  loader:any;
  imageLoader:boolean;
  mandatoryFields: any[];
  



  constructor(private sharedsharedService: SharedService,
    private componentLoaderService:ComponentLoaderService,
    private toastr:ToastrCustomService) { }

  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getAppLabels();
    this.getDataCodes();
    if (sessionStorage.getItem("isLoggedIn") == "true")
      this.readOnlyMode = true;

  }
  getAppLabels() {
    this.sharedsharedService
      .getJson("app-labels/" + this.appLanguage + "/traderProfile")
      .subscribe((res) => {
        this.appLabel = res["basicCompanyInformation"]
        console.log(res)
      });
  }

  getTraderJson() { 

    this.sharedsharedService
    .getTraderDetailsForLoggedInUser(sessionStorage["auth_token"])
    .subscribe((traderResponse) => {
      console.log("Trader Details", traderResponse[0]["traderProfile"]["msmeType"]);
      //Check
      if (!traderResponse[0].traderProfile.typeOfEntity) {
        traderResponse[0].traderProfile.typeOfEntity = {"code": ""}
      }
      if (this.appLanguage == "ENG-IN") {
        if (traderResponse[0]["traderProfile"]["msmeType"] == undefined) {
          console.log("Trader-Details Undefined");
          this.sharedsharedService.getJSONData("app-models/" + this.appLanguage + "/post-jsons/trader").subscribe((resJson) => {
            traderResponse[0]["traderProfile"]["msmeType"] =
              resJson["traderProfile"]["msmeType"];
            this.traderJson = traderResponse[0];
            console.log("Trader-Json-Updated", this.traderJson);
          });
        } else this.traderJson = traderResponse[0];
      }
      else if (this.appLanguage == "ENG-US")
        this.traderJson = traderResponse[0];
    });

  }
  getDataCodes(

  ) {
    this.sharedsharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.sharedsharedService.getJSONData('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getBusinessTypeGREF();
        this.getMSMETypesGREF();
        this.attachedDoc = "";
        this.getTraderJson();
        if (this.appLanguage == 'ENG-IN')
          this.getAttachments();
      });
    }
    );

  }
  onchange(form){
    //console.log(this.basiccompinfo.form.invalid || !attachedDoc|| loader ||( !editableFlag && readOnlyMode))
    this.loader
    console.log(form.form.invalid )
  }
  getBusinessTypeGREF() {
    this.sharedsharedService
    .getTraderGREFDataByClassCode(
      this.traderRefDataCodes.entityType.classCode
    )
    .subscribe((res) => {
      this.businessTypes = res;
    });

  }
  getMSMETypesGREF() {
    this.sharedsharedService
    .getTraderGREFDataByClassCode(
      this.traderRefDataCodes.msme.classCode
    
    )
    .subscribe((res) => (this.msmeTypes = res));

  }
  
  getAttachments() {
    this.imageLoader = true;
    this.sharedsharedService
      .getAttachment(
        this.traderRefDataCodes.attachment.msme,
        sessionStorage["trader_id"]
      )
      .subscribe((resMSME) => {
        if (resMSME) {
          this.imgFlag = true;
          console.log("Attachment Response", resMSME);
          this.attachedDoc = "data:image/png;base64," + resMSME.attachment;
          this.imageLoader = false;
          console.log(this.attachedDoc);
        }
        // this.tanNumberAttachment = reader.readAsDataURL(resTAN.attachment);
        // this.tanNumberAttachment = resTAN.attachment;
      },
        err => {
          this.imageLoader = false;
        });
  }
 
  
  addAttachment(event) {
    console.log(event.target.files[0]);
    const attachedFile = event.target.files[0];

    if (attachedFile.type != "application/pdf") {
      this.imgFlag = true;
      this.imageChangeFlag = true;
      this.fd = new FormData();
      this.fd.append("file", attachedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.attachedDoc = e.target["result"];
        //Checking file size
        if (attachedFile.size > (6 * 1048576)) {
          this.attachedDoc = '';
          this.toastr.showError('Image size should be less than 6Mb');
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.fd = new FormData();
      this.fd.append("file", attachedFile);
      this.attachedDoc = attachedFile.name;
      this.imgFlag = false;
    }
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  navigateNext() {
    if (this.readOnlyMode) {
      
      let lazyComp: any = import(
        "../../vendor-profile-forms/registered-address/registered-address.component"
      ).then(({ RegisteredAddressComponent }) => RegisteredAddressComponent);
      this.componentLoaderService.storeLazyComponent({
        component: lazyComp,
        componentName: "RegisteredAddressComponent",
      });
    } else {
      this.sharedsharedService
        .patchTraderProfile(this.traderJson)
        .subscribe((res) => {
          //Check For attachment
          if (this.imgFlag && this.attachedDoc != '') {
            this.sharedsharedService
              .addAttachment(
                sessionStorage["trader_id"],
                this.fd,
                this.traderRefDataCodes.attachment.msme
              )
              .subscribe((attachmentResponse) => {
                console.log("Attachment Added Successfully",attachmentResponse);
              });
          }
          let lazyComp: any = import(
            "../../vendor-profile-forms/registered-address/registered-address.component"
          ).then(
            ({ RegisteredAddressComponent }) => RegisteredAddressComponent
          );
          this.componentLoaderService.storeLazyComponent({
            component: lazyComp,
            componentName: "RegisteredAddressComponent",
          });
          console.log("Details Updated Successfully");
        });
    }
  }


  navigatePrevious(){
    let lazyComp: any = import(
      "../../vendor-profile-forms/basic-information/basic-information.component"
    ).then(({ BasicInformationComponent }) => BasicInformationComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "BasicInformationComponent",
    });
  }
  updateTraderDetails() {
    this.loader = true;
    if (this.traderJson.traderProfile.msmeType && this.traderJson.traderProfile.msmeType.code=="") {
      delete this.traderJson.traderProfile.msmeType;
    }
    this.sharedsharedService
      .patchTraderProfile(this.traderJson)
      .subscribe((res) => {
        this.editableFlag = false;
        this.toastr.showSuccess('Details Updated Successfully');
        this.loader = false;
        this.sharedsharedService.traderDetails.next(res);
        if (this.imageChangeFlag && this.attachedDoc != '') {
          console.log("hiiiii")
          this.sharedsharedService
            .addAttachment(
              sessionStorage["trader_id"],
              this.fd,
              this.traderRefDataCodes.attachment.msme
            )
            .subscribe((attachmentResponse) => {
              this.toastr.showSuccess('Details Updated Successfully');
              console.log("Attachment Added Successfully", attachmentResponse);
              this.sharedsharedService.traderAttachment.next([
                this.traderRefDataCodes.attachment.msme,
                this.attachedDoc,
              ]);
              this.imageChangeFlag = false;
            });
        }
        console.log("Details Updated Successfully");
        this.toastr.showSuccess("Details Updated Successfully")
      });
      this.getTraderJson();
     // this.ngOnInit();
      //window.location.reload();
  }
 
  isFieldInvalid(fieldName: string): boolean {
    this.mandatoryFields = this.sharedsharedService.dataArray;
  return this.mandatoryFields.includes(fieldName);



    
}
}
