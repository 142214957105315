<div class="communication-address" *ngIf="traderJson">
    <div class="container-fluid">
        <div class="form-group" style="position: absolute;
        top: 18px;
        right: 75px;">
            <input type="checkbox" (change)="updateCommunicationAdressAsSame($event)" [(ngModel)]="copyaddress"
                name="copyaddress" [disabled]="!editableFlag && readOnlyMode? true : null"> &nbsp; <label class="text-label" style="font-size: 12px;font-weight: bold;">Same as
                registered address</label>
        </div>
 
        <button class="btn" style="position: absolute;
        top: 13px;
        right: 15px;" (click)="toggleEdit()">

            <span *ngIf="readOnlyMode && !editableFlag">
                <i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i>
            </span>
            <span *ngIf="readOnlyMode && editableFlag">
                <i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i>
            </span>

        </button>


       

        <form #communicationAddress="ngForm" *ngIf="isDataLoaded && appLabel">

            <div class="row">
                <div class="col-12 col-md-4" *ngIf="appLabel.nameOfAuthorizedPerson.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.nameOfAuthorizedPerson.label}}<span
                                class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" id="Name" aria-describedby="helpId"
                            placeholder="" name="authorizedPerson" [disabled]="!editableFlag && readOnlyMode"
                            [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].firstName" required
                            [ngClass]="{'has-error': isFieldInvalid()}">
                    </div>
                </div>
                <div class="col-12 col-md-4"  *ngIf="appLabel.designation.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.designation.label}}<span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" id="Designation"
                            aria-describedby="helpId" placeholder="" name="designation"
                            [disabled]="!editableFlag && readOnlyMode"
                            [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].designation"
                            required [ngClass]="{'has-error': isFieldInvalid()}">
                    </div>
                </div>
                <div class="col-12 col-md-4"  *ngIf="appLabel.landline.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.landline.label}}</label>
                        <input type="text" class="form-control form-control-sm" id="Landline" aria-describedby="helpId"
                            placeholder="" name="landline" pattern="^[\+\d]?(?:[\d-.\s()]*)$"
                            [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[_contactPersonLandlineIndex].contactValue"
                            [disabled]="!editableFlag && readOnlyMode">
                    </div>
                </div>

            </div>
            <div class="row mt-2">
                <div class="col-12 col-md-4" *ngIf="appLabel.mobile.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.mobile.label}}<span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" id="Mobile" aria-describedby="helpId"
                            placeholder="" name="mobile" [disabled]="!editableFlag && readOnlyMode"
                            pattern="^[\+\d]?(?:[\d-.\s()]*)$"
                            [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[_contactPersonMobileIndex].contactValue"
                            required [ngClass]="{'has-error': isFieldInvalid()}">
                    </div>
                </div>
                <div class="col-12 col-md-4"  *ngIf="appLabel.emailId1.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.emailId1.label}}<span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" id="Email1" aria-describedby="helpId"
                            placeholder="" #email1="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            name="email1"
                            [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[_contactPersonMandatoryEmailIndex].contactValue"
                            [disabled]="!editableFlag && readOnlyMode" required
                            [ngClass]="{'has-error': isFieldInvalid()}">
                        <span *ngIf="email1.touched">
                            <small *ngIf="email1.errors?.pattern" class="text-danger">Please enter a valid email</small>
                            <small *ngIf="email1.errors?.required" class="text-danger">Email is required</small>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4" *ngIf="appLabel.emailId2.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.emailId2.label}}</label>
                        <input type="text" class="form-control form-control-sm" id="Email2" aria-describedby="helpId"
                            placeholder="" #email2="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            name="email2"
                            [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[_contactPersonOptionalEmailIndex].contactValue"
                            [disabled]="!editableFlag && readOnlyMode">
                        <span *ngIf="email2.touched">
                            <small *ngIf="email2.errors?.pattern" class="text-danger">Please enter a valid email</small>
                        </span>

                    </div>
                </div>

            </div>
            <div class="row mt-2 justify-content-between">
                <div class="col-12 col-md-4" *ngIf="appLabel.nameOfAuthorizedSignatory.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.nameOfAuthorizedSignatory.label}}<span
                                class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" id="AuthorizedSignatory"
                            name="authorizedSignatory" [disabled]="!editableFlag && readOnlyMode"
                            [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].signatoryName"
                            required [ngClass]="{'has-error': isFieldInvalid()}">
                    </div>
                </div>
                <div class="col-12 col-md-4"  *ngIf="appLabel.addressLine1.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.addressLine1.label}}<span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" id="addressLine1"
                            aria-describedby="helpId" placeholder=""
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.addressLine1 : traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.addressLine1"
                            name="addressLine1" [disabled]="(!editableFlag && readOnlyMode) || copyaddress" required
                            [ngClass]="{'has-error': isFieldInvalid()}">
                    </div>
                </div>
                <div class="col-12 col-md-4" *ngIf="appLabel.addressLine2.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.addressLine2.label}}</label>
                        <input type="text" class="form-control form-control-sm" id="addressLine2"
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.addressLine2 :traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.addressLine2"
                            name="addressLine2" [disabled]="(!editableFlag && readOnlyMode) || copyaddress">
                    </div>
                </div>

            </div>
            <div class="row mt-2 ">
                <div class="col-12 col-md-4"  *ngIf="appLabel.country.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.country.label}}<span class="text-danger">*</span></label>
                        <select class="form-select  form-select-sm" aria-label="Default select example" name="country"
                            id=""
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.country.code :traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.country.code"
                            disabled required style="width: 100%;" [ngClass]="{'has-error': isFieldInvalid()}">
                            <option value="" selected disabled>Select {{appLabel.country.label}}</option>
                            <option *ngFor="let dropdownElement of country" [value]="dropdownElement.code">
                                {{dropdownElement.labels[0].text}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-4" *ngIf="appLabel.state.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.state.label}}<span class="text-danger">*</span></label>
                        <select class="form-select  form-select-sm" aria-label="Default select example" name="state"
                            id=""
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.state.code :traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.state.code"
                            (change)="getDistrict(traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.state.code,1);"
                            [disabled]="(!editableFlag && readOnlyMode) || copyaddress" required style="width: 100%;"
                            [ngClass]="{'has-error': isFieldInvalid()}">
                            <option value="" selected disabled>Select {{appLabel.state.label}}</option>
                            <option *ngFor="let dropdownElement of states" [value]="dropdownElement.code">
                                {{dropdownElement.labels[0].text}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-4" *ngIf="appLabel.district.required">
                    <div class="form-group">
                        <label for="" class="text-label">{{appLabel.district.label}}<span class="text-danger">*</span></label>
                        <select class="form-select  form-select-sm" aria-label="Default select example" name="district"
                            id=""
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.district.code :traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.district.code"
                            (change)="getBlock(traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.district.code,1)"
                            [disabled]="(!editableFlag && readOnlyMode) || copyaddress" required style="width: 100%;"
                            [ngClass]="{'has-error': isFieldInvalid()}">
                            <option value="" selected disabled>Select District</option>
                            <option *ngFor="let dropdownElement of district" [value]="dropdownElement.code">
                                {{dropdownElement.labels[0].text}}</option>
                        </select>
                    </div>
                </div>

            </div>
            <div class="row mt-2">
                <div class="col-12 col-md-4"*ngIf="appLabel.block.required">
                    <div class="form-group">
                        <label class="text-label">{{appLabel.block.label}}<span class="text-danger">*</span></label>
                        <select class="form-select  form-select-sm" aria-label="Default select example" name="block"
                            id=""
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.block.code :traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.block.code"
                            [disabled]="(!editableFlag && readOnlyMode) || copyaddress" required style="width: 100%;"
                            [ngClass]="{'has-error': isFieldInvalid()}">
                            <option value="" selected disabled>Select Block</option>
                            <option *ngFor="let dropdownElement of block" [value]="dropdownElement.code">
                                {{dropdownElement.labels[0].text}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-4" *ngIf="appLabel.pinCode.required">
                    <div class="form-group">
                        <label class="text-label">{{appLabel.pinCode.label}} <span class="text-danger">*</span></label>
                        <input type="number" class="form-control form-control-sm" id="pinCode" aria-describedby="helpId"
                            placeholder=""
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.pinCode :traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.pinCode"
                            name="pinCode" [disabled]="(!editableFlag && readOnlyMode) || copyaddress" required
                            style="width: 100%;" [ngClass]="{'has-error': isFieldInvalid()}">
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group">
                        <label class="text-label">Landmark</label>
                        <input type="text" class="form-control form-control-sm" id="Landmark" aria-describedby="helpId"
                            placeholder=""
                            [(ngModel)]="copyaddress ? traderJson.traderProfile.address.landmark :traderJson.traderProfile.traderContactPersons[currentIndex].personAddress.landmark"
                            name="landmark" [disabled]="(!editableFlag && readOnlyMode) || copyaddress">

                    </div>


                </div>

            </div>
            <!-- <div class="row mt-2">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1" class="text-label">First Name<span
                            class="text-danger">*</span></label>
                    <input type="email" class="form-control form-control-sm" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter email">
                </div>
            </div>
            
        </div>  -->

            <div class="row" style="margin-top: 40px;">
                <div class="full-width-div">
                    <div class="row">
                        <div class="col-12"
                            style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">
                            <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                                style="height:28px;width:100px;left:15px" [disabled]="editableFlag "
                                (click)="navigatePrevious()">
                                <i class="fa fa-angle-double-left"
                                    style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                                Previous
                            </button>
                            <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                            <button [disabled]="editableFlag " type="button"
                                class="btn  btn-sm profile-side-nav-next-prev-button"
                                style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                                Next&nbsp; &nbsp;
                                <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                            </button>

                  
                            

                    <button type="button" [disabled]="communicationAddress.form.invalid || loader ||( !editableFlag && readOnlyMode)" 
                    class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                    style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
                </div>
            </div>
        </form>
    </div>
</div>