<div class="brand-details" *ngIf="traderJson">
    <button class="btn" style="position: absolute;
    top: 13px;
    right: 15px;" (click)="toggleEdit()"><span 
          *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
          *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span>    </button>
    <div class="container-fluid">
        <form #branddetails="ngForm">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label  class="text-label">Brand Name
                        <span
                        class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control form-control-sm" aria-describedby="helpId" name="brandName"
                    [(ngModel)]="traderJson.traderProfile.traderBrands[0].brandName"  required [disabled]="!editableFlag && readOnlyMode" placeholder="Please Enter Brand Name"  [ngClass]="{'has-error': isFieldInvalid()&& !input1.model}" #input1="ngModel">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label  class="text-label">Brand Ownership
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                            <select class="form-select  form-select-sm" name="brandOwnership" id=""
                            [(ngModel)]="traderJson.traderProfile.traderBrands[0].brandOwnerShip.code" *ngIf="traderJson?.traderProfile?.traderBrands[0]?.brandOwnerShip"
                            [disabled]="!editableFlag && readOnlyMode">

                            
                            <option value="" selected disabled>Select Brand Ownership</option>
                            <option *ngFor="let dropdownElement of brandOwnerships" [value]="dropdownElement.code">
                              {{dropdownElement.labels[0].text}}</option>
                          </select>
                </div>
            </div>
          

        </div>

    
        <div class="full-width-div">
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">

                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                        style="height:28px;width:100px;left:15px" [disabled]="editableFlag " (click)="navigatePrevious()"
                       >
                        <i class="fa fa-angle-double-left" style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                        Previous
                    </button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                    <button [disabled]="editableFlag " type="button"
                        class="btn  btn-sm profile-side-nav-next-prev-button"
                        style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                        Next&nbsp; &nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>


                    <button type="button" [disabled]="branddetails.form.invalid || loader ||( !editableFlag && readOnlyMode)"class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                        style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>