import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomAuthService {

  constructor() { }
  
  isLoggedIn():any {
    console.log('Checking', sessionStorage['tenant_id'])
    if (sessionStorage['tenant_id'] !== undefined) {
      console.log('Verified User');
      return true;
    }
  }

}
