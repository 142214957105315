import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal.component';

@Component({
  selector: 'app-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.scss']
})
export class MenuNavigationComponent implements OnInit {
  @Input() currentActiveMenuVal: any = ''
  isActiveMenu = ""
  currentRoute: any

  loggedinUserName: any
  userDropDown: any = [
    { dropdownValue: 'PROFILE', dropdownName: 'User Profile' },
    { dropdownValue: 'CHANGEPASSWORD', dropdownName: 'Change Password', },
    { dropdownValue: 'LOGOUT', dropdownName: 'Logout' }
  ]
  menuOperation: any = ''
  constructor(private router: Router, private sharedService: SharedService, private modalService: NgbModal) { }

  ngOnInit(): void {

    this.sharedService
      .getTraderDetailsForLoggedInUser(sessionStorage["auth_token"])
      .subscribe((traderResponse) => {
        this.loggedinUserName = traderResponse[0].traderProfile.firstName + ' ' + traderResponse[0].traderProfile.lastName
        console.log( traderResponse[0].traderProfile.firstName)
      }, err => {

      });

  }
  navigateMenues(menuName: any) {
    switch (menuName) {
      case 'PROFILE':
        this.router.navigate(['vendorDashboard/vendor-profile', sessionStorage['user_id']])
        break;
      case 'PRODUCTS':
        this.router.navigate(['products/manage-product'])
        break;
      case 'CHANNELS':
        this.router.navigate(['vendorDashboard/market-channel'])
        break;
      case 'INVENTORY':
        this.router.navigate(['products/inventory-management'])
        break;
        case 'CHANGEPASSWORD':
          
          this.openChangePasswordModal();
          break;
      case 'SELLER-DASHBOARD':
        this.router.navigate(['vendorDashboard/seller-dashbaord'])
        break;
      case 'LOGOUT':
        this.router.navigate(['user-login']);
        sessionStorage.clear();
        localStorage.clear();
        this.sharedService.resetDataArray();
        break;

      default:
        break;
    }
  }

 

  logout() {

  }

  openChangePasswordModal() {
    const modalRef = this.modalService.open(ResetPasswordModalComponent, { centered: true, backdrop: 'static', keyboard: false })
  }
  
  
}
