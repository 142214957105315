<div class="display-information" *ngIf="traderJson">
    <button class="btn" style="position: absolute;
    top: 13px;
    right: 15px;" (click)="toggleEdit()"> <span 
          *ngIf="readOnlyMode && !editableFlag"><i class="fa fa-edit" style="font-size:20px;color: #1A237F;"></i></span><span 
          *ngIf="readOnlyMode && editableFlag"><i class="fa fa-times" style="font-size:20px;color: #1A237F;"></i></span></button>
  
    <div class="container-fluid">
                 
       <form #displayinfo="ngForm" >
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label  class="text-label">Business Display Name<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" 
                    aria-describedby="helpId" placeholder="Please Enter Display Name" name="displayName"
                    [(ngModel)]="traderJson.traderProfile.organisation" [disabled]="!editableFlag && readOnlyMode"  [ngClass]="{'has-error': isFieldInvalid()}" required>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label  class="text-label">Main Category<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" 
                    aria-describedby="helpId" placeholder="Please Enter Main Category" name="description"
                    [(ngModel)]="traderJson.traderProfile.description" [disabled]="!editableFlag && readOnlyMode"   [ngClass]="{'has-error': isFieldInvalid()}"required>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label  class="text-label">Customer Contact Number <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm"  [ngClass]="{'has-error': isFieldInvalid()}"
                    aria-describedby="helpId" placeholder="Please Enter Customer Contact Number"  pattern="^[\+\d]?(?:[\d-.\s()]*)$" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[contactsMobileIndex].contactValue" name="custContNumber" [disabled]="!editableFlag && readOnlyMode" required>

                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label  class="text-label">Customer Email Id <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" [ngClass]="{'has-error': isFieldInvalid()}"
                    aria-describedby="helpId" [(ngModel)]="traderJson.traderProfile.traderContactPersons[currentIndex].personContacts[contactsMailIndex].contactValue" #email="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" placeholder="" name="custEmailId" [disabled]="!editableFlag && readOnlyMode" required>

                </div>
                <span *ngIf="email.touched">
                    <small *ngIf="email.errors?.pattern" class="text-danger">Please enter a valid email</small>
                    <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
                  </span>
            </div>


        </div>
        <div class="full-width-div">
            <div class="row">
                <div class="col-12" style="height:35px;display: inline-block;align-items: center ; padding-top: 4px;">

                    <button type="button" class="btn  btn-sm profile-side-nav-next-prev-button"
                        style="height:28px;width:100px;left:15px" [disabled]="editableFlag " (click)="navigatePrevious()"
                       >
                        <i class="fa fa-angle-double-left" style="font-size:16px;font-weight: bold;"></i>&nbsp;&nbsp;
                        Previous
                    </button>
                    <span class="vertical-separator" style=" margin: 0 105px;font-size: 22px;"></span>

                    <button [disabled]="editableFlag " type="button"
                        class="btn  btn-sm profile-side-nav-next-prev-button"
                        style=" height:28px;width:100px;left: 135px;" (click)="navigateNext()">
                        Next&nbsp; &nbsp;
                        <i class="fa fa-angle-double-right" style="font-size:16px;font-weight: bold;"></i>
                    </button>


                    <button type="button" [disabled]="displayinfo.form.invalid || loader ||( !editableFlag && readOnlyMode)"class="btn btn-warning btn-sm page-header-button" (click)="updateTraderDetails()"
                        style="color:#fff;height:28px;width:100px;background-color: orange;">Update</button>
                </div>
            </div>
        </div>
       </form>
    </div>
</div>