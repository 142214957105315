import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { ToastrCustomService } from '../toastr-custom.service';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {
  modalData: any = {}; // optional, if you need to pass data to modal

  model: any = { oldPassword: '', newPassword: '' };
  confirmPassword: any;
  loader: boolean;
  invalidPassword: any;
  errorFlag: boolean;
  showHidePassword = false;
  showHideConfirmPassword=false;
  showHideNewPassword=false;

  constructor(
    private service: SharedService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrCustomService
  ) { }

  ngOnInit(): void {}

  updatePassword() {
    this.invalidPassword = false;
    this.errorFlag = false;
    this.loader = true;
    
    this.service.resetPassword(this.model, sessionStorage.getItem('auth_token')).subscribe(
      (res) => {
        this.toastr.showSuccess('Password Changed Successfully');
  this.loader = false;
  setTimeout(() => {
    this.closeModal('Success');
  }, 2000); //
      },
      (err) => {
        if (err.status == 400 && err.error.errorMessage == 'Invalid Password') {
          this.invalidPassword = true;
        } else {
          this.errorFlag = true;
        }
        this.loader = false;
      }
    );
  }

  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
    window.location.reload();
  }
}
