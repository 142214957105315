import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/application-services/core-services/shared.service';

@Component({
  selector: 'app-un-authorized-login',
  templateUrl: './un-authorized-login.component.html',
  styleUrls: ['./un-authorized-login.component.scss']
})
export class UnAuthorizedLoginComponent implements OnInit {
  languageFlag: any;
  appLanguage: any;
  @Input() request: any;
  model: any = { "mobile": "", "loginId": "", "password": "" };
  postJson: any = {
    "password": "",
    "userLogin": ""
  };
  userNameValidation=false;
  userPasswordValidation=false;
  invalidFlag: boolean;
  loader: boolean;
  errorFlag: boolean;
  showHidePassword: any = false;
  convertedData: any = [];
  organizationValidation: any;
  checkInitialClick: any = false;
  isMobileNumber: any = false;
  constructor(private sharedService: SharedService, public activeModal: NgbModal, private router: Router) { }

  ngOnInit(): void {
    console.log("request:LLLLLLLLLLLLLLL", this.request);
    if (sessionStorage.getItem('appLanguage')) {
      this.appLanguage = sessionStorage.getItem('appLanguage');
    } else {
      this.appLanguage = 'ENG-IN'
    }
    console.log("this.request::::::::::::", this.request);
  }

  userLogin() {
    this.loader = true;
    if (!this.model.mobile || this.model.mobile == '') {
      this.userNameValidation = true
    }
    if (!this.model.password || this.model.password == '') {
      this.userPasswordValidation = true
    }
    if (this.model.loginId !== ''&&this.model.mobile !== ''&&this.model.password !== ''){
      if (this.model.mobile.match('[a-zA-z]+')) {
        this.postJson.userLogin = this.model.mobile + "@@" + this.model.loginId;
      }
      else {
        let appLanguage = sessionStorage.getItem('appLanguage');
        let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '+91' : '+1';
        this.postJson.userLogin = prefix + this.model.mobile + "@@" + this.model.loginId;
      }
      this.postJson.password = this.model.password;
      this.sharedService.login(this.postJson).subscribe(res => {
        sessionStorage.setItem('auth_token', res['tokenType'] + ' ' + res['accessToken']);
        //Call the network that was interepted due to expired token
        if (this.request) {
          this.sharedService.executeRequestOnTokenExpiry(this.request.method, this.request.urlWithParams, sessionStorage['auth_token']).subscribe(res => {
            console.log('Success', res);
            this.activeModal.dismissAll('dismissed');
            this.loader = false;
            this.close();
            window.location.reload();
          }
          );
        } else { this.router.navigate(['user-login']); this.close }
      }, err => {
        this.loader = false;
        this.invalidFlag = true;
      })

    }else if(this.model.loginId == ''||!this.model.loginId ){
      this.organizationValidation=true;
    }

   
  }

  updateAppLanguage(event: any) {
    
    console.log(event);
    
    this.languageFlag = true;
    sessionStorage.setItem('appLanguage', event);
    this.appLanguage = event;


  }
  close() {
    console.log('Closed');
    this.activeModal.dismissAll('popup closed')
    //   this.activeModal.close('dismissed');
    // this.activeModal.dismiss('dismiss on button click');
  }

  getUserTenancyByUserLogin() {

    this.checkInitialClick = false
    let userLogin = '';

    if (this.model.mobile.match('[a-zA-z]+')) {
      this.isMobileNumber = false;
      userLogin = this.model.mobile;
    } else {
      // if (this.model[0] !== '+')

      this.isMobileNumber = true;
      let appLanguage = sessionStorage.getItem('appLanguage');
      let prefix = (appLanguage != null && appLanguage.includes('IN')) ? '%2B91' : '%2B1';
      userLogin = prefix + this.model.mobile;

    }
    this.sharedService.getTenancyByUserLogin(userLogin).subscribe(tenancyList => {
      this.convertedData = Object.entries(tenancyList).map(([key, value]) => ({ key, value }));
      console.log("convertedData::::::::::", this.convertedData);
      if (this.convertedData.length === 0) {
        this.checkInitialClick = true
      }
      if (this.convertedData.length == 1) {
        this.model.loginId = this.convertedData[0].value
      }



    }, err => {

    })
  }

  changeSelectedOption(event) {
    this.organizationValidation = false

  }
}

