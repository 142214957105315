import { Component, OnInit } from '@angular/core';

import { SharedService } from 'src/app/application-services/core-services/shared.service';
import { AdditionalKycComponent } from '../additional-kyc/additional-kyc.component';
import { ComponentLoaderService } from 'src/app/application-services/component-service/component-loader.service';
import { ToastrCustomService } from 'src/app/shared-module/toastr-custom.service';


@Component({
  selector: 'app-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss']
})
export class EmergencyContactComponent implements OnInit {

  traderJson: any;
  readOnlyMode: boolean;
  editableFlag: boolean;
  currentIndexCEO: number;
  currentIndexFinance: number;
  currentIndexDelivery: number;
  currentIndexCustomer: number;
  appLanguage: string;
  refDataCodes: any;
  traderRefDataCodes: any;
  loader: boolean;

  constructor(private service: SharedService,private componentLoaderService:ComponentLoaderService,
    private toastr: ToastrCustomService) { }
  ngOnInit(): void {
    this.appLanguage = sessionStorage.getItem("appLanguage");
    this.getDataCodes();

    // this.getTraderJson();
    if (sessionStorage.getItem('isLoggedIn') == 'true')
      this.readOnlyMode = true;
  }
  getDataCodes() {
    this.service.getJson('app-models/' + this.appLanguage + '/app-data/ref-data').subscribe(res => {
      this.refDataCodes = res;
      this.service.getJson('app-models/' + this.appLanguage + '/app-data/trader-ref').subscribe(traderRef => {
        this.traderRefDataCodes = traderRef;
        this.getTraderJson();
      });
    }
    );
  }
  getTraderJson() {
    this.service.getTraderDetailsForLoggedInUser(sessionStorage['auth_token']).subscribe(traderResponse => {
      console.log('Trader Details', traderResponse[0]);
      console.log('Trader Details', traderResponse[0]['traderProfile']['traderContactPersons']);
      let arr = [];
      arr = traderResponse[0]['traderProfile']['traderContactPersons'].filter(x => x.division.code == this.traderRefDataCodes.division.ceo);

      console.log(arr);
      if (traderResponse[0]['traderProfile']['traderContactPersons'].length == 0 || arr.length == 0) {
        console.log('Trader-Details Undefined');
        this.service.getJson('app-models/'+this.appLanguage+'/post-jsons/trader').subscribe(resJson => {
          traderResponse[0]['traderProfile']['traderContactPersons'].push(resJson['traderProfile']['traderContactPersons'][2]);
          traderResponse[0]['traderProfile']['traderContactPersons'].push(resJson['traderProfile']['traderContactPersons'][3]);
          traderResponse[0]['traderProfile']['traderContactPersons'].push(resJson['traderProfile']['traderContactPersons'][4]);
          traderResponse[0]['traderProfile']['traderContactPersons'].push(resJson['traderProfile']['traderContactPersons'][5]);
          this.traderJson = traderResponse[0];
          this.currentIndexCEO = this.traderJson.traderProfile.traderContactPersons.length - 4;
          this.currentIndexFinance = this.traderJson.traderProfile.traderContactPersons.length - 3;
          this.currentIndexDelivery = this.traderJson.traderProfile.traderContactPersons.length - 2;
          this.currentIndexCustomer = this.traderJson.traderProfile.traderContactPersons.length - 1;
          console.log('Trader-Json-Updated', this.traderJson);
        })
      }
      else {
        //Assigning indexes
        traderResponse[0]['traderProfile']['traderContactPersons'].filter((x, index) => {
          //Sorting
          x['personContacts'] = x['personContacts'].sort(function (a, b) {
            var x = a.contactType.code.toLowerCase();
            var y = b.contactType.code.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
          });

          if (x.division.code == this.traderRefDataCodes.division.financeManager) {
            this.currentIndexFinance = index;
            console.log('Loop')
          }
          if (x.division.code == this.traderRefDataCodes.division.deliveryManager)
            this.currentIndexDelivery = index;
          if (x.division.code == this.traderRefDataCodes.division.customerServiceManager)
            this.currentIndexCustomer = index;
          if (x.division.code == this.traderRefDataCodes.division.ceo)
            this.currentIndexCEO = index;
        });

        this.traderJson = traderResponse[0];
        // if (this.traderJson.traderContactPersons.length < 6) {
        //   for (let i = 0; i < this.traderJson.traderContactPersons.length; i++) {

        //   }
        // }
      }
    })
  }
  navigateNext() {
    if (this.readOnlyMode || this.traderJson.traderProfile.traderContactPersons[this.currentIndexCEO].firstName == '') {
      let lazyComp: any = import('../../vendor-profile-forms/additional-kyc/additional-kyc.component').then(({ AdditionalKycComponent }) => AdditionalKycComponent);
      this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'AdditionalKycComponent' });
    }
    else {
      this.service.patchTraderProfile(this.traderJson).subscribe(res => {
        let lazyComp: any = import('../../vendor-profile-forms/additional-kyc/additional-kyc.component').then(({ AdditionalKycComponent }) => AdditionalKycComponent);
        this.componentLoaderService.storeLazyComponent({ 'component': lazyComp, 'componentName': 'AdditionalKycComponent' });
        console.log('Details Updated Successfully')
      })
    }
  }
  toggleEdit() {
    this.editableFlag = !this.editableFlag;
  }
  updateTraderDetails() {
    this.loader = true;
    this.service.patchTraderProfile(this.traderJson,).subscribe(res => {
      this.editableFlag = false;
      this.toastr.showSuccess('Details Updated Successfully');
      console.log('Details Updated Successfully');
      this.service.traderDetails.next(res);
      this.loader = false;
    })
  }
  navigatePrevious(){
    let lazyComp: any = import(
      "../../vendor-profile-forms/product-information/product-information.component"
    ).then(({ ProductInformationComponent }) => ProductInformationComponent);
    this.componentLoaderService.storeLazyComponent({
      component: lazyComp,
      componentName: "ProductInformationComponent",
    });
  }
}
